<div>

  <div class="example-header" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign=" center" fxLayoutGap.gt-sm="20px">
    <mat-form-field fxFlex>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'todoDemo.management.Search' | translate}}">
    </mat-form-field>
    <mat-slide-toggle (change)="$event? toggleCompletedTasks() : null" [checked]="hideCompletedTasks" fxFlex="initial">{{'todoDemo.management.HideCompleted' | translate}}</mat-slide-toggle>
  </div>

  <div class="example-container mat-elevation-z8">
    <mat-progress-bar *ngIf="loadingIndicator" mode="query"></mat-progress-bar>
    <mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="select">
        <mat-header-cell fxFlex="60px" *matHeaderCellDef>
          <mat-checkbox (change)="$event ? toggleAllTasksComplete() : null"
                        [checked]="completedTasks.hasValue() && areAllTasksComplete"
                        [indeterminate]="completedTasks.hasValue() && !areAllTasksComplete">
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell fxFlex="60px" *matCellDef="let task">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? toggleTaskComplete(task) : null"
                        [checked]="completedTasks.isSelected(task)">
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell fxFlex="35%" *matHeaderCellDef mat-sort-header> {{'todoDemo.management.Task' | translate}} </mat-header-cell>
        <mat-cell fxFlex="35%" *matCellDef="let task" [class.completed]="task.isComplete">{{task.name}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'todoDemo.management.Description' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let task" [class.completed]="task.isComplete"> {{task.description}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell fxFlex="170px" *matHeaderCellDef>
          <button mat-button (click)="addTask()" color="primary">
            <mat-icon class="mat-button-icon">add</mat-icon>{{'todoDemo.editor.AddTask' | translate}}
          </button>
        </mat-header-cell>
        <mat-cell fxFlex="140px" fxFlexOffset="30px" *matCellDef="let task">
          <button mat-icon-button matTooltip="{{'todoDemo.management.Important' | translate}}" (click)="task.isImportant = !task.isImportant">
            <mat-icon color="accent">{{task.isImportant ? 'bookmark' : 'bookmark_outline'}}</mat-icon>
          </button>
          <button mat-icon-button matTooltip="{{'todoDemo.management.Delete' | translate}}" (click)="confirmDelete(task)">
            <mat-icon color="warn">delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>

</div>
