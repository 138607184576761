
    <!-- ===============================================-->
    <!--    Menu -->
    <!-- ===============================================-->

    <div class="sticky-top navbar-elixir">
      <div class="container">
        <nav class="navbar navbar-expand-lg"> <a class="navbar-brand" href="index.html"><img width="260" height="32" src="assets/img/logo-4.png" alt="SYDI.Finance" /></a>
          <button class="navbar-toggler p-0" type="button" data-bs-toggle="collapse" data-bs-target="#primaryNavbarCollapse" aria-controls="primaryNavbarCollapse" aria-expanded="false" aria-label="Toggle navigation"><span class="hamburger hamburger--emphatic"><span class="hamburger-box"><span class="hamburger-inner"></span></span></span></button>
          <div class="collapse navbar-collapse" id="primaryNavbarCollapse">
            <ul class="navbar-nav py-3 py-lg-0 mt-1 mb-2 my-lg-0 ms-lg-n1">
              <li class="nav-item dropdown"><a class="nav-link dropdown-toggle dropdown-indicator" href="JavaScript:void(0)" role="button" data-bs-toggle="dropdown" aria-expanded="false">Автострахування</a>
                <ul class="dropdown-menu">
                   <li><a class="dropdown-item" href="car-insurance">Автоцивілка</a></li>
                </ul>
              </li>
              <li class="nav-item dropdown"><a class="nav-link dropdown-toggle dropdown-indicator" href="JavaScript:void(0)" role="button" data-bs-toggle="dropdown" aria-expanded="false">Туристичне страхування</a>
                <ul class="dropdown-menu">
                   <li><a class="dropdown-item" href="travel-insurance">Електронний поліс</a></li>
                </ul>
              </li>
              <li class="nav-item dropdown"><a class="nav-link dropdown-toggle dropdown-indicator" href="JavaScript:void(0)" role="button" data-bs-toggle="dropdown" aria-expanded="false">Страхові компанії</a>
                <ul class="dropdown-menu">
                   <li><a class="dropdown-item" href="salamandra">Salamandra</a></li>
                </ul>
              </li>
            </ul>

            <button *ngIf="!isUserLoggedIn" mat-button (click)="router.navigateByUrl('/login')">
              <mat-icon>lock_open</mat-icon>
              <span>Вхід</span>
            </button>

            <button *ngIf="isUserLoggedIn" mat-button (click)="router.navigateByUrl('/settings')">
              <mat-icon>person</mat-icon>
              <span>Профіль</span>
            </button>

            <button *ngIf="isUserLoggedIn" mat-button (click)="logout()">
              <mat-icon>lock</mat-icon>
              <span>Вихід</span>
            </button>

          </div>
        </nav>
      </div>
    </div>


    <!-- ===============================================-->
    <!--    Main Content -->
    <!-- ===============================================-->
    <router-outlet></router-outlet>


    <app-footer></app-footer>

    <ngx-toasta></ngx-toasta>

