<main class="main" id="top">

  <!-- ============================================-->
  <!-- <section> begin ============================-->
    <section class="bg-primary" data-zanim-timeline="{}" data-zanim-trigger="scroll">
      <div class="container">
        <div class="row align-items-center text-white">
          <div class="col-lg-12">
            <div class="border border-2 border-warning p-4 py-lg-5 text-left rounded-3" data-zanim-timeline="{}" data-zanim-trigger="scroll">
              <div class="overflow-hidden">
                <h4 class="text-white" data-zanim-xs='{"delay":0}'>Туристичне Страхування</h4>
                <hr class="short" data-zanim-xs='{"from":{"opacity":0,"width":0},"to":{"opacity":1,"width":"4.20873rem"},"duration":2.8}'
                  data-zanim-trigger="scroll" style="background-color:white"/>
              </div>
              <div class="overflow-hidden">
                <div class="row align-items-center">
                  <div class="col-lg-6 mt-4 mt-lg-0">
                    <h5 class="px-lg-1 text-100 mb-0" data-zanim-xs='{"delay":0.2}'>{{searchResult}}</h5>
                  </div>
                  <div class="col-lg-6 mt-4 mt-lg-0">
                    <h5 class="px-lg-1 text-100 mb-0" data-zanim-xs='{"delay":0.2}'>{{searchResult1}}</h5>
                  </div>
                </div>

                <div class="row align-items-center">
                  <div class="col-lg-6 mt-4 mt-lg-0">
                    <h5 class="px-lg-1 text-100 mb-0" data-zanim-xs='{"delay":0.2}'>{{searchResult2}}</h5>
                  </div>
                  <div class="col-lg-6 mt-4 mt-lg-0">
                    <h5 class="px-lg-1 text-100 mb-0" data-zanim-xs='{"delay":0.2}'>{{searchResult3}}</h5>
                  </div>
                </div>

              </div>
              <div class="overflow-hidden pt-3">
                <h6 class="px-lg-1 text-100 mb-0" data-zanim-xs='{"delay":0.3}'>Туристична страховка онлайн. Онлайн оформлення страховки. Туристичні страховки по всьому світу. Різні цілі мандрування та терміни дії. Підтримка 24/7.</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end of .container-->
    </section>

    <mat-progress-bar *ngIf="loadingDataSource" mode="query"></mat-progress-bar>
  <!-- <section> close ============================-->
  <!-- ============================================-->

  <section class="bg-100" data-zanim-timeline="{}" data-zanim-trigger="scroll">


    <div class="bg-holder" style="background-image:url(assets/img/video-1.jpg);">
      <video class="bg-video" autoplay="autoplay" loop="loop" muted="muted" playsinline="playsinline">
        <source src="assets/videos/morning-routine/video.mp4" type="video/mp4" />
        <source src="assets/videos/morning-routine/video.webm" type="video/webm" />
      </video>
    </div>

    <div class="container">

      <div *ngIf="loadingDataSource" class="spinner_overlay"><mat-spinner *ngIf="loadingDataSource"></mat-spinner></div>

      <div class="row g-4" data-inertia='{"weight":1.5}' >

        <div class="col-md-4 col-lg-4">
          <div class="card" *ngIf="!loadingDataSource">
            <img class="card-img-top" src="assets/img/salamandra-travel.gif" alt="Salamandra" />

            <div class="card-body" data-zanim-timeline="{}" data-zanim-trigger="scroll">
              <div class="overflow-hidden">
                  <h5 data-zanim-xs='{"delay":0}'>{{travelProduct?.insuranceCompanyName}}</h5>
              </div>
              <div class="overflow-hidden">
                <h5 class="mb-1 text-success" data-zanim-xs='{"delay":0.2}'>{{travelProduct?.limit | number:'1.0-0'}} $</h5>
                <p class="fs--1 text-500" data-zanim-xs='{"delay":0.1}'>Ліміт</p>
              </div>

              <div class="overflow-hidden" *ngIf="travelProduct.discountPercent != null">
                <h4 class="mt-3 fs-0 text-success headline-red" data-zanim-xs='{"delay":0.2}'><mark class="main-container-red">{{travelProduct.price | number:'1.2-2'}} грн.</mark></h4>
                <h4 class="mt-3 fs-2 text-success headline-green" data-zanim-xs='{"delay":0.2}'><mark class="main-container-green">{{travelProduct.sum | number:'1.2-2'}} грн.</mark></h4>
              </div>

              <div class="overflow-hidden" *ngIf="travelProduct.discountPercent == null">
                <h4 class="mt-3 fs-2 text-success headline-green" data-zanim-xs='{"delay":0.2}'><mark class="main-container-green">{{travelProduct.sum | number:'1.2-2'}} грн.</mark></h4>
              </div>

              <div class="overflow-hidden">
                <ul class="no-style">
                  <li>Oпції
                    <ul class="style-check" *ngFor="let riskLimit of travelProduct.riskLimits">
                      <li *ngIf="riskLimit.price > 0">{{riskLimit.description}} <mark>{{riskLimit.price | number:'1.0-0'}} {{riskLimit.currency}}</mark></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-8 col-lg-8">

          <form #orderNgForm="ngForm" [formGroup]="orderForm"
                  fxLayout="column" [fxLayoutGap]="isEditMode ? '1em' : '0'" autocomplete="off">

            <div class="card" *ngIf="!loadingDataSource">
              <div class="card-body" data-zanim-timeline="{}" data-zanim-trigger="scroll">

                <div>
                  <mat-icon class="mat-icon-md" aria-hidden="true" svgIcon="">contacts</mat-icon>
                  <h4 class="header">{{'travel-insurance-order.editor.Contacts' | translate}}</h4>
                </div>

                <div fxLayout.lt-md="column" fxLayoutGap="1em">
                  <mat-form-field [floatLabel]="floatLabels" fxFlex>
                    <mat-label>{{'travel-insurance-order.editor.Email' | translate}}</mat-label>
                    <input matInput formControlName="email" [class.auto-width]="true" [readonly]="true" />
                  </mat-form-field>

                  <mat-form-field [floatLabel]="floatLabels" fxFlex>
                    <mat-label>{{'travel-insurance-order.editor.PhoneNumber' | translate}}</mat-label>
                    <input matInput formControlName="phoneNumber"
                          mask="99 999 999 99 99"
                          placeholder="38 050 000 00 00"
                          [readonly]="!isEditMode" />

                    <mat-error *ngIf="phoneNumber.hasError('required')">
                      {{'travel-insurance-order.editor.PhoneNumberRequired' | translate}}
                    </mat-error>
                    <mat-error *ngIf="!phoneNumber.hasError('required') && phoneNumber.hasError('pattern')">
                      {{'travel-insurance-order.editor.PhoneNumberRegex' | translate}}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="card" *ngIf="!loadingDataSource">
              <div class="card-body" data-zanim-timeline="{}" data-zanim-trigger="scroll">
                <div>
                  <mat-icon class="mat-icon-md" aria-hidden="true" svgIcon="passport"></mat-icon>
                  <h4 class="header">{{'travel-insurance-order.editor.Document' | translate}}</h4>
                </div>

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">

                  <mat-form-field [floatLabel]="floatLabels" fxFlex>
                    <mat-label>{{'travel-insurance-order.editor.Firstname' | translate}}</mat-label>
                    <input matInput formControlName="firstname" [readonly]="!isEditMode" />
                    <mat-error *ngIf="firstname.hasError('required')">
                      {{'travel-insurance-order.editor.FirstnameRequired' | translate}}
                    </mat-error>
                    <mat-error *ngIf="!firstname.hasError('required') && firstname.hasError('pattern')">
                        {{'travel-insurance-order.editor.FirstnameRegex' | translate}}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field [floatLabel]="floatLabels" fxFlex>
                    <mat-label>{{'travel-insurance-order.editor.Lastname' | translate}}</mat-label>
                    <input matInput formControlName="lastname" [readonly]="!isEditMode" />
                    <mat-error *ngIf="lastname.hasError('required')">
                      {{'travel-insurance-order.editor.LastnameRequired' | translate}}
                    </mat-error>
                    <mat-error *ngIf="!lastname.hasError('required') && lastname.hasError('pattern')">
                        {{'travel-insurance-order.editor.LastnameRegex' | translate}}
                    </mat-error>
                  </mat-form-field>

                </div>

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
                  <mat-form-field [floatLabel]="floatLabels" fxFlex>
                    <mat-label>{{'travel-insurance-order.editor.Middlename' | translate}}</mat-label>
                    <input matInput formControlName="middlename" [readonly]="!isEditMode" />
                    <mat-error *ngIf="middlename.hasError('required')">
                      {{'travel-insurance-order.editor.MiddlenameRequired' | translate}}
                    </mat-error>
                    <mat-error *ngIf="!middlename.hasError('required') && middlename.hasError('pattern')">
                        {{'travel-insurance-order.editor.MiddlenameRegex' | translate}}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field [floatLabel]="floatLabels" fxFlex>
                    <mat-label>{{'travel-insurance-order.editor.Birthday' | translate}}</mat-label>

                    <input #myBirthday matInput formControlName="birthday"
                            [matDatepicker]="birthdayPicker"
                            appMaskDate
                            [disabled]="!isEditMode"
                           />

                    <mat-hint>день.місяць.рік</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="birthdayPicker"></mat-datepicker-toggle>
                    <mat-datepicker #birthdayPicker></mat-datepicker>

                    <mat-error *ngIf="birthday.hasError('required')">
                      {{'travel-insurance-order.editor.BirthdayRequired' | translate}}
                    </mat-error>
                    <mat-error *ngIf="!birthday.hasError('required') && birthday.hasError('pattern')">
                      {{'travel-insurance-order.editor.BirthdayRegex' | translate}}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
                  <mat-form-field [floatLabel]="floatLabels" fxFlex>
                    <mat-label>{{'travel-insurance-order.editor.RegistrationPlace' | translate}}</mat-label>
                    <input matInput [(ngModel)]="passportSelectedCity" formControlName="searchCity"
                        [matAutocomplete]="auto" placeholder="Мінімум 3 символи"
                        [readonly]="!isEditMode"
                        >
                    <button matSuffix mat-icon-button aria-label="Clear" (click)="clearCitySelection()"
                        [disabled]="!isEditMode" >
                      <mat-icon>cancel</mat-icon>
                    </button>

                    <mat-error *ngIf="!isValidSelectedCity">{{'travel-insurance-order.editor.RegistrationPlaceRequired' | translate}}</mat-error>

                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelected($event.option.value)"
                                      [displayWith]="displayWith"
                                      >
                      <mat-option *ngIf="isLoadingCity" class="is-loading">{{'travel-insurance-order.editor.RegistrationPlaceLoading' | translate}}</mat-option>
                      <ng-container *ngIf="!isLoadingCity">
                        <mat-option *ngFor="let city of filteredCities" [value]="city">
                          <span><b>{{city.town}}</b> {{city.region}}</span>
                        </mat-option>
                      </ng-container>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
                  <mat-form-field [floatLabel]="floatLabels" fxFlex>
                    <mat-label>{{'travel-insurance-order.editor.Street' | translate}}</mat-label>
                    <input matInput formControlName="street" [readonly]="!isEditMode" />

                    <mat-error *ngIf="street.hasError('required')">
                      {{'travel-insurance-order.editor.StreetRequired' | translate}}
                    </mat-error>

                    <mat-error *ngIf="!street.hasError('required') && street.hasError('pattern')">
                        {{'travel-insurance-order.editor.StreetRegex' | translate}}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field [floatLabel]="floatLabels" fxFlex>
                    <mat-label>{{'travel-insurance-order.editor.HouseNumber' | translate}}</mat-label>
                    <input matInput formControlName="houseNumber" [readonly]="!isEditMode" />

                    <mat-error *ngIf="houseNumber.hasError('required')">
                      {{'travel-insurance-order.editor.HouseNumberRequired' | translate}}
                    </mat-error>

                    <mat-error *ngIf="!houseNumber.hasError('required') && houseNumber.hasError('pattern')">
                        {{'travel-insurance-order.editor.HouseNumberRegex' | translate}}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field [floatLabel]="floatLabels" fxFlex>
                    <mat-label>{{'travel-insurance-order.editor.ApartmentNumber' | translate}}</mat-label>
                    <input matInput formControlName="apartmentNumber" [readonly]="!isEditMode" />

                    <mat-error *ngIf="apartmentNumber.hasError('required')">
                      {{'travel-insurance-order.editor.ApartmentNumberRequired' | translate}}
                    </mat-error>

                    <mat-error *ngIf="!apartmentNumber.hasError('required') && apartmentNumber.hasError('pattern')">
                        {{'travel-insurance-order.editor.ApartmentNumberRegex' | translate}}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="card" *ngIf="!loadingDataSource">
              <div class="card-body" data-zanim-timeline="{}" data-zanim-trigger="scroll">
                <div>
                  <mat-icon class="mat-icon-md" aria-hidden="true" svgIcon="people"></mat-icon>
                  <h4 class="header">{{'travel-insurance-order.editor.Travelers' | translate}}</h4>
                </div>

                <ng-container formArrayName="travelers">
                  <ng-container *ngFor="let travelerForm of travelers.controls; let i = index">
                      <div class="rounded-3 border-2 border-warning traveler" [formGroup]="travelerForm">
                        <div>
                          <div class="traveler-label"><h5>{{i + 1}}. Турист </h5></div>
                          <hr />
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">

                          <mat-form-field [floatLabel]="floatLabels" fxFlex>
                            <mat-label>{{'travel-insurance-order.editor.FirstnameEng' | translate}}</mat-label>
                            <input matInput formControlName="firstname"
                                  [readonly]="!isEditMode"
                                  oninput="this.value = this.value.toUpperCase()" />
                            <mat-error *ngIf="travelerForm.get('firstname').errors?.required">
                              {{'travel-insurance-order.editor.FirstnameEngRequired' | translate}}
                            </mat-error>
                            <mat-error *ngIf="!travelerForm.get('firstname').errors?.required && travelerForm.get('firstname').errors?.pattern">
                                {{'travel-insurance-order.editor.FirstnameEngRegex' | translate}}
                            </mat-error>
                          </mat-form-field>

                          <mat-form-field [floatLabel]="floatLabels" fxFlex>
                            <mat-label>{{'travel-insurance-order.editor.LastnameEng' | translate}}</mat-label>
                            <input matInput formControlName="lastname"
                                  [readonly]="!isEditMode"
                                  oninput="this.value = this.value.toUpperCase()" />
                            <mat-error *ngIf="travelerForm.get('lastname').errors?.required">
                              {{'travel-insurance-order.editor.LastnameEngRequired' | translate}}
                            </mat-error>
                            <mat-error *ngIf="!travelerForm.get('lastname').errors?.required && travelerForm.get('lastname').errors?.pattern">
                                {{'travel-insurance-order.editor.LastnameEngRegex' | translate}}
                            </mat-error>
                          </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
                          <mat-form-field [floatLabel]="floatLabels" fxFlex>
                            <mat-label>{{'travel-insurance-order.editor.Birthday' | translate}}</mat-label>

                            <input #myBirthday matInput formControlName="birthday"
                                    [matDatepicker]="birthdayPicker"
                                    appMaskDate
                                    [disabled]="true"
                                    readonly
                                   />

                            <mat-hint>день.місяць.рік</mat-hint>
                            <mat-datepicker-toggle matSuffix [for]="birthdayPicker"></mat-datepicker-toggle>
                            <mat-datepicker #birthdayPicker [disabled]="true"></mat-datepicker>

                            <mat-error *ngIf="travelerForm.get('birthday').errors?.required">
                              {{'travel-insurance-order.editor.BirthdayRequired' | translate}}
                            </mat-error>
                            <mat-error *ngIf="!travelerForm.get('birthday').errors?.required && travelerForm.get('birthday').errors?.pattern">
                              {{'travel-insurance-order.editor.BirthdayRegex' | translate}}
                            </mat-error>
                          </mat-form-field>

                          <mat-form-field [floatLabel]="floatLabels" fxFlex class="seria">
                            <mat-label>{{'travel-insurance-order.editor.Seria' | translate}}</mat-label>
                            <input matInput formControlName="seria"
                                    mask="UU||UUU" [patterns]="casePatterns"
                                    placeholder="AF or АЕМ"
                                    [validation]="true"
                                    [readonly]="!isEditMode"
                                    oninput="this.value = this.value.toUpperCase()" >
                            <mat-error *ngIf="travelerForm.get('seria').errors?.required">
                              {{'travel-insurance-order.editor.SeriaRequired' | translate}}
                            </mat-error>
                            <mat-error *ngIf="!travelerForm.get('seria').errors?.required && travelerForm.get('seria').errors?.pattern">
                              {{'travel-insurance-order.editor.SeriaRegex' | translate}}
                            </mat-error>
                          </mat-form-field>

                          <mat-form-field [floatLabel]="floatLabels" fxFlex class="number">
                            <mat-label>{{'travel-insurance-order.editor.Number' | translate}}</mat-label>
                            <input matInput formControlName="number"
                                  mask="999999||9999999||99999999||999999999"
                                  placeholder="123456 to 123456789"
                                  [readonly]="!isEditMode" />

                            <mat-error *ngIf="travelerForm.get('number').errors?.required">
                              {{'travel-insurance-order.editor.NumberRequired' | translate}}
                            </mat-error>
                            <mat-error *ngIf="!travelerForm.get('number').errors?.required && travelerForm.get('number').errors?.pattern">
                                {{'travel-insurance-order.editor.NumberRegex' | translate}}
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                  </ng-container>
              </ng-container>

              </div>
            </div>

            <div class="card" *ngIf="!loadingDataSource && !isEditMode">
              <div class="card-body" data-zanim-timeline="{}" data-zanim-trigger="scroll">

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">

                  <mat-slide-toggle formControlName="acceptedByClient"
                        >{{'travel-insurance-order.editor.AcceptedByClient' | translate}}</mat-slide-toggle>

                </div>

                <div *ngIf="acceptedByClient.hasError('required') && orderNgForm.submitted " class="mat-error">
                  {{'travel-insurance-order.editor.AcceptedByClientRequired' | translate}}
                </div>

              </div>
            </div>

            <div class="card" *ngIf="!loadingDataSource">
              <div class="card-body" data-zanim-timeline="{}" data-zanim-trigger="scroll">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
                  <mat-action-row *ngIf="!loadingDataSource">
                    <button mat-raised-button  class="back-button" [disabled]="isSaving" (click)="back()"
                      *ngIf="!isEditMode" color="warn"
                        >{{'travel-insurance-order.commands.Back' | translate}}</button>

                    <button mat-raised-button type="submit" form="ngForm" class="spinner-button" [disabled]="isSaving"
                            *ngIf="isEditMode" (click)="validate()" color="primary"
                            >
                      <mat-spinner *ngIf="isSaving" [diameter]="20" color="primary"></mat-spinner>
                      <span>{{'travel-insurance-order.commands.Validate' | translate}}</span>
                    </button>

                    <button mat-raised-button class="spinner-button" [disabled]="isSaving"
                            *ngIf="!isEditMode" (click)="save()" color="primary"
                            >
                      <mat-spinner *ngIf="isSaving" [diameter]="20" color="primary"></mat-spinner>
                      <span>{{'travel-insurance-order.commands.Save' | translate}}</span>
                    </button>
                  </mat-action-row>
                </div>
              </div>
            </div>

            <!-- https://github.com/angular/material2/issues/8798 -->
            <div>&nbsp;</div>
          </form>

          <form ngNoForm [action]="gatewayUrl" method="post" #paymentForm>
            <input type="hidden" id="payment" name="payment" [value]="payment">
            <input type="hidden" id="key" name="key" [value]="key">
            <input type="hidden" id="url" name="url" [value]="callbackUrl">
            <input type="hidden" id="data" name="data" [value]="data">
            <input type="hidden" id="req_token" name="req_token" [value]="req_token">
            <input type="hidden" id="sign" name="sign" [value]="sign">
            <input type="hidden" id="email" name="email" [value]="useremail">
            <input type="hidden" id="order" name="order" [value]="order">
            <input type="hidden" id="ext1" name="ext1" [value]="ext1">
            <input type="hidden" id="ext2" name="ext2" [value]="ext2">
            <input type="hidden" id="ext3" name="ext3" [value]="ext3">
            <input type="hidden" id="ext4" name="ext4" [value]="ext4">
          </form>

        </div>

      </div>
    </div>
  </section>

</main>
<!-- ===============================================-->
<!--    End of Main Content-->
<!-- ===============================================-->

<!-- ============================================-->
<!-- <section> begin ============================-->
<section style="background-color: #3D4C6F">

  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 mt-4 mt-lg-0">
        <div class="row">
          <div class="col-6 col-lg-4 text-white ms-lg-auto">
            <ul class="list-unstyled">
              <li class="mb-3"><a class="text-white" href="contact.html">Зв'яжіться з нами</a></li>
              <li class="mb-3"><a class="text-white" href="#!">FAQ</a></li>
              <li class="mb-3"><a class="text-white" href="/policy">Політика конфіденційності</a></li>
              <li class="mb-3"><a class="text-white" href="/termsofuse">Умови використання</a></li>
            </ul>
          </div>
          <div class="col-6 col-sm-5 ms-sm-auto">
            <ul class="list-unstyled">
              <li class="mb-3"><a class="text-decoration-none d-flex align-items-center" href="#!"> <span class="brand-icon me-3"><span class="fab fa-twitter"></span></span>
                  <h5 class="fs-0 text-white mb-0 d-inline-block">Twitter</h5>
                </a></li>
              <li class="mb-3"><a class="text-decoration-none d-flex align-items-center" href="#!"> <span class="brand-icon me-3"><span class="fab fa-facebook-f"></span></span>
                  <h5 class="fs-0 text-white mb-0 d-inline-block">Facebook</h5>
                </a></li>
                <li class="mb-3 mb-3-custom">
                  <a class="text-decoration-none d-flex align-items-center" href='https://play.google.com/store/apps/details?id=com.finance.sydi.mobile_sydi_finance'>
                    <img
                      class="android"
                      alt='Get it on Google Play'
                      src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png' />
                  </a>
                </li>

                <li class="mb-3">
                  <a class="text-decoration-none d-flex align-items-center" href="https://apps.apple.com/">
                    <img
                      class="apple" style="margin-left:0%;width:73%"
                      src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1276560000&h=7e7b68fad19738b5649a1bfb78ff46e9"
                      alt="Download on the App Store">
                  </a>
                </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end of .container-->

</section>
<!-- <section> close ============================-->
<!-- ============================================-->
