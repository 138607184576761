import { Gender } from "./Gender";

export class Passport {

    constructor(id?: string,
            applicationUserId?: string,
            firstname?: string,
            lastname?: string,
            middlename?: string,
            birthday?: Date,
            seria?: string,
            number?: string,
            issuer?: string,
            issued?: Date,
            validTo?: Date,

            isIdCard?: boolean,
            street?: string,
            houseNumber?: string,
            apartmentNumber?: string,
            cityKoatuuId?: string,
            cityName?: string,
            birthPlace?: string,
            nationality?: string,
            sex?: Gender,
            recordNumber?: string,

            createdBy?: string,
            createdDate?: Date,
            updatedBy?: string,
            updatedDate?: Date  ) {
        this.id = id;
        this.applicationUserId = applicationUserId;
        this.firstname = firstname;
        this.lastname = lastname;
        this.middlename = middlename;
        this.birthday = birthday;
        this.seria = seria;
        this.number = number;
        this.issuer = issuer;
        this.issued = issued;
        this.validTo = validTo;

        this.isIdCard = isIdCard;
        this.street = street;
        this.houseNumber = houseNumber;
        this.apartmentNumber = apartmentNumber;
        this.cityKoatuuId = cityKoatuuId;
        this.cityName = cityName;
        this.birthPlace = birthPlace;
        this.nationality = nationality;
        this.sex = sex;
        this.recordNumber = recordNumber;

        this.createdBy = createdBy;
        this.createdDate = createdDate;
        this.updatedBy = updatedBy;
        this.updatedDate = updatedDate;
    }

    public id: string;
    public applicationUserId: string;
    public firstname: string;
    public lastname: string;
    public middlename: string;
    public birthday: Date;
    public seria: string;
    public number: string;
    public issuer: string;
    public issued: Date;
    public validTo: Date;

    public isIdCard: boolean;
    public street: string;
    public houseNumber: string;
    public apartmentNumber: string;
    public cityKoatuuId: string;
    public cityName: string;
    public birthPlace: string;
    public nationality: string;
    public sex: Gender;
    public recordNumber: string;

    public createdBy: string;
    public createdDate: Date;
    public updatedBy: string;
    public updatedDate: Date;
}