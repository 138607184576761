
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { AppTranslationService } from './app-translation.service';
import { LocalStoreManager } from './local-store-manager.service';
import { DBkeys } from './db-keys';
import { Utilities } from './utilities';
import { environment } from '../../environments/environment';

interface UserConfiguration {
    language: string;
    themeId: number;
}

@Injectable()
export class ConfigurationService {

    constructor(
        private localStorage: LocalStoreManager,
        private translationService: AppTranslationService) {

        this.loadLocalChanges();
    }

    set language(value: string) {
        this._language = value;
        this.saveToLocalStore(value, DBkeys.LANGUAGE);
        this.translationService.changeLanguage(value);
    }
    get language() {
        return this._language || ConfigurationService.defaultLanguage;
    }

    set themeId(value: number) {
        this._themeId = value;
        this.saveToLocalStore(value, DBkeys.THEME_ID);
    }
    get themeId() {
        return this._themeId || ConfigurationService.defaultThemeId;
    }

    public static readonly appVersion: string = '5.0.0';

    // ***Specify default configurations here***
    public static readonly defaultLanguage: string = 'uk';//'en';
    public static readonly defaultHomeUrl: string = '/';
    public static readonly defaultThemeId: number = 1;

    public baseUrl = environment.baseUrl || Utilities.baseUrl();
    public tokenUrl = environment.tokenUrl || environment.baseUrl || Utilities.baseUrl();
    public loginUrl = environment.loginUrl;
    public googleClientId = environment.googleClientId;
    public facebookClientId = environment.facebookClientId;
    public appleClientId = environment.appleClientId;
    public appleRedirectUrl = environment.appleRedirectUrl;
    public currentBaseUrl = environment.currentUrl;
    public fallbackBaseUrl = environment.currentUrl;

    public platonApiKey = environment.platonApiKey;
    public platonApiPassword = environment.platonApiPassword;
    public platonApiUrl = environment.platonApiUrl;

    // ***End of defaults***

    private _language: string = null;
    private _themeId: number = null;
    private onConfigurationImported: Subject<boolean> = new Subject<boolean>();

    configurationImported$ = this.onConfigurationImported.asObservable();

    private loadLocalChanges() {
        if (this.localStorage.exists(DBkeys.LANGUAGE)) {
            this._language = this.localStorage.getDataObject<string>(DBkeys.LANGUAGE);
            this.translationService.changeLanguage(this._language);
        } else {
            this.resetLanguage();
        }

        if (this.localStorage.exists(DBkeys.THEME_ID)) {
            this._themeId = this.localStorage.getDataObject<number>(DBkeys.THEME_ID);
        }
    }

    private saveToLocalStore(data: any, key: string) {
        setTimeout(() => this.localStorage.savePermanentData(data, key));
    }

    public import(jsonValue: string) {
        this.clearLocalChanges();

        if (jsonValue) {
            const importValue: UserConfiguration = Utilities.JsonTryParse(jsonValue);

            if (importValue.language != null) {
                this.language = importValue.language;
            }

            if (importValue.themeId != null) {
                this.themeId = importValue.themeId;
            }
        }

        this.onConfigurationImported.next(true);
    }

    public export(changesOnly = true): string {
        const exportValue: UserConfiguration = {
            language: changesOnly ? this._language : this.language,
            themeId: changesOnly ? this._themeId : this.themeId
        };

        return JSON.stringify(exportValue);
    }

    public clearLocalChanges() {
        this._language = null;
        this._themeId = null;

        this.localStorage.deleteData(DBkeys.LANGUAGE);
        this.localStorage.deleteData(DBkeys.THEME_ID);

        this.resetLanguage();
    }

    private resetLanguage() {
        //const language = this.translationService.useBrowserLanguage();

        const language = this.translationService.useDefaultLangage();
        if (language) {
            this._language = language;
        } else {
            this._language = this.translationService.useDefaultLangage();
        }
    }
}
