<h1 mat-dialog-title>{{'mainMenu.Login' | translate}}</h1>
<div mat-dialog-content>
  <app-login-control #loginControl [isModal]="true"></app-login-control>
</div>
<div mat-dialog-actions>
  <div fxFill fxLayout="column">
    <div>
      <button mat-raised-button class="spinner-button" color="primary" (click)="loginControl.login()">
        <mat-spinner *ngIf="loginControl.isLoading && !loginControl.isExternalLogin" [diameter]="20" color="primary"></mat-spinner>
        <span>{{'commands.Login' | translate}}</span>
      </button>
      <div fxFlex></div>
      <button mat-raised-button (click)="onCancel()">{{'commands.Cancel' | translate}}</button>
    </div>
    <div class="mat-typography social-logins-divider" fxLayout="row" fxLayoutAlign="space-around center">
      <mat-divider></mat-divider>
      <span>{{'form.Or' | translate}}</span>
      <mat-divider></mat-divider>
    </div>
    <div class="social-logins-buttons" fxLayout="column" fxLayoutAlign="space-between stretch">
      <button class="spinner-button" mat-raised-button [disabled]="loginControl.isLoading" (click)="loginControl.loginWithGoogle()">
        <mat-icon *ngIf="!(loginControl.isLoading && loginControl.isGoogleLogin)" class="googleIcon" svgIcon="google"></mat-icon>
        <mat-spinner *ngIf="loginControl.isLoading && loginControl.isGoogleLogin" [diameter]="20"></mat-spinner>
        <span>Увійдіть через Google</span>
      </button>
      <button class="spinner-button" mat-raised-button [color]="'facebook'" [disabled]="loginControl.isLoading" (click)="loginControl.loginWithFacebook()">
        <mat-icon *ngIf="!(loginControl.isLoading && loginControl.isFacebookLogin)" svgIcon="facebook" class="facebookIcon"></mat-icon>
        <mat-spinner *ngIf="loginControl.isLoading && loginControl.isFacebookLogin" [diameter]="20" color="accent"></mat-spinner>
        <span >Вхід через Facebook</span>
      </button>
      <button class="spinner-button" mat-raised-button [color]="'apple'" [disabled]="loginControl.isLoading" (click)="loginControl.loginWithApple()">
        <mat-icon *ngIf="!(loginControl.isLoading && loginControl.isAppleLogin)" svgIcon="apple1" class="appleIcon"></mat-icon>
        <mat-spinner *ngIf="loginControl.isLoading && loginControl.isAppleLogin" [diameter]="20" color="accent"></mat-spinner>
        <span >Вхід через Apple</span>
      </button>
      <!--<button class="spinner-button" mat-raised-button [color]="'twitter'" [disabled]="loginControl.isLoading" (click)="loginControl.loginWithTwitter()">
        <mat-icon *ngIf="!(loginControl.isLoading && loginControl.isTwitterLogin)" svgIcon="twitter"></mat-icon>
        <mat-spinner *ngIf="loginControl.isLoading && loginControl.isTwitterLogin" [diameter]="20"></mat-spinner>
        <span >Twitter</span>
      </button>-->
    </div>
  </div>
</div>
