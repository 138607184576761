
import { Component } from '@angular/core';
import { fadeInOut } from '../../services/animations';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './termsofuse.component.html',
  styleUrls: ['./termsofuse.component.scss'],
  animations: [fadeInOut]
})
export class TermsOfUseComponent { }
