
    <!-- ===============================================-->
    <!--    Main Content-->
    <!-- ===============================================-->
    <main class="main" id="top">

      <!-- ============================================-->
      <!-- <section> begin ============================-->
      <section class="py-0">

        <div class="bg-holder overlay overlay-2" style="background-image:url(assets/img/header-5.jpg);">
        </div>
        <!--/.bg-holder-->

        <div class="container">
          <div class="row min-vh-100 align-items-center">
            <div class="col-md-8 col-lg-5 mx-auto" data-zanim-timeline="{}" data-zanim-trigger="scroll">
              <div class="mb-5"></div>
              <div class="mb-3" data-zanim-xs='{"delay":0,"duration":1}'><a href="/index.html"><img src="assets/img/logo-4.png" alt="SYDI.Finance" /></a></div>

              <div class="card" data-zanim-xs='{"delay":0.1,"duration":1}'>

                <mat-card class="mat-elevation-z6">
                  <h4 class="fs-0 fs-md-1">{{'mainMenu.Login' | translate}}</h4>
                  <mat-card-content>
                    <app-login-control [isModal]="false" (enterKeyPress)="loginControl.login()"></app-login-control>
                  </mat-card-content>
                  <mat-card-actions>
                    <div>
                      <button mat-raised-button class="spinner-button" color="primary" [disabled]="loginControl.isLoading" (click)="loginControl.login()">
                        <mat-spinner *ngIf="loginControl.isLoading && !loginControl.isExternalLogin" [diameter]="20" color="primary"></mat-spinner>
                        <span>{{'commands.Login' | translate}}</span>
                      </button>
                      <div fxFlex></div>
                      <a mat-button routerLink="/recover-password">{{'commands.ForgotPassword' | translate}}</a>
                      <mat-divider [vertical]="true"></mat-divider>
                      <a mat-button routerLink="/register">{{'commands.Registration' | translate}}</a>
                    </div>
                    <div class="mat-typography social-logins-divider" fxLayout="row" fxLayoutAlign="space-around center">
                      <mat-divider [inset]="true"></mat-divider>
                      <span>{{'form.Or' | translate}}</span>
                      <mat-divider [inset]="true"></mat-divider>
                    </div>
                    <div class="social-logins-buttons" fxLayout="column" fxLayoutAlign="space-between stretch">
                      <button mat-raised-button class="spinner-button" [disabled]="loginControl.isLoading" (click)="loginControl.loginWithGoogle()">
                        <mat-icon *ngIf="!(loginControl.isLoading && loginControl.isGoogleLogin)" svgIcon="google" class="googleIcon"></mat-icon>
                        <mat-spinner *ngIf="loginControl.isLoading && loginControl.isGoogleLogin" [diameter]="20" color="primary"></mat-spinner>
                        <span>Увійдіть через Google</span>
                      </button>
                      <button mat-raised-button class="spinner-button" [color]="'facebook'" [disabled]="loginControl.isLoading" (click)="loginControl.loginWithFacebook()">
                        <mat-icon *ngIf="!(loginControl.isLoading && loginControl.isFacebookLogin)" svgIcon="facebook" class="facebookIcon"></mat-icon>
                        <mat-spinner *ngIf="loginControl.isLoading && loginControl.isFacebookLogin" [diameter]="20" color="accent"></mat-spinner>
                        <span>Вхід через Facebook</span>
                      </button>
                      <button mat-raised-button class="spinner-button" [color]="'apple'" [disabled]="loginControl.isLoading" (click)="loginControl.loginWithApple()">
                        <mat-icon *ngIf="!(loginControl.isLoading && loginControl.isAppleLogin)" [inline]="true" svgIcon="apple1" class="appleIcon"></mat-icon>
                        <mat-spinner *ngIf="loginControl.isLoading && loginControl.isAppleLogin" [diameter]="20" color="accent"></mat-spinner>
                        <span>Вхід через Apple</span>
                      </button>
                      <!--<button mat-raised-button class="spinner-button" [color]="'twitter'" [disabled]="loginControl.isLoading" (click)="loginControl.loginWithTwitter()">
                        <mat-icon *ngIf="!(loginControl.isLoading && loginControl.isTwitterLogin)" svgIcon="twitter"></mat-icon>
                        <mat-spinner *ngIf="loginControl.isLoading && loginControl.isTwitterLogin" [diameter]="20" color="primary"></mat-spinner>
                        <span>Twitter</span>
                      </button>-->
                    </div>
                  </mat-card-actions>
                </mat-card>


              </div>
            </div>
          </div>
        </div>
        <!-- end of .container-->

      </section>
      <!-- <section> close ============================-->
      <!-- ============================================-->


    </main>
    <!-- ===============================================-->
    <!--    End of Main Content-->
    <!-- ===============================================-->


