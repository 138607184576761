import { CarTypePropertyModel } from "./cartypeproperty.model";

export class CarTypeModel {

    constructor(id?: number, name?: string, description?: string) {
        this.Id = id;
        this.Name = name;
        this.Description = description;
    }

    public Id: number;
    public Name: string;
    public Description: string;
}