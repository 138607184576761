

    <!-- ===============================================-->
    <!--    Main Content-->
    <!-- ===============================================-->
    <main class="main" id="top">


      <!-- ============================================-->
      <!-- <section> begin ============================-->
      <section class="text-center py-0">

        <div class="bg-holder overlay overlay-1" style="background-image:url(../assets/img/background-1.jpg);">
        </div>
        <!--/.bg-holder-->

        <div class="container">
          <div class="row min-vh-100 align-items-center">
            <div class="col-md-9 col-lg-6 mx-auto" data-zanim-timeline="{}" data-zanim-trigger="scroll">
              <div class="mb-5"></div>
              <div class="mb-5" data-zanim-xs='{"delay":0,"duration":1}'><a href="index.html"><img src="assets/img/logo-4.png" alt="SYDI Finance" /></a></div>

              <div class="card" data-zanim-xs='{"delay":0.1,"duration":1}'>

                <mat-card class="mat-elevation-z6">
                  <mat-card-header>
                    <mat-card-title><h4>Реєстрація</h4></mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    <form #form="ngForm" [formGroup]="registerForm" (ngSubmit)="register()" novalidate class="app-register-form">

                      <mat-form-field class="app-validate">
                        <input matInput formControlName="email" placeholder="Email" autocomplete="username" type="text" (keydown.enter)="register()">
                        <mat-error *ngIf="email.hasError('required')">
                          Адреса електронної пошти є <strong>обов'язковою</strong> (максімум 200 символів)
                        </mat-error>
                        <mat-error *ngIf="!email.hasError('required') && email.hasError('email')">
                          В електронній адресі <strong>помилка</strong>
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field class="app-validate" formGroupName="password">
                        <input matInput formControlName="newPassword" placeholder="Password" autocomplete="new-password" (keydown.enter)="register()"
				                    [type]="hidePassword ? 'password' : 'text'">
			                  <mat-icon matSuffix style="cursor: pointer;" (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                        <mat-error *ngIf="newPassword.hasError('required')">
                          Пароль є <strong>обов'язковим</strong>
                        </mat-error>
                        <mat-error *ngIf="!newPassword.hasError('required') && newPassword.hasError('pattern')">
                          Пароль має містити щонайменше: одну велику літеру, одну малу літеру та одну цифру
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field class="app-validate" formGroupName="password">
                        <input matInput formControlName="confirmPassword" placeholder="Confirm Password" autocomplete="new-password" (keydown.enter)="register()"
			                        [type]="hideConfirmPassword ? 'password' : 'text'">
                        <mat-icon matSuffix style="cursor: pointer;" (click)="hideConfirmPassword = !hideConfirmPassword">{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                        <mat-error *ngIf="confirmPassword.hasError('required')">
                          Пароль підтвердження є <strong>обов'язковим</strong>
                        </mat-error>
                        <mat-error *ngIf="!confirmPassword.hasError('required') && confirmPassword.hasError('notEqual')">
                          Пароль та Пароль підтвердження <strong>не співпадає</strong>
                        </mat-error>
                      </mat-form-field>

                      <p class="error">{{backendErrorMessage}}</p>

                    </form>
                  </mat-card-content>
                  <mat-card-actions>
                    <button mat-raised-button color="primary" [disabled]="isLoading" (click)="register()">
                      <mat-spinner *ngIf="isLoading" [diameter]="20"></mat-spinner>
                      Реєстрація
                    </button>
                    <a mat-button routerLink="/login">Відмінити</a>
                  </mat-card-actions>
                </mat-card>

              </div>
            </div>
          </div>
        </div>
        <!-- end of .container-->

      </section>
      <!-- <section> close ============================-->
      <!-- ============================================-->


    </main>
    <!-- ===============================================-->
    <!--    End of Main Content-->
    <!-- ===============================================-->
