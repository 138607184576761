
import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { CatalogEndpoint } from './catalog-endpoint.service';
import { AuthService } from './auth.service';
import { City } from '../models/city.model';
import { CarType } from '../models/CarType';
import { CarTypeProperty } from '../models/CarTypeProperty';
import { CarBrandView } from '../models/carbrandview.model';


@Injectable()
export class CatalogService {

    constructor(
        private authService: AuthService,
        private catalogEndpoint: CatalogEndpoint) {

    }

    getCities(city?: string) {
        return this.catalogEndpoint.getCitiesEndpoint<City[]>(city);
    }

    getCity(cityCode?: string) {
      return this.catalogEndpoint.getCityEndpoint<City>(cityCode);
    }

    getCarBrands(carType?: CarType, carTypeProperty?: CarTypeProperty) {
      return this.catalogEndpoint.getCarBrandsEndpoint<CarBrandView[]>(carType, carTypeProperty);
    }
}
