
import { Component } from '@angular/core';

import packageJson from '../../../../package.json';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent {

  public version: string = packageJson.version;
  public configurationName: string = environment.configurationName;

  getYear() {
    return new Date().getUTCFullYear();
  }
}
