export class DriverLicence {

    constructor(id?: string,
            applicationUserId?: string,
            firstname?: string,
            lastname?: string,
            middlename?: string,
            birthday?: Date,
            seria?: string,
            number?: string,
            issuer?: string,
            issued?: Date,
            validTo?: Date,

            createdBy?: string,
            createdDate?: Date,
            updatedBy?: string,
            updatedDate?: Date  ) {
        this.id = id;
        this.applicationUserId = applicationUserId;
        this.firstname = firstname;
        this.lastname = lastname;
        this.middlename = middlename;
        this.birthday = birthday;
        this.seria = seria;
        this.number = number;
        this.issuer = issuer;
        this.issued = issued;
        this.validTo = validTo;

        this.createdBy = createdBy;
        this.createdDate = createdDate;
        this.updatedBy = updatedBy;
        this.updatedDate = updatedDate;
    }

    public id: string;
    public applicationUserId: string;
    public firstname: string;
    public lastname: string;
    public middlename: string;
    public birthday: Date;
    public seria: string;
    public number: string;
    public issuer: string;
    public issued: Date;
    public validTo: Date;

    public createdBy: string;
    public createdDate: Date;
    public updatedBy: string;
    public updatedDate: Date;
}