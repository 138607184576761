
<main class="main" id="top">

      <!-- ============================================-->
      <!-- <section> begin ============================-->
      <section>

        <div class="container">

		      <h3>Умови використання</h3>
                <p>Оновлено: 19 травня 2023</p>

		            <p>
                  Під час завантаження або використання програми ці умови будуть
                   автоматично застосовуються до вас – тому ви повинні переконатися
                   щоб ви уважно прочитали їх перед використанням програми. Ти не
                   дозволено копіювати або змінювати програму, будь-яку частину програми або
                   наші торгові марки будь-яким способом. Вам заборонено намагатися
                   витягніть вихідний код програми, і ви також не повинні намагатися
                   щоб перекласти програму іншими мовами або зробити її похідною
                   версії. Сама програма та всі торгові марки, авторські права,
                   права на бази даних та інші права інтелектуальної власності
                   до нього, як і раніше належать SYDI.Finance.
                </p> <p>
                  SYDI.Finance прагне гарантувати, що додаток є
                   максимально корисним і ефективним. З цієї причини ми
                   залишає за собою право вносити зміни в додаток у будь-який
                   час і з будь-якої причини. Ми ніколи не будемо
                   стягувати плату за програму.
                </p> <p>
                  Програма SYDI.Finance зберігає та обробляє особисті дані, які
                  ви надали нам, щоб забезпечити наш Сервіс. Ви несете
                  відповідальність за збереження свого телефону та безпечний
                  доступ до програми. Тому ми рекомендуємо вам це зробити
                  не джейлбрейк або рутування вашого телефону, що є процесом
                  усунення програмних обмежень і обмежень, накладених
                  офіційна операційна система вашого пристрою. Це може зробити ваш
                  телефон вразливий до шкідливих програм/вірусів/шкідливих програм,
                  скомпрометувати функції безпеки вашого телефону, і це може означати
                  що програма SYDI.Finance не працюватиме належним чином
                  або взагалі не працюватиме.
                </p> <p>
                    Додаток використовує сторонні сервіси, які оголошують
                    власні Умови використання.
                  </p> <p>
                    Посилання на Загальні положення та умови сторонніх постачальників
                    послуг, які використовуються програмою
                  </p> <p>
                  Ви повинні знати, що є певні речі, які
                  SYDI.Finance не бере на себе відповідальності. Певний
                  функції програми вимагатимуть, щоб програма мала активний
                  підключення до інтернету. Підключення може бути Wi-Fi або наданим
                  вашим постачальником мобільної мережі, але SYDI.Finance
                  не може нести відповідальність за те, що у програмі не працює повною мірою
                  функціональність, якщо у вас немає доступу до Wi-Fi, а у вас його немає
                  у вас залишилося будь-яке обмеження даних.
                </p> <p></p> <p>
                  Якщо ви використовуєте програму за межами зони з Wi-Fi, ви
                  слід пам’ятати, що ваші умови угоди з вашим
                  оператор мобільного зв’язку все одно застосовуватиметься. У результаті
                  Ваш оператор мобільного зв’язку може стягуватиме плату за вартість даних для
                  тривалість з’єднання під час доступу до програми, або
                  інші збори третьої сторони. Використовуючи додаток, ви приймаєте
                  відповідальність за будь-які такі збори, включно з даними в роумінгу
                  за які стягується плата, якщо ви використовуєте додаток за межами вашої території
                  (тобто регіон або країна) без вимкнення роумінгу даних. Якщо
                  ви не є платником рахунків за пристрій, на якому ви перебуваєте
                  користуючись програмою, майте на увазі, що ми припускаємо, що у вас є
                  дозвіл від платника рахунка на використання програми.
                </p> <p>
                  Таким же чином SYDI.Finance не завжди несе відповідальність за те,
                  як ви використовуєте програму, тобто вам потрібно
                  переконайтеся, що ваш пристрій залишається зарядженим – якщо розрядиться
                  акумулятор, і ви не можете ввімкнути його, щоб скористатися Послугою,
                  SYDI.Finance не несе відповідальності.
                </p> <p>
                  Що стосується відповідальності SYDI.Finance за вашу
                  використання програми, важливо, щоб під час використання програми
                  майте на увазі, що хоча ми докладаємо всіх зусиль, щоб це було так
                  постійно оновлюються та виправляються, ми покладаємося на треті сторони
                  щоб надати нам інформацію, щоб ми могли зробити її доступною
                  тобі. SYDI.Finance не несе жодної відповідальності
                  втрати, прямі чи непрямі, яких ви зазнаєте внаслідок
                  повністю покладаючись на цю функцію програми.
                </p> <p>
                  У якийсь момент ми можемо забажати оновити додаток. Додаток є
                  наразі доступний на Android або iOS – вимоги до
                  системи (і для будь-яких додаткових систем ми
                  вирішити розширити доступність програми до) може змінитися,
                  і вам потрібно буде завантажити оновлення, якщо ви хочете зберегти
                  за допомогою програми. SYDI.Finance цього не обіцяє
                  завжди оновлюватиме програму, щоб вона була актуальною для вас
                  і/або працює з вашою версією Android обо iOS
                  яку встановлено на вашому пристрої. Однак ви обіцяєте завжди
                  приймати оновлення програми, коли вам пропонуються, Ми можемо
                  також припинити надання програми та припинити використання
                  у будь-який час без повідомлення про припинення дії.
                  Якщо ми не скажемо вам інше, після будь-якого розірвання, (a)
                  права та ліцензії, надані вам за цими умовами, припиняються;
                  (b) ви повинні припинити використання програми та (за потреби) видалити її
                  з вашого пристрою.
                </p> <p><strong>Як видалити Ваш обліковий запис?</strong></p> <p>
                  Для видалення облікового запису (акаунту) перейдіть Профіль -> Акаунт. Відправьте запит на Вашу електрону адресу.
		  Після підтвердіть запит на видалення Вашого облікового запису в електроному листі.
                </p> <p><strong>Зміни до цих Умов</strong></p> <p>
                  Я можу час від часу оновлювати наші Положення та умови.
                  Тому радимо періодично переглядати цю сторінку на предмет
                  будь-яких змін. Я повідомлятиму вас про будь-які зміни,
                  опублікувавши нові Умови використання на цій сторінці.
                </p> <p>
                  Ці умови набувають чинності з 10.06.2023
                </p> <p><strong>Зв'язатися з нами</strong></p> <p>
                  Якщо у вас є будь-які запитання чи пропозиції щодо моїх Умов,
                  не соромтеся зв’язатися зі нами за адресою info@sydi.finance.
                </p>


        </div>
    </section>
</main>
