
import { NgModule, ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { OAuthModule } from 'angular-oauth2-oidc';
import { ToastaModule } from 'ngx-toasta';
import { NgChartsModule } from 'ng2-charts';

import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';

import { NguCarouselModule } from '@ngu/carousel';

import { AppRoutingModule } from './app-routing.module';
import { AppErrorHandler } from './app-error.handler';

import { SharedModule } from './shared/shared.module';
import { AdminModule } from './admin/admin.module';
import { SettingsModule } from './settings/settings.module';
import { ThemePickerModule } from './shared/theme-picker/theme-picker.component';

import { AppTitleService } from './services/app-title.service';
import { AppTranslationService, TranslateLanguageLoader } from './services/app-translation.service';
import { ConfigurationService } from './services/configuration.service';
import { AlertService } from './services/alert.service';
import { LocalStoreManager } from './services/local-store-manager.service';
import { OidcHelperService } from './services/oidc-helper.service';
import { NotificationService } from './services/notification.service';
import { NotificationEndpoint } from './services/notification-endpoint.service';
import { AccountService } from './services/account.service';
import { AccountEndpoint } from './services/account-endpoint.service';
import { CatalogService } from './services/catalog.service';
import { CatalogEndpoint } from './services/catalog-endpoint.service';

import { CarInsuranceEndpoint } from './services/car-insurance-endpoint.service';
import { CarInsuranceService } from './services/car-insurance.service';

import { DriverLicenceEndpoint } from './services/driver-licence-endpoint.service';
import { DriverLicenceService } from './services/driver-licence.service';

import { PassportEndpoint } from './services/passport-endpoint.service';
import { PassportService } from './services/passport.service';

import { RegistrationCertificateEndpoint } from './services/registration-certificate-endpoint.service';
import { RegistrationCertificateService } from './services/registration-certificate.service';


import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { LoginControlComponent } from './components/login/login-control.component';
import { LoginDialogComponent } from './components/login/login-dialog.component';
import { AuthCallbackComponent } from './components/auth-callback/auth-callback.component';
import { RegisterComponent } from './components/register/register.component';
import { ConfirmEmailComponent } from './components/confirm-email/confirm-email.component';
import { ConfirmDeleteAccountComponent } from './components/confirm-delete-account/confirm-delete-account.component';
import { RecoverPasswordComponent } from './components/recover-password/recover-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';

import { HomeComponent } from './components/home/home.component';

import { CarInsuranceComponent } from './components/car-insurance/car-insurance.component';
import { CarInsuranceOffersComponent } from './components/car-insurance-offers/car-insurance-offers.component';

import { TravelInsuranceComponent } from './components/travel-insurance/travel-insurance.component';
import { TravelInsuranceOffersComponent } from './components/travel-insurance-offers/travel-insurance-offers.component';

import { DriverLicenceListComponent } from './settings/driver-licences/driver-licence-list.component';
import { PassportListComponent } from './settings/passports/passport-list.component';

import { CustomersComponent } from './components/customers/customers.component';
import { ProductsComponent } from './components/products/products.component';
import { OrdersComponent } from './components/orders/orders.component';
import { AboutComponent } from './components/about/about.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { PolicyComponent } from './components/policy/policy.component';
import { TermsOfUseComponent } from './components/terms-of-use/termsofuse.component';

import { BannerDemoComponent } from './components/controls/banner-demo.component';
import { TodoDemoComponent } from './components/controls/todo-demo.component';
import { StatisticsDemoComponent } from './components/controls/statistics-demo.component';
import { NotificationsViewerComponent } from './components/controls/notifications-viewer.component';
import { AddTaskDialogComponent } from './components/controls/add-task-dialog.component';
import { CarTypeService } from './services/cartype.service';
import { NavigationService } from './services/navigation.service';

// states
//import { LoginState } from './models/login.state';
import { environment } from 'src/environments/environment';

import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { NgxMaskModule } from 'ngx-mask';

import { registerLocaleData } from '@angular/common';
import localeUA from '@angular/common/locales/ru-UA';
import { CarInsuranceOrderComponent } from './components/car-insurance-order/car-insurance-order.component';
import { CommonService } from './services/common.service';
import { DEFAULT_TIMEOUT, TimeoutInterceptor } from './services/timeout-interceptor';
import { CarInsurancePaidComponent } from './components/car-insurance-paid/car-insurance-paid.component';
import { TravelEndpoint } from './services/travel-endpoint.service';
import { TravelService } from './services/travel.service';
import { TravelInsuranceOrderComponent } from './components/travel-insurance-offer/travel-insurance-order.component';
import { TravelInsurancePaidComponent } from './components/travel-insurance-paid/travel-insurance-paid.component';
import { SalamandraComponent } from './components/insurance-companies/salamandra/salamandra.component';

registerLocaleData(localeUA, 'ru-UA');

//const states = [LoginState];

@NgModule({
    imports: [
        SharedModule,
        ThemePickerModule,
        HttpClientModule,
        AdminModule,
        SettingsModule,

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        }),

        //NgxsModule.forRoot(states, { developmentMode: !environment.production }),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        NgxsFormPluginModule.forRoot(),
        NgxMaskModule.forChild(),
        OAuthModule.forRoot(),
        ToastaModule.forRoot(),
        NgChartsModule,
        NguCarouselModule,
        AppRoutingModule
    ],
    declarations: [
        AppComponent,
        LoginComponent, LoginControlComponent, LoginDialogComponent,
        AuthCallbackComponent,
        RegisterComponent,
        ConfirmEmailComponent,
        ConfirmDeleteAccountComponent,
        RecoverPasswordComponent,
        ResetPasswordComponent,
        HomeComponent,
        CarInsuranceComponent,
        TravelInsuranceComponent,
	      TravelInsuranceOffersComponent,
        CustomersComponent,
        ProductsComponent,
        OrdersComponent,
        AboutComponent,
        PolicyComponent,
        TermsOfUseComponent,
        NotFoundComponent,
        NotificationsViewerComponent,
        AddTaskDialogComponent,
        StatisticsDemoComponent, TodoDemoComponent, BannerDemoComponent,
        TravelInsuranceOffersComponent,
        CarInsuranceOffersComponent,
        CarInsuranceOrderComponent,
        CarInsurancePaidComponent,
        TravelInsuranceOrderComponent,
        TravelInsurancePaidComponent,
        SalamandraComponent
    ],
    providers: [
        { provide: ErrorHandler, useClass: AppErrorHandler },

        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},

        { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
        { provide: DEFAULT_TIMEOUT, useValue: 300000 }, //300 seconds

        CommonService,
        AlertService,
        ConfigurationService,
        AppTitleService,
        AppTranslationService,
        NotificationService,
        NotificationEndpoint,
        AccountService,
        AccountEndpoint,
        LocalStoreManager,
        OidcHelperService,
        CarTypeService,
        CatalogService,
        CatalogEndpoint,
        CarInsuranceEndpoint,
        CarInsuranceService,
        DriverLicenceEndpoint,
        DriverLicenceService,
        PassportEndpoint,
        PassportService,
        RegistrationCertificateEndpoint,
        RegistrationCertificateService,
        NavigationService,
        TravelEndpoint,
        TravelService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
