    <!-- ===============================================-->
    <!--    Main Content-->
    <!-- ===============================================-->
    <main class="main" id="top">


      <!-- ============================================-->
      <!-- <section> begin ============================-->
      <section class="bg-100 py-0">

        <div class="container">
          <div class="row min-vh-100 align-items-center">
	   <div class="col-12 px-0">


<app-page-header title="Settings" icon="settings"></app-page-header>

<div [@fadeInOut] class="page-content">

  <mat-accordion class="example-headers-align">
    <mat-expansion-panel #profile hideToggle="true" (opened)="navigateToFragment('profile')">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{'settings.tab.Profile' | translate}}
        </mat-panel-title>
        <mat-panel-description>
          {{'settings.description.UserProfile' | translate}}
          <mat-icon>account_circle</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <app-user-editor #userProfile></app-user-editor>

      <mat-action-row>
        <button mat-raised-button *ngIf="!userProfile.isEditMode" (click)="userProfile.beginEdit()" color="primary">{{'settings.commands.Edit' | translate}}</button>
        <button mat-raised-button *ngIf="userProfile.isEditMode" (click)="userProfile.save()" color="primary" style="margin-right: 1em;">{{'settings.commands.Save' | translate}}</button>
        <button mat-raised-button *ngIf="userProfile.isEditMode" (click)="userProfile.cancel()" color="warn">{{'settings.commands.Cancel' | translate}}</button>
      </mat-action-row>

    </mat-expansion-panel>

    <mat-expansion-panel #preferences hideToggle="true" (opened)="navigateToFragment('preferences')">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{'settings.tab.Preferences' | translate}}
        </mat-panel-title>
        <mat-panel-description>
          {{'settings.description.UserPreferences' | translate}}
          <mat-icon>tune</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <app-user-preferences></app-user-preferences>

      <mat-action-row>
        <button mat-button (click)="userPreferences.reset()"
                matTooltip="{{'preferences.ResetPreferencesHint' | translate}}"
                color="warn" style="margin-right: 1em;">
          {{'commands.Reset' | translate}}
        </button>
        <button mat-raised-button (click)="userPreferences.save()"
                matTooltip="{{'preferences.SavePreferencesHint' | translate}}"
                color="primary" style="margin-right: 1em;">
          {{'commands.Save' | translate}}
        </button>
        <button mat-raised-button (click)="userPreferences.reload()"
                matTooltip="{{'preferences.ReloadPreferencesHint' | translate}}"
                color="accent">
          {{'commands.Reload' | translate}}
        </button>
      </mat-action-row>

    </mat-expansion-panel>

    <mat-expansion-panel #driverlicences hideToggle="true" (opened)="navigateToFragment('driverlicences')">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{'settings.tab.DriverLicences' | translate}}
        </mat-panel-title>
        <mat-panel-description matTooltip="{{'settings.description.DriverLicences' | translate}}" #tooltip="matTooltip" >
          {{'settings.description.DriverLicences' | translate | shorter}}
          <mat-icon svgIcon="driverlicence"></mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <driver-licence-list></driver-licence-list>

    </mat-expansion-panel>

    <mat-expansion-panel #passports hideToggle="true" (opened)="navigateToFragment('passports')">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{'settings.tab.Passports' | translate}}
        </mat-panel-title>
        <mat-panel-description matTooltip="{{'settings.description.Passports' | translate}}" #tooltip="matTooltip" >
          {{'settings.description.Passports' | translate | shorter}}
          <mat-icon svgIcon="passport"></mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <passport-list></passport-list>
    </mat-expansion-panel>

    <mat-expansion-panel #registration_certificates hideToggle="true" (opened)="navigateToFragment('registration_certificates')">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{'settings.tab.RegistrationCertificates' | translate}}
        </mat-panel-title>
        <mat-panel-description matTooltip="{{'settings.description.RegistrationCertificates' | translate}}" #tooltip="matTooltip" >
          {{'settings.description.RegistrationCertificates' | translate | shorter}}
          <mat-icon>directions_car</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <registration-certificate-list></registration-certificate-list>

    </mat-expansion-panel>

    <mat-expansion-panel #car_insurances hideToggle="true" (opened)="navigateToFragment('car_insurances')">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{'settings.tab.CarInsurances' | translate}}
        </mat-panel-title>
        <mat-panel-description matTooltip="{{'settings.description.CarInsurances' | translate}}" #tooltip="matTooltip" >
          {{'settings.description.CarInsurances' | translate | shorter}}
          <mat-icon svgIcon="cat"></mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <car-insurance-list></car-insurance-list>

    </mat-expansion-panel>

    <mat-expansion-panel #travel_insurances hideToggle="true" (opened)="navigateToFragment('travel_insurances')">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{'settings.tab.TravelInsurances' | translate}}
        </mat-panel-title>
        <mat-panel-description matTooltip="{{'settings.description.TravelInsurances' | translate}}" #tooltip="matTooltip" >
          {{'settings.description.TravelInsurances' | translate | shorter}}
          <mat-icon svgIcon="travel"></mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <travel-insurance-list></travel-insurance-list>

    </mat-expansion-panel>

    <mat-expansion-panel #delete_account hideToggle="true" (opened)="navigateToFragment('delete_account')">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{'settings.tab.DeleteAccount' | translate}}
        </mat-panel-title>
        <mat-panel-description>
          {{'settings.description.DeleteAccount' | translate}}
          <mat-icon svgIcon="delete_user"></mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <app-delete-account></app-delete-account>

      <mat-action-row>
        <button mat-raised-button (click)="deleteAccount.save()"
                matTooltip="{{'deleteaccount.SendEmailHint' | translate}}"
                color="primary" style="margin-right: 1em;">
          {{'commands.SendEmail' | translate}}
        </button>
      </mat-action-row>

    </mat-expansion-panel>


  </mat-accordion>



</div>

	    </div>
          </div>
        </div>
        <!-- end of .container-->

      </section>
      <!-- <section> close ============================-->
      <!-- ============================================-->


    </main>
    <!-- ===============================================-->
    <!--    End of Main Content-->
    <!-- ===============================================-->
