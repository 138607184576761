
import { Component, OnInit, AfterViewInit, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { debounceTime, tap, switchMap, finalize, distinctUntilChanged, filter } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { ThemeService } from 'ng2-charts';

import { CarTypeModel } from 'src/app/models/cartype.model';
import { CarTypePropertyModel } from 'src/app/models/cartypeproperty.model';
import { CarTypeService } from 'src/app/services/cartype.service';

import { fadeInOut } from '../../services/animations';
import { ConfigurationService } from '../../services/configuration.service';

import { City } from 'src/app/models/city.model';
import { CatalogService } from 'src/app/services/catalog.service';
import { NavigationService } from 'src/app/services/navigation.service';


@Component({
  selector: 'car-insurance',
  templateUrl: './car-insurance.component.html',
  styleUrls: ['./car-insurance.component.scss'],
  animations: [fadeInOut]
})

export class CarInsuranceComponent implements OnInit, AfterViewInit, OnDestroy {

  filteredCities: City[] =
    [{id: '8000000000', town: 'М.КИЇВ', region: '',mainRegion:'', friendlyName:''},
     {id: '6310100000', town: 'ХАРКІВ' , region: '',mainRegion:'', friendlyName:''},
     {id: '1210100000', town: 'ДНІПРО' , region: '',mainRegion:'', friendlyName:''},
     {id: '4610100000', town: 'ЛЬВІВ' , region: '',mainRegion:'', friendlyName:''},
     {id: '5310100000', town: 'ПОЛТАВА' , region: '',mainRegion:'', friendlyName:''},
     {id: '0510100000', town: 'ВІННИЦЯ' , region: '',mainRegion:'', friendlyName:''},
     {id: '1410100000', town: 'ДОНЕЦЬК' , region: '',mainRegion:'', friendlyName:''},
     {id: '0110100000', town: 'СІМФЕРОПОЛЬ' , region: '',mainRegion:'', friendlyName:''},
     {id: '8500000000', town: 'М.СЕВАСТОПОЛЬ' , region: '',mainRegion:'', friendlyName:''},
    ];

  minLengthTerm = 3;
  selectedCity: City = null;

  isLoading = false;
  carInsuranceForm: UntypedFormGroup;

  descriptionCarType: string;

  selectedCarType: CarTypeModel = new CarTypeModel();
  selectedCarTypeProperty: CarTypePropertyModel = new CarTypePropertyModel();

  carTypes: CarTypeModel[];
  carTypePropertities: CarTypePropertyModel[];

  @ViewChildren('form')
  private forms: QueryList<NgForm>;
  private form: NgForm;

  isValidSelectedCity = false;

  constructor(
    public configurations: ConfigurationService,
    private carTypeService: CarTypeService,
    private catalogService: CatalogService,
    private formBuilder: UntypedFormBuilder,
    private router: Router) {
  }

  onSelected() {
    console.log(this.selectedCity);
    this.selectedCity = this.selectedCity;

    if(this.selectedCity != null)
        this.isValidSelectedCity = true;
    else
        this.isValidSelectedCity = false;
  }

  displayWith(value: City) {
    return value?.town;
  }

  clearSelection() {
    this.selectedCity = null;
    this.filteredCities = [];
  }

  ngOnInit() {

    this.carTypes = this.carTypeService.getCarTypes();
    if(this.carTypes.length > 0)
      this.selectedCarType = this.carTypes[0];

    this.carTypePropertities = this.carTypeService.getCarTypeProperties().filter((item) => item.CarTypeId == this.selectedCarType.Id);
    if(this.carTypePropertities.length > 0)
      this.selectedCarTypeProperty = this.carTypePropertities[0];

    this.descriptionCarType = this.selectedCarType.Description;

    this.carInsuranceForm = this.formBuilder.group({
      cartype: [{ value: '' }],
      cartypeproperty: [{value: ''}],
      searchCity:['', Validators.required],
    });


    this.cartypeControl.setValue(this.selectedCarType.Id);
    this.cartypepropertyControl.setValue(this.selectedCarTypeProperty.Id);

    if(this.filteredCities.length > 0)
      this.selectedCity = this.filteredCities[0];

    this.searchCityControl.setValue(this.selectedCity.town);

    this.searchCityControl.valueChanges
      .pipe(
        filter(res => {
          return res !== null && res.length >= this.minLengthTerm
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.filteredCities = [];
          this.isLoading = true;
        }),
        switchMap(value => this.catalogService.getCities(value)
          .pipe(
            finalize(() => {
              this.isLoading = false
            }),
          )
        )
      )
      .subscribe((data: any) => {
        this.filteredCities = data;

        console.log(this.filteredCities);
      });

  }



  ngAfterViewInit() {
  }

  ngOnDestroy() {
  }

  onSearch()
  {
    if(this.selectedCity == null){

      this.searchCityControl.markAsTouched();
      return;
    }

    const сityId = this.selectedCity?.id;
    const carTypeId = this.selectedCarType?.Id;
    const carTypePropertyId = this.selectedCarTypeProperty?.Id;

    this.router.navigate(
      ['/car-insurance/offers'],
      { queryParams: { id: сityId,
                        carType: carTypeId,
                        carTypeProperty: carTypePropertyId } }
    );
  }

  get searchCityControl() { return this.carInsuranceForm.get('searchCity'); }

  get cartypeControl() { return this.carInsuranceForm.get('cartype'); }

  get cartypepropertyControl() { return this.carInsuranceForm.get('cartypeproperty'); }

  onCarTypeChange(carTypeId)
  {
    this.selectedCarType = this.carTypes[carTypeId.value - 1];
    this.descriptionCarType = this.selectedCarType.Description;

    this.carTypePropertities = this.carTypeService.getCarTypeProperties().filter((item) => item.CarTypeId == carTypeId.value);

    if(this.carTypePropertities.length > 0)
      this.selectedCarTypeProperty = this.carTypePropertities[0];

    this.cartypepropertyControl.setValue(this.selectedCarTypeProperty.Id);
  }

  onCarTypePropertyChange(carTypePropertyId)
  {
    if(this.carTypePropertities.length > 0)
    {
      let props = this.carTypePropertities.filter((item) => item.Id == carTypePropertyId.value);
      this.selectedCarTypeProperty = props[0];

      this.cartypepropertyControl.setValue(this.selectedCarTypeProperty.Id);
    }
  }
}
