<app-page-header title="CarInsurances" svgIcon="cat"></app-page-header>

<div [@fadeInOut] class="page-content" >

  <div class="search-box">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'car-insurances.management.Search' | translate}}">
    </mat-form-field>
  </div>

  <div class="mat-elevation-z8">
    <mat-progress-bar *ngIf="loadingIndicator" mode="query"></mat-progress-bar>
    <mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="registrationNumber">
        <mat-header-cell fxFlex="30%" *matHeaderCellDef mat-sort-header> {{'car-insurances.management.RegistrationNumber' | translate}} </mat-header-cell>
        <mat-cell fxFlex="30%" *matCellDef="let row"> {{row.registrationNumber}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="make">
        <mat-header-cell fxFlex="34%" *matHeaderCellDef mat-sort-header> {{'car-insurances.management.Make' | translate}} </mat-header-cell>
        <mat-cell fxFlex="34%" *matCellDef="let row"> {{row.make}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="model">
        <mat-header-cell fxFlex="23%" *matHeaderCellDef mat-sort-header> {{'car-insurances.management.Model' | translate}} </mat-header-cell>
        <mat-cell fxFlex="23%" *matCellDef="let row"> {{row.model}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="insuranceValidFrom">
        <mat-header-cell fxFlex="23%" *matHeaderCellDef mat-sort-header> {{'car-insurances.management.ValidFrom' | translate}} </mat-header-cell>
        <mat-cell fxFlex="23%" *matCellDef="let row"> {{row.insuranceValidFrom | shortDate}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef fxFlex="40px">
        </mat-header-cell>
        <mat-cell *matCellDef="let insurance" fxFlex="40px" fxFlexOffset="10px">
          <button mat-icon-button matTooltip="{{'car-insurances.management.Download' | translate}}" (click)="downloadInsurance(insurance)">
            <mat-icon>download</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25]" ></mat-paginator>
  </div>
</div>
