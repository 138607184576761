
    <!-- ===============================================-->
    <!--    Main Content-->
    <!-- ===============================================-->
    <main class="main" id="top">


      <!-- ============================================-->
      <!-- <section> begin ============================-->
      <section class="text-white py-0" data-zanim-timeline="{}" data-zanim-trigger="scroll">

        <div class="bg-holder" style="background-image:url(assets/img/video-1.jpg);">
          <video class="bg-video" autoplay="autoplay" loop="loop" muted="muted" playsinline="playsinline">
            <source src="assets/videos/morning-routine/video.mp4" type="video/mp4" />
            <source src="assets/videos/morning-routine/video.webm" type="video/webm" />
          </video>
        </div>
        <!--/.bg-holder-->

        <div class="container">
          <div class="row min-vh-100 py-8 align-items-center" data-inertia='{"weight":1.5}'>
            <div class="col-sm-9 col-lg-8">
              <div class="overflow-hidden">
                <h1 class="fs-4 fs-md-5" data-zanim-xs='{"delay":0.5}'>Онлайн Експерт Консультант</h1>
              </div>
              <div class="overflow-hidden">
                <p class="text-primary mt-4 mb-5 fs-1 fs-md-2 lh-xs" data-zanim-xs='{"delay":0.6}'>Великий досвід допомоги клієнтам у пошуку комплексних рішень</p>
              </div>
              <div class="overflow-hidden">
                <div data-zanim-xs='{"delay":0.7}'>

                  <mat-card class="mat-elevation-z6">
                    <mat-card-header>
                      <mat-card-title class="font-sans-serif">Автоцивілка онлайн (Поліс ОСЦПВ)</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>

                      <hr class="short" data-zanim-xs='{"from":{"opacity":0,"width":0},"to":{"opacity":1,"width":"4.20873rem"},"duration":2.8}' data-zanim-trigger="scroll" />

                      <div fxLayoutAlign="center center" class="mat-typography found-email-notice">
                        Покриття ваших витрат при настанні ДТП з іншим учасником. Розширення страхового ліміту. Гнучкі тарифи та індивідуальний підхід. Дистанційне врегулювання. Підтримка 24/7.
                      </div>

                      <form #form="ngForm" [formGroup]="carInsuranceForm" (ngSubmit)="onSearch()" novalidate >

                        <mat-form-field appearance="fill">
                          <mat-label>Транспортний засіб</mat-label>
                          <mat-select formControlName="cartype" (selectionChange)="onCarTypeChange($event)">
                            <mat-option *ngFor="let carType of carTypes" [value]="carType.Id">
                              {{carType.Name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                          <mat-label>{{descriptionCarType}}</mat-label>
                          <mat-select formControlName="cartypeproperty" (selectionChange)="onCarTypePropertyChange($event)">
                            <mat-option *ngFor="let carTypePropertity of carTypePropertities" [value]="carTypePropertity.Id">
                              {{carTypePropertity.Name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                          <mat-label>Місто реєстрації</mat-label>
                          <input matInput placeholder="Найти ..." [(ngModel)]="selectedCity" [matAutocomplete]="auto"
                            formControlName="searchCity" placeholder="Мінімум 3 символи">

                          <button *ngIf="selectedCity" matSuffix mat-icon-button aria-label="Clear" (click)="clearSelection()">
                            <mat-icon>cancel</mat-icon>
                          </button>

                          <mat-error *ngIf="!isValidSelectedCity">Потрібно обрати місто

                          </mat-error>
                          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelected()"
                            [displayWith]="displayWith">
                            <mat-option *ngIf="isLoading" class="is-loading">Завантаження...</mat-option>
                            <ng-container *ngIf="!isLoading">
                              <mat-option *ngFor="let city of filteredCities" [value]="city">
                                <span><b>{{city.town}}</b> {{city.region}}</span>
                              </mat-option>
                            </ng-container>
                          </mat-autocomplete>
                        </mat-form-field>

                      </form>
                    </mat-card-content>
                    <mat-card-actions>
                      <button mat-raised-button color="primary" [disabled]="isLoading" (click)="onSearch()">
                        Пошук Автоцивілки
                      </button>
                      <div fxFlex></div>
                    </mat-card-actions>
                  </mat-card>

                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end of .container-->

      </section>
      <!-- <section> close ============================-->
      <!-- ============================================-->

    </main>
    <!-- ===============================================-->
    <!--    End of Main Content-->
    <!-- ===============================================-->



    <!--===============================================-->
    <!--    Footer-->
    <!--===============================================-->


    <!-- ============================================-->
    <!-- <section> begin ============================-->
    <section style="background-color: #3D4C6F">

      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 mt-4 mt-lg-0">
            <div class="row">
              <div class="col-6 col-lg-4 text-white ms-lg-auto">
                <ul class="list-unstyled">
                  <li class="mb-3"><a class="text-white" href="contact.html">Зв'яжіться з нами</a></li>
                  <li class="mb-3"><a class="text-white" href="#!">FAQ</a></li>
                  <li class="mb-3"><a class="text-white" href="/policy">Політика конфіденційності</a></li>
                  <li class="mb-3"><a class="text-white" href="/termsofuse">Умови використання</a></li>
                </ul>
              </div>
              <div class="col-6 col-sm-5 ms-sm-auto">
                <ul class="list-unstyled">
                  <li class="mb-3"><a class="text-decoration-none d-flex align-items-center" href="#!"> <span class="brand-icon me-3"><span class="fab fa-twitter"></span></span>
                      <h5 class="fs-0 text-white mb-0 d-inline-block">Twitter</h5>
                    </a></li>
                  <li class="mb-3"><a class="text-decoration-none d-flex align-items-center" href="#!"> <span class="brand-icon me-3"><span class="fab fa-facebook-f"></span></span>
                      <h5 class="fs-0 text-white mb-0 d-inline-block">Facebook</h5>
                    </a></li>
                    <li class="mb-3 mb-3-custom">
                      <a class="text-decoration-none d-flex align-items-center" href='https://play.google.com/store/apps/details?id=com.finance.sydi.mobile_sydi_finance'>
                        <img
                          class="android"
                          alt='Get it on Google Play'
                          src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png' />
                      </a>
                    </li>

                    <li class="mb-3">
                      <a class="text-decoration-none d-flex align-items-center" href="https://apps.apple.com/">
                        <img
                          class="apple" style="margin-left:0%;width:73%"
                          src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1276560000&h=7e7b68fad19738b5649a1bfb78ff46e9"
                          alt="Download on the App Store">
                      </a>
                    </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end of .container-->

    </section>
    <!-- <section> close ============================-->
    <!-- ============================================-->

