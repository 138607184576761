
    <footer class="footer bg-primary text-center py-4">
      <app-footer-logos></app-footer-logos>
      <div class="container">
        <div class="row align-items-center opacity-85 text-white">
          <div class="col-sm-3 text-sm-start"></div>
          <div class="col-sm-6 mt-3 mt-sm-0">
            <p class="lh-lg mb-0 fw-semi-bold">&copy; SYDI.Finance {{getYear()}} Версія: {{version}} {{configurationName}}</p>
          </div>
          <div class="col text-sm-end mt-3 mt-sm-0"></div>
        </div>
      </div>
    </footer>

