
import { Component, OnInit, AfterViewInit, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { debounceTime, tap, switchMap, finalize, distinctUntilChanged, filter } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { ThemeService } from 'ng2-charts';

import { fadeInOut } from '../../services/animations';
import { ConfigurationService } from '../../services/configuration.service';
import { NavigationService } from 'src/app/services/navigation.service';
import moment from 'moment';
import { TravelProductModel } from 'src/app/models/travel-insurance/travel-product.model';
import { TravelService } from 'src/app/services/travel.service';
import { TravelProductsRequest } from 'src/app/models/travel-insurance/travel-products-request';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { TravelFilterResponse } from 'src/app/models/travel-insurance/travel-filter-response.model';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { TravelProductsResponse } from 'src/app/models/travel-insurance/travel-products-response';

@Component({
  selector: 'app-travel-insurance-offers',
  templateUrl: './travel-insurance-offers.component.html',
  styleUrls: ['./travel-insurance-offers.component.scss'],
  animations: [fadeInOut],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ]
})

export class TravelInsuranceOffersComponent implements OnInit {

  selectedProductId: string;
  selectedTermId: string;
  selectedTerritoryId: string;
  selectedPurposeId: string;
  selectedMultipleTrip: boolean;
  selectedBirthdays: Array<Date> = [];

  selectedValidFrom: string;
  selectedValidTo: string;

  selectedTermName: string;
  selectedTerritoryName: string;
  selectedPurposeName: string;

  searchResult:string;
  searchResult1:string;
  searchResult2:string;
  searchResult3:string;

  loadingDataSource: boolean;

  public pageEvent: PageEvent;
  public pageSize = 6;
  public currentPage = 0;
  public totalSize = 0;

  public travelProducts: TravelProductModel[];
  public dataSource: TravelProductModel[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private navigationService: NavigationService,
    private travelService: TravelService,
    public _MatPaginatorIntl: MatPaginatorIntl  ) {
    }

  ngOnInit(): void {

    this._MatPaginatorIntl.firstPageLabel = 'До Першої Сторінки';
    this._MatPaginatorIntl.itemsPerPageLabel = 'Кількість На Сторінці';
    this._MatPaginatorIntl.lastPageLabel = 'До Останньої Сторінки';
    this._MatPaginatorIntl.nextPageLabel = 'До Наступної Сторінки';
    this._MatPaginatorIntl.previousPageLabel = 'До Попередньої Сторінки';

    this.route.queryParams.subscribe(params => {

      this.selectedProductId = params['productId'];
      this.selectedTermId = params['termId'];
      this.selectedTerritoryId = params['territoryId'];
      this.selectedPurposeId = params['purposeId'];
      this.selectedMultipleTrip = params['multipleTrip'] == "true" ? true : false;

      var decoded = params['birthdays'];
      var birthdayArray = JSON.parse(decoded);
      this.selectedBirthdays = birthdayArray.map(element => { return moment.utc(element, 'DD-MM-YYYY').toDate(); });

      this.selectedValidFrom = params['validFrom'];
      this.selectedValidTo = params['validTo'];

      var model = new TravelProductsRequest();
      model.isMultiple = this.selectedMultipleTrip;
      model.dateFrom = moment.utc(this.selectedValidFrom, 'DD-MM-YYYY').toDate();
      model.dateTo = moment.utc(this.selectedValidTo, 'DD-MM-YYYY').toDate();
      model.termId = this.selectedTermId;
      model.territoryId = this.selectedTerritoryId;
      model.purposeId = this.selectedPurposeId;
      model.clientBirthdays = this.selectedBirthdays;

      this.loadData(model);

      console.log(this.selectedBirthdays);
    });
  }

  loadData(model: TravelProductsRequest) {
    this.loadingDataSource = true;

    let loadingTravelFilter: Observable<TravelFilterResponse> = this.travelService.getFilter();
    let loadingTravelProducts: Observable<TravelProductsResponse> = this.travelService.getProducts(model);

    forkJoin(loadingTravelProducts, loadingTravelFilter).subscribe(results => {
      this.dataSource = results[0].products;

      let positionId = 1;

      this.dataSource.forEach((element) => {
        element.localId = positionId++;
      });

      this.totalSize = this.dataSource.length;
      this.currentPage = 0;
      this.iterator();

      let filter = results[1];

      this.selectedTermName = filter.terms.filter((item) => item.id == this.selectedTermId)[0]?.name;
      this.selectedTerritoryName = filter.territories.filter((item) => item.id == this.selectedTerritoryId)[0]?.name;
      this.selectedPurposeName = filter.purposes.filter((item) => item.id == this.selectedPurposeId)[0]?.name;

      if(this.selectedMultipleTrip == true) {
        this.searchResult = "Термін дії: з " + this.selectedValidFrom + ' період ' + this.selectedTermName;
      } else {
        this.searchResult = "Термін дії: з " + this.selectedValidFrom + ' по ' + this.selectedValidTo;
      }

      this.searchResult1 = "Країни: " + this.selectedTerritoryName;
      this.searchResult2 = 'Мета візиту: ' + this.selectedPurposeName;
      this.searchResult3 = "Кількість відвидувачів: " + this.selectedBirthdays.length;

      this.loadingDataSource = false;
    },
    error => {
      this.totalSize = 0;
      this.currentPage = 0;
      this.dataSource = [];
      this.iterator();
      console.log(error);
      this.loadingDataSource = false;
    });
  }

  private iterator() {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.dataSource.slice(start, end);
    this.travelProducts = part;
  }

  handlePageEvent(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.iterator();
  }

  buy(item: TravelProductModel)
  {
      const productId = this.selectedProductId;
      const termId = this.selectedTermId;
      const territoryId = this.selectedTerritoryId;
      const purposeId = this.selectedPurposeId;
      const multipleTrip = this.selectedMultipleTrip;

      const validFrom = this.selectedValidFrom;
      const validTo = this.selectedValidTo;

      const birthdays = this.selectedBirthdays.map(birthday => moment(birthday).format('DD.MM.YYYY'));
      const birthdaysJson = JSON.stringify(birthdays);

      const limit = item.limit;
      const programId = item.programId;

      let params;

      if(!this.selectedMultipleTrip) {
        params = {
          territoryId: territoryId,
          purposeId: purposeId,
          multipleTrip: multipleTrip,
          birthdays: birthdaysJson,
          validFrom :validFrom,
          validTo :validTo,
          limit :limit,
          programId :programId,
          productId: productId
        };
      } else {
        params = {
          termId: termId,
          territoryId: territoryId,
          purposeId: purposeId,
          multipleTrip: multipleTrip,
          birthdays: birthdaysJson,
          validFrom :validFrom,
          limit :limit,
          programId :programId,
          productId: productId
        };
      }

    this.router.navigate(['/travel-insurance/order'], { queryParams: params } );
  }
}
