
import { NgModule, Injectable } from '@angular/core';
import { Routes, RouterModule, DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { AuthCallbackComponent } from './components/auth-callback/auth-callback.component';
import { RegisterComponent } from './components/register/register.component';
import { ConfirmEmailComponent } from './components/confirm-email/confirm-email.component';
import { ConfirmDeleteAccountComponent } from './components/confirm-delete-account/confirm-delete-account.component';
import { RecoverPasswordComponent } from './components/recover-password/recover-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';

import { CarInsuranceComponent } from './components/car-insurance/car-insurance.component';
import { CarInsuranceOffersComponent } from './components/car-insurance-offers/car-insurance-offers.component';

import { TravelInsuranceComponent } from './components/travel-insurance/travel-insurance.component';
import { TravelInsuranceOffersComponent } from './components/travel-insurance-offers/travel-insurance-offers.component';

import { HomeComponent } from './components/home/home.component';
import { CustomersComponent } from './components/customers/customers.component';
import { ProductsComponent } from './components/products/products.component';
import { OrdersComponent } from './components/orders/orders.component';
import { SettingsComponent } from './settings/settings.component';
import { AboutComponent } from './components/about/about.component';
import { PolicyComponent } from './components/policy/policy.component';
import { TermsOfUseComponent } from './components/terms-of-use/termsofuse.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth-guard.service';
import { Utilities } from './services/utilities';
import { CarInsuranceOrderComponent } from './components/car-insurance-order/car-insurance-order.component';
import { CarInsurancePaidComponent} from './components/car-insurance-paid/car-insurance-paid.component';
import { TravelInsuranceOrderComponent } from './components/travel-insurance-offer/travel-insurance-order.component';
import { TravelInsurancePaidComponent } from './components/travel-insurance-paid/travel-insurance-paid.component';
import { SalamandraComponent } from './components/insurance-companies/salamandra/salamandra.component';


@Injectable()
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    const possibleSeparators = /[?;#]/;
    const indexOfSeparator = url.search(possibleSeparators);
    let processedUrl: string;

    if (indexOfSeparator > -1) {
      const separator = url.charAt(indexOfSeparator);
      const urlParts = Utilities.splitInTwo(url, separator);
      urlParts.firstPart = urlParts.firstPart.toLowerCase();

      processedUrl = urlParts.firstPart + separator + urlParts.secondPart;
    } else {
      processedUrl = url.toLowerCase();
    }

    return super.parse(processedUrl);
  }
}


const routes: Routes = [
  { path: '', component: HomeComponent, data: { title: '' } },
  { path: 'car-insurance',
     children: [
      { path: '', component: CarInsuranceComponent, data: { title: 'Автоцивілка' } },
      { path: 'offers', component: CarInsuranceOffersComponent, data: { title: 'Автоцивілка | Пропозиції' } },
      { path: 'order', component: CarInsuranceOrderComponent, canActivate: [AuthGuard], data: { title: 'Автоцивілка | Заказ' } },
      { path: 'paid', component: CarInsurancePaidComponent, canActivate: [AuthGuard], data: { title: 'Автоцивілка | Заказ оплачений' } },

    ]
  },
  { path: 'travel-insurance',
     children: [
      { path: '', component: TravelInsuranceComponent, data: { title: 'Страхування подорожей' } },
      { path: 'offers', component: TravelInsuranceOffersComponent, data: { title: 'Страхування подорожей | Пропозиції' } },
      { path: 'order', component: TravelInsuranceOrderComponent, canActivate: [AuthGuard], data: { title: 'Страхування подорожей | Заказ' } },
      { path: 'paid', component: TravelInsurancePaidComponent, canActivate: [AuthGuard], data: { title: 'Страхування подорожей | Заказ оплачений' } },

    ]
  },
  { path: 'salamandra', component: SalamandraComponent, data: { title: 'Salamandra' } },
  { path: 'login', component: LoginComponent, data: { title: 'Вхід' } },
  { path: 'google-login', component: AuthCallbackComponent, data: { title: 'Google Login' } },
  { path: 'facebook-login', component: AuthCallbackComponent, data: { title: 'Facebook Login' } },
  { path: 'twitter-login', component: AuthCallbackComponent, data: { title: 'Twitter Login' } },
  { path: 'apple-login', component: AuthCallbackComponent, data: { title: 'Apple Login' } },
  { path: 'register', component: RegisterComponent, data: { title: 'Register' } },
  { path: 'confirm-email', component: ConfirmEmailComponent, data: { title: 'Confirm Email' } },
  { path: 'confirm-delete-account', component: ConfirmDeleteAccountComponent, data: { title: 'Підтвердження видалення акаунту' } },
  { path: 'recover-password', component: RecoverPasswordComponent, data: { title: 'Recover Password' } },
  { path: 'reset-password', component: ResetPasswordComponent, data: { title: 'Reset Password' } },

  { path: 'customers', component: CustomersComponent, canActivate: [AuthGuard], data: { title: 'Customers' } },
  { path: 'products', component: ProductsComponent, canActivate: [AuthGuard], data: { title: 'Products' } },
  { path: 'orders', component: OrdersComponent, canActivate: [AuthGuard], data: { title: 'Orders' } },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard], data: { title: 'Settings' } },
  { path: 'about', component: AboutComponent, data: { title: 'Про Нас' } },
  { path: 'policy', component: PolicyComponent, data: { title: 'Політика Конфіденційності' } },
  { path: 'termsofuse', component: TermsOfUseComponent, data: { title: 'Умови використання' } },
  { path: '**', component: NotFoundComponent, data: { title: 'Page Not Found' } }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    AuthService,
    AuthGuard,
    { provide: UrlSerializer, useClass: LowerCaseUrlSerializer }]
})
export class AppRoutingModule { }
