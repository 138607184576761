
import { Component, NgModule, Input } from '@angular/core';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})

export class PageHeaderComponent {
  @Input()
  title: string;

  @Input()
  icon: string;

  @Input()
  svgIcon: string;
}
