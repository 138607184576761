import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { RegistrationCertificateEndpoint } from './registration-certificate-endpoint.service';
import { AuthService } from './auth.service';
import { RegistrationCertificate } from '../models/registration-certificate.model';

@Injectable()
export class RegistrationCertificateService {
  
  constructor(
    private authService: AuthService,
    private registrationCertificateEndpoint: RegistrationCertificateEndpoint) {

  }

  getRegistrationCertificates(page?: number, pageSize?: number) {

    const userId = this.currentUser.id;

    return this.registrationCertificateEndpoint.getRegistrationCertificatesEndpoint<RegistrationCertificate[]>(userId, page, pageSize);
  }

  deleteRegistrationCertificate(passportId: string) {
    
    const userId = this.currentUser.id;
    return this.registrationCertificateEndpoint.deleteRegistrationCertificateEndpoint<RegistrationCertificate>(passportId, userId);
  }

  newRegistrationCertificate(passport: RegistrationCertificate) {

    passport.createdBy = this.currentUser.id;
    passport.updatedBy = this.currentUser.id;
    return this.registrationCertificateEndpoint.createRegistrationCertificateEndpoint<RegistrationCertificate>(passport);
  }

  updateRegistrationCertificate(passport: RegistrationCertificate) {
    
    passport.updatedBy = this.currentUser.id;
    return this.registrationCertificateEndpoint.updateRegistrationCertificateEndpoint<RegistrationCertificate>(passport);
  }

  get currentUser() {
    return this.authService.currentUser;
  }
}