export class CarTypePropertyModel {

    constructor(id?: number, cartypeid?: number, name?: string) {
        this.Id = id;
        this.CarTypeId = cartypeid;
        this.Name = name;
    }

    public Id: number;
    public CarTypeId: number;
    public Name: string;
}