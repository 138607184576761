<main class="main" id="top">

  <!-- ============================================-->
  <!-- <section> begin ============================-->
    <section class="bg-primary" data-zanim-timeline="{}" data-zanim-trigger="scroll">
      <div class="container">
        <div class="row align-items-center text-white">
          <div class="col-lg-12">
            <div class="border border-2 border-warning p-4 py-lg-5 text-left rounded-3" data-zanim-timeline="{}" data-zanim-trigger="scroll">
              <div class="overflow-hidden">
                <h4 class="text-white" data-zanim-xs='{"delay":0}'>Туристичне Страхування</h4>
                <hr class="short" data-zanim-xs='{"from":{"opacity":0,"width":0},"to":{"opacity":1,"width":"4.20873rem"},"duration":2.8}'
                  data-zanim-trigger="scroll" style="background-color:white"/>
              </div>
              <div class="overflow-hidden">
                <div class="row align-items-center">
                  <div class="col-lg-6 mt-4 mt-lg-0">
                    <h5 class="px-lg-1 text-100 mb-0" data-zanim-xs='{"delay":0.2}'>{{searchResult}}</h5>
                  </div>
                  <div class="col-lg-6 mt-4 mt-lg-0">
                    <h5 class="px-lg-1 text-100 mb-0" data-zanim-xs='{"delay":0.2}'>{{searchResult1}}</h5>
                  </div>
                </div>

                <div class="row align-items-center">
                  <div class="col-lg-6 mt-4 mt-lg-0">
                    <h5 class="px-lg-1 text-100 mb-0" data-zanim-xs='{"delay":0.2}'>{{searchResult2}}</h5>
                  </div>
                  <div class="col-lg-6 mt-4 mt-lg-0">
                    <h5 class="px-lg-1 text-100 mb-0" data-zanim-xs='{"delay":0.2}'>{{searchResult3}}</h5>
                  </div>
                </div>

              </div>
              <div class="overflow-hidden pt-3">
                <h6 class="px-lg-1 text-100 mb-0" data-zanim-xs='{"delay":0.3}'>Туристична страховка онлайн. Онлайн оформлення страховки. Туристичні страховки по всьому світу. Різні цілі мандрування та терміни дії. Підтримка 24/7.</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end of .container-->
    </section>

  <!-- <section> close ============================-->
  <!-- ============================================-->

  <section class="bg-100" data-zanim-timeline="{}" data-zanim-trigger="scroll">


    <div class="bg-holder" style="background-image:url(assets/img/video-1.jpg);">
      <video class="bg-video" autoplay="autoplay" loop="loop" muted="muted" playsinline="playsinline">
        <source src="assets/videos/morning-routine/video.mp4" type="video/mp4" />
        <source src="assets/videos/morning-routine/video.webm" type="video/webm" />
      </video>
    </div>

    <div class="container">

      <mat-spinner *ngIf="loadingDataSource"></mat-spinner>

      <div class="row g-4" data-inertia='{"weight":1.5}' >

        <div class="col-md-4 col-lg-4">
          <div class="card" *ngIf="!loadingDataSource">
            <img class="card-img-top" src="assets/img/salamandra-travel.gif" alt="Salamandra" />

            <div class="card-body" data-zanim-timeline="{}" data-zanim-trigger="scroll">
              <div class="overflow-hidden">
                  <h5 data-zanim-xs='{"delay":0}'>{{insuranceCompanyName}}</h5>
              </div>
              <div class="overflow-hidden">
                <h5 class="mb-1 text-success" data-zanim-xs='{"delay":0.2}'>{{limit | number:'1.0-0'}} $</h5>
                <p class="fs--1 text-500" data-zanim-xs='{"delay":0.1}'>Ліміт</p>
              </div>
              <div class="overflow-hidden">
                <h6 class="mt-3 fs-2 text-success" data-zanim-xs='{"delay":0.2}'><mark>{{sum | number:'1.2-2'}} грн.</mark></h6>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-8 col-lg-8">

            <div class="card" *ngIf="!loadingDataSource">
              <div class="card-body" data-zanim-timeline="{}" data-zanim-trigger="scroll">

                <div>
                  <mat-icon class="mat-icon-md" aria-hidden="true" svgIcon="travel"></mat-icon>
                  <h4 class="header">{{'travel-insurance-paid.management.Travel' | translate}}</h4>
                </div>

                <div>
                  <h6 class="mt-3 fs-2 text-success main-result" data-zanim-xs='{"delay":0.2}'><mark>{{travelResult}}</mark></h6>
                </div>

                <div>
                  <h6 class="mt-3 fs-2 text-success" data-zanim-xs='{"delay":0.2}'>
                    <mat-icon class="mat-icon-md text-success" aria-hidden="true" svgIcon="">check_circle</mat-icon>
                    {{'travel-insurance-paid.management.InsuranceReleased' | translate}}</h6>
                </div>

                <div>
                  <p>{{'car-insurance-paid.management.InsuranceReleased1' | translate}} <strong>{{email}}</strong></p>
                </div>

                <div>
                  <p>{{'travel-insurance-paid.management.InsuranceReleased2' | translate}}</p>
                </div>

                <div>
                   <a mat-raised-button color="primary" href="./settings#travel_insurances">{{'travel-insurance-paid.commands.Redirect' | translate}}</a>
                </div>
              </div>
            </div>

            <!-- https://github.com/angular/material2/issues/8798 -->
            <div>&nbsp;</div>

        </div>

      </div>
    </div>
  </section>

</main>
<!-- ===============================================-->
<!--    End of Main Content-->
<!-- ===============================================-->

<!-- ============================================-->
<!-- <section> begin ============================-->
<section style="background-color: #3D4C6F">

  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 mt-4 mt-lg-0">
        <div class="row">
          <div class="col-6 col-lg-4 text-white ms-lg-auto">
            <ul class="list-unstyled">
              <li class="mb-3"><a class="text-white" href="contact.html">Зв'яжіться з нами</a></li>
              <li class="mb-3"><a class="text-white" href="#!">FAQ</a></li>
              <li class="mb-3"><a class="text-white" href="/policy">Політика конфіденційності</a></li>
              <li class="mb-3"><a class="text-white" href="/termsofuse">Умови використання</a></li>
            </ul>
          </div>
          <div class="col-6 col-sm-5 ms-sm-auto">
            <ul class="list-unstyled">
              <li class="mb-3"><a class="text-decoration-none d-flex align-items-center" href="#!"> <span class="brand-icon me-3"><span class="fab fa-twitter"></span></span>
                  <h5 class="fs-0 text-white mb-0 d-inline-block">Twitter</h5>
                </a></li>
              <li class="mb-3"><a class="text-decoration-none d-flex align-items-center" href="#!"> <span class="brand-icon me-3"><span class="fab fa-facebook-f"></span></span>
                  <h5 class="fs-0 text-white mb-0 d-inline-block">Facebook</h5>
                </a></li>
                <li class="mb-3 mb-3-custom">
                  <a class="text-decoration-none d-flex align-items-center" href='https://play.google.com/store/apps/details?id=com.finance.sydi.mobile_sydi_finance'>
                    <img
                      class="android"
                      alt='Get it on Google Play'
                      src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png' />
                  </a>
                </li>

                <li class="mb-3">
                  <a class="text-decoration-none d-flex align-items-center" href="https://apps.apple.com/">
                    <img
                      class="apple" style="margin-left:0%;width:73%"
                      src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1276560000&h=7e7b68fad19738b5649a1bfb78ff46e9"
                      alt="Download on the App Store">
                  </a>
                </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end of .container-->

</section>
<!-- <section> close ============================-->
<!-- ============================================-->
