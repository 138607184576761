<button mat-icon-button [mat-menu-trigger-for]="themeMenu" [matTooltip]="tooltip"
        tabindex="-1">
    <mat-icon>format_color_fill</mat-icon>
</button>

<mat-menu class="app-theme-picker-menu" #themeMenu="matMenu" x-position="before">
    <div mat-menu-item *ngFor="let theme of themeManager.themes" (click)="setTheme(theme)">
        <div class="app-theme-picker-swatch">
            <div [style.background]="theme.primary" [style.border-color]="theme.accent">
                <mat-icon class="app-theme-chosen-icon" *ngIf="currentTheme === theme">check_circle</mat-icon>
            </div>
        </div>
        <div style="display: inline-block">
            {{theme.name}}
        </div>
    </div>
</mat-menu>
