
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { from, throwError } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';

import { LocalStoreManager } from './local-store-manager.service';
import { ConfigurationService } from './configuration.service';
import { Utilities } from './utilities';
import { DBkeys } from './db-keys';
import { LoginResponse } from '../models/login-response.model';
import { FacebookUser } from '../models/facebook-user';

@Injectable()
export class OidcHelperService {

  private get baseUrl() { return this.configurations.baseUrl; }

  private clientId = 'sydiapp_spa';
  private clientSecret = 'c6hqz^arpfEn=vW7qf?mH6';
  private scope = 'sydiapp_api';
  private scopeForUser = 'openid email phone profile offline_access roles sydiapp_api';

  private clientIdExt = 'sydiapp_ext';
  private clientSecretExt = '56H8z^arPEn=vW7qf?mH1';

  constructor(
    private http: HttpClient,
    private oauthService: OAuthService,
    private configurations: ConfigurationService,
    private localStorage: LocalStoreManager) {

  }

  loginWithPassword(userName: string, password: string) {
    const header = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'Access-Control-Allow-Origin': '*'});
    const params = new HttpParams()
      .append('username', userName)
      .append('password', password)
      .append('client_id', this.clientId)
      .append('client_secret', this.clientSecret)
      .append('grant_type', 'password')
      .append('scope', this.scopeForUser);

    this.oauthService.issuer = this.baseUrl;

    return from(this.oauthService.loadDiscoveryDocument())
      .pipe(mergeMap(() => {
        return this.http.post<LoginResponse>(this.oauthService.tokenEndpoint, params, { headers: header });
      }));
  }

  loginWithClientCredentials() {
    const header = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'Access-Control-Allow-Origin': '*' });
    const params = new HttpParams()
      .append('client_secret', this.clientSecret)
      .append('client_id', this.clientId)
      .append('grant_type', 'client_credentials')
      .append('scope', this.scope);

    this.oauthService.issuer = this.baseUrl;

    return from(this.oauthService.loadDiscoveryDocument())
      .pipe(mergeMap(() => {
        var res = this.http.post<LoginResponse>(this.oauthService.tokenEndpoint, params, { headers: header });
        return res;
      }));
  }

  refreshLogin() {
    const header = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'Access-Control-Allow-Origin': '*' });
    const params = new HttpParams()
      .append('refresh_token', this.refreshToken)
      .append('client_id', this.clientId)
      .append('grant_type', 'refresh_token');

    this.oauthService.issuer = this.baseUrl;

    return from(this.oauthService.loadDiscoveryDocument())
      .pipe(mergeMap(() => {
        return this.http.post<LoginResponse>(this.oauthService.tokenEndpoint, params, { headers: header });
      }));
  }

  /*
  'client_id': clientId,
  'client_secret': clientSecret,
  'grant_type': 'external',
  'scopes': scope,
  'email': email,
  'provider': provider, //(Google, Facebook)
  'external_token': accessToken
  */

  loginWithExternalToken(accessToken: string, provider: string, email: string) {
    const header = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'Access-Control-Allow-Origin': '*' });
    let params = new HttpParams()
      .append('external_token', accessToken)
      .append('provider', provider)
      .append('client_id', this.clientIdExt)
      .append('client_secret', this.clientSecretExt)
      .append('grant_type', 'external')
      .append('scopes', this.scope);

    if (email) {
      params = params.append('email', email);
    }

    //this.oauthService.strictDiscoveryDocumentValidation = false;
    this.oauthService.issuer = this.baseUrl;

    var res = from(this.oauthService.loadDiscoveryDocument())
      .pipe(mergeMap(() => {
        return this.http.post<LoginResponse>(this.oauthService.tokenEndpoint, params, { headers: header });
      }));

    return res;
  }

  initLoginWithGoogle() {
    this.oauthService.configure({
      issuer: 'https://accounts.google.com',
      redirectUri: this.configurations.currentBaseUrl + '/google-login',
      clientId: this.configurations.googleClientId,
      strictDiscoveryDocumentValidation: false,
      scope: 'openid profile email phone',
      sessionChecksEnabled: false
    });

    this.oauthService.loadDiscoveryDocument().then(() => {
      this.oauthService.initImplicitFlow();
    });
  }

  initLoginWithApple() {
    this.oauthService.configure({
      issuer: 'https://appleid.apple.com',
      redirectUri: this.configurations.appleRedirectUrl,
      //redirectUri: 'https://sydi.finance/apple-login',
      clientId: this.configurations.appleClientId,
      strictDiscoveryDocumentValidation: false,
      responseType: 'code id_token',
      scope: 'openid email name',
      sessionChecksEnabled: false
    });

    this.oauthService.customQueryParams = {
      'response_mode': 'form_post'
      //'response_mode': 'fragment'
    };

    this.oauthService.loadDiscoveryDocument().then(() => {
      this.oauthService.initImplicitFlow();
    });
  }

  initLoginWithFacebook() {
    this.oauthService.configure({
      loginUrl: 'https://www.facebook.com/v16.0/dialog/oauth',
      redirectUri: this.configurations.currentBaseUrl + '/facebook-login',
      clientId: this.configurations.facebookClientId,
      scope: 'public_profile email user_hometown user_friends user_birthday',
      oidc: false,
      sessionChecksEnabled: false
    });

    this.oauthService.initImplicitFlow();
  }

  getFacebookEmail(accessToken: string) {
    //var url = 'https://graph.facebook.com/v16.0/me?fields=id,name,email';

    //var headers = new HttpHeaders({
    //  Authorization: 'Bearer ' + accessToken,
    //  'Content-Type': 'application/json',
    //  Accept: 'application/json, text/plain, */*'
    //});

    //return this.http.get<FacebookUser>(url, { headers }).pipe<FacebookUser>();

    var res = fetch(`https://graph.facebook.com/v16.0/me?fields=id,name,hometown,first_name,last_name,email&access_token=${accessToken}`)
              .then(response => response.json())
              .catch(function(error) {
                console.error(error);
              });
    return res;
  }

  initLoginWithTwitter() {
    this.getTwitterRequestToken()
      .subscribe(response => {
        const tokens = Utilities.getQueryParamsFromString(response);
        if (tokens.oauth_callback_confirmed) {
          this.localStorage.savePermanentData(tokens.oauth_token, DBkeys.TWITTER_OAUTH_TOKEN);
          this.localStorage.savePermanentData(tokens.oauth_token_secret, DBkeys.TWITTER_OAUTH_TOKEN_SECRET);

          this.authenticateTwitterRequestToken(tokens.oauth_token);
        } else {
          throw new Error('Twitter OAuth Callback Not Confirmed');
        }
      });
  }

  private getTwitterRequestToken() {
    const requestTokenUrl = this.baseUrl + '/oauth/twitter/request_token';
    const requestObject = { oauth_callback: this.configurations.currentBaseUrl + '/twitter-login' };
    return this.http.post(requestTokenUrl, requestObject, { responseType: 'text' });
  }

  private authenticateTwitterRequestToken(oauthToken: string) {
    window.location.href = 'https://api.twitter.com/oauth/authenticate?oauth_token=' + oauthToken;
  }

  getTwitterAccessToken(oauthToken: string, oauthVerifier: string) {
    const savedOauthToken = this.localStorage.getDataObject<string>(DBkeys.TWITTER_OAUTH_TOKEN);
    const savedOauthTokenSecret = this.localStorage.getDataObject<string>(DBkeys.TWITTER_OAUTH_TOKEN_SECRET);
    this.localStorage.deleteData(DBkeys.TWITTER_OAUTH_TOKEN);
    this.localStorage.deleteData(DBkeys.TWITTER_OAUTH_TOKEN_SECRET);

    if (oauthToken !== savedOauthToken) {
      return throwError('Invalid or Expired Twitter OAuthToken');
    }

    const requestTokenUrl = this.baseUrl + '/oauth/twitter/access_token';
    const requestObject = {
      oauth_token: oauthToken,
      oauth_token_secret: savedOauthTokenSecret,
      oauth_verifier: oauthVerifier,
    };

    return this.http.post(requestTokenUrl, requestObject, { responseType: 'text' });
  }


  get accessToken(): string {
    return this.localStorage.getData(DBkeys.ACCESS_TOKEN);
  }

  get accessTokenExpiryDate(): Date {
    return this.localStorage.getDataObject<Date>(DBkeys.TOKEN_EXPIRES_IN, true);
  }

  get refreshToken(): string {
    return this.localStorage.getData(DBkeys.REFRESH_TOKEN);
  }

  get isSessionExpired(): boolean {
    if (this.accessTokenExpiryDate == null) {
      return true;
    }

    return this.accessTokenExpiryDate.valueOf() <= new Date().valueOf();
  }

  get clientCredentialsToken(): string {
    return this.localStorage.getData(DBkeys.CLIENTS_CREDENTIALS_TOKEN);
  }

}
