import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { PassportEndpoint } from './passport-endpoint.service';
import { AuthService } from './auth.service';
import { Passport } from '../models/passport.model';

@Injectable()
export class PassportService {
  
  constructor(
    private authService: AuthService,
    private passportEndpoint: PassportEndpoint) {

  }

  getPassports(page?: number, pageSize?: number) {

    const userId = this.currentUser.id;

    return this.passportEndpoint.getPassportsEndpoint<Passport[]>(userId, page, pageSize);
  }

  deletePassport(passportId: string) {
    
    const userId = this.currentUser.id;
    return this.passportEndpoint.deletePassportEndpoint<Passport>(passportId, userId);
  }

  newPassport(passport: Passport) {

    passport.createdBy = this.currentUser.id;
    passport.updatedBy = this.currentUser.id;
    return this.passportEndpoint.createPassportEndpoint<Passport>(passport);
  }

  updatePassport(passport: Passport) {
    
    passport.updatedBy = this.currentUser.id;
    return this.passportEndpoint.updatePassportEndpoint<Passport>(passport);
  }

  get currentUser() {
    return this.authService.currentUser;
  }
}