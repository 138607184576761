import { CarType } from "./CarType";
import { CarTypeProperty } from "./CarTypeProperty";

export class CarInsuranceProductsRequest {

    constructor(cityId?: string,
                carType?: CarType,
                carTypeProperty?: CarTypeProperty
                ) {
        this.cityId = cityId;
        this.carType = carType;
        this.carTypeProperty = carTypeProperty;
    }

    public cityId: string;
    public carType: CarType;
    public carTypeProperty: CarTypeProperty;
}