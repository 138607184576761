
<div class="container">
  <div class="row align-items-center opacity-85 text-white">
    <div class="col-sm-3 text-sm-start"></div>
    <div class="col-sm-6 mt-3 mt-sm-0">

      <object class="size2" data="./assets/logo-gor-white.png" type="image/png"></object>
      <object class="size1" data="./assets/visa.svg" type="image/svg+xml"></object>
      <object class="size1" data="./assets/mastercard.svg" type="image/svg+xml"></object>
      <object class="size1" data="./assets/pci-dss.svg" type="image/svg+xml"></object>
      <object class="size" data="./assets/apple-pay.svg" type="image/svg+xml"></object>
      <object class="size" data="./assets/google-pay.svg" type="image/svg+xml"></object>
    </div>
    <div class="col text-sm-end mt-3 mt-sm-0"></div>
  </div>
</div>
