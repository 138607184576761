<app-page-header title="RegistrationCertificates" icon="directions_car"></app-page-header>

<form [hidden]="!isEditMode" #registrationCertificateNgForm="ngForm" [formGroup]="registrationCertificateForm" novalidate (ngSubmit)="save()"
      fxLayout="column"
      [fxLayoutGap]="isEditMode ? '1em' : '0'" autocomplete="off">

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">

      <mat-form-field [floatLabel]="floatLabels" fxFlex>
        <mat-label>{{'registration-certificates.editor.CarType' | translate}}</mat-label>
        <mat-select formControlName="carTypeId" (selectionChange)="onCarTypeChange($event)">
          <mat-option *ngFor="let carType of carTypes" [value]="carType.Id">
            {{carType.Name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field [floatLabel]="floatLabels" fxFlex>
        <mat-label>{{descriptionCarType}}</mat-label>
        <mat-select formControlName="carTypePropertyId" (selectionChange)="onCarTypePropertyChange($event)">
          <mat-option *ngFor="let carTypePropertity of carTypePropertities" [value]="carTypePropertity.Id">
            {{carTypePropertity.Name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">

    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'registration-certificates.editor.Make' | translate}}</mat-label>
      <input matInput formControlName="make" [(ngModel)]="selectedCarBrand" [matAutocomplete]="auto1" />
      <button matSuffix mat-icon-button aria-label="Clear" (click)="clearCarBrandSelection()">
        <mat-icon>cancel</mat-icon>
      </button>
      <mat-error *ngIf="make.hasError('required')">
        {{'registration-certificates.editor.MakeRequired' | translate}}
      </mat-error>
      <mat-error *ngIf="!make.hasError('required') && make.hasError('pattern')">
          {{'registration-certificates.editor.MakeRegex' | translate}}
      </mat-error>

      <mat-autocomplete #auto1="matAutocomplete" (optionSelected)="onSelectedCarBrand()"

         >
        <mat-option *ngIf="isLoadingMarks" class="is-loading">Завантаження...</mat-option>
        <ng-container *ngIf="!isLoadingMarks">
          <mat-option *ngFor="let carBrand of filteredCarBrands" [value]="carBrand">
            <span><b>{{carBrand.name}}</b></span>
          </mat-option>
        </ng-container>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'registration-certificates.editor.Model' | translate}}</mat-label>
      <input matInput formControlName="model" [(ngModel)]="selectedCarModel" [matAutocomplete]="auto2" />
      <button matSuffix mat-icon-button aria-label="Clear" (click)="clearCarModelSelection()">
        <mat-icon>cancel</mat-icon>
      </button>
      <mat-error *ngIf="model.hasError('required')">
        {{'registration-certificates.editor.ModelRequired' | translate}}
      </mat-error>
      <mat-error *ngIf="!model.hasError('required') && model.hasError('pattern')">
          {{'registration-certificates.editor.ModelRegex' | translate}}
      </mat-error>

      <mat-autocomplete #auto2="matAutocomplete" (optionSelected)="onSelectedCarModel()"
         >
        <mat-option *ngIf="isLoadingMarks" class="is-loading">Завантаження...</mat-option>
        <ng-container *ngIf="!isLoadingMarks">
          <mat-option *ngFor="let carModel of filteredCarModels" [value]="carModel">
            <span><b>{{carModel.name}}</b></span>
          </mat-option>
        </ng-container>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">

    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'registration-certificates.editor.RegistrationNumber' | translate}}</mat-label>
      <input matInput formControlName="registrationNumber" />
      <mat-error *ngIf="registrationNumber.hasError('required')">
        {{'registration-certificates.editor.RegistrationNumberRequired' | translate}}
      </mat-error>
      <mat-error *ngIf="!registrationNumber.hasError('required') && registrationNumber.hasError('pattern')">
          {{'registration-certificates.editor.RegistrationNumberRegex' | translate}}
      </mat-error>
    </mat-form-field>

    <mat-form-field [floatLabel]="floatLabels" fxFlex appYearFormat>
      <mat-label>{{'registration-certificates.editor.ManufactureYear' | translate}}</mat-label>

      <input matInput formControlName="manufactureYear" [matDatepicker]="manufactureYearPicker" />
      <mat-hint>рік</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="manufactureYearPicker"></mat-datepicker-toggle>
      <mat-datepicker #manufactureYearPicker
                      startView="multi-year"
                      (yearSelected)="chosenYearHandler($event, manufactureYearPicker)"
                      panelClass="year-picker"
                ></mat-datepicker>

      <mat-error *ngIf="manufactureYear.hasError('required')">
        {{'registration-certificates.editor.ManufactureYearRequired' | translate}}
      </mat-error>
    </mat-form-field>

  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">

    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'registration-certificates.editor.VehicleIdentificationNumber' | translate}}</mat-label>
      <input matInput formControlName="vehicleIdentificationNumber" />
      <mat-error *ngIf="vehicleIdentificationNumber.hasError('required')">
        {{'registration-certificates.editor.VehicleIdentificationNumberRequired' | translate}}
      </mat-error>
      <mat-error *ngIf="!vehicleIdentificationNumber.hasError('required') && vehicleIdentificationNumber.hasError('pattern')">
          {{'registration-certificates.editor.VehicleIdentificationNumberRegex' | translate}}
      </mat-error>
    </mat-form-field>

    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'registration-certificates.editor.RegistrationPlace' | translate}}</mat-label>
      <input matInput [(ngModel)]="selectedCity" formControlName="searchCity" [matAutocomplete]="auto" placeholder="Мінімум 3 символи">
      <button matSuffix mat-icon-button aria-label="Clear" (click)="clearCitySelection()">
        <mat-icon>cancel</mat-icon>
      </button>

      <mat-error *ngIf="!isValidSelectedCity">{{'registration-certificates.editor.RegistrationPlaceRequired' | translate}}</mat-error>

      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectedCity()" >
        <mat-option *ngIf="isLoadingCity" class="is-loading">{{'registration-certificates.editor.RegistrationPlaceLoading' | translate}}</mat-option>
        <ng-container *ngIf="!isLoadingCity">
          <mat-option *ngFor="let city of filteredCities" [value]="city">
            <span><b>{{city.town}}</b> {{city.region}}</span>
          </mat-option>
        </ng-container>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <mat-action-row>
    <button mat-raised-button class="spinner-button" [disabled]="isSaving" (click)="save()" color="primary">
      <mat-spinner *ngIf="isSaving" [diameter]="20" color="primary"></mat-spinner>
      <span>{{'settings.commands.Save' | translate}}</span></button>
    <button mat-raised-button  [disabled]="isSaving" (click)="cancel()" color="warn">{{'settings.commands.Cancel' | translate}}</button>
  </mat-action-row>

  <!-- https://github.com/angular/material2/issues/8798 -->
  <div>&nbsp;</div>
</form>


<div [hidden]="isEditMode" [@fadeInOut] class="page-content" >

  <div class="search-box">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'registration-certificates.management.Search' | translate}}">
    </mat-form-field>
  </div>

  <div class="mat-elevation-z8">
    <mat-progress-bar *ngIf="loadingIndicator" mode="query"></mat-progress-bar>
    <mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="registrationNumber">
        <mat-header-cell fxFlex="33%" *matHeaderCellDef mat-sort-header> {{'registration-certificates.management.RegistrationNumber' | translate}} </mat-header-cell>
        <mat-cell fxFlex="33%" *matCellDef="let row"> {{row.registrationNumber}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="make">
        <mat-header-cell fxFlex="34%" *matHeaderCellDef mat-sort-header> {{'registration-certificates.management.Make' | translate}} </mat-header-cell>
        <mat-cell fxFlex="34%" *matCellDef="let row"> {{row.make}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="model">
        <mat-header-cell fxFlex="33%" *matHeaderCellDef mat-sort-header> {{'registration-certificates.management.Model' | translate}} </mat-header-cell>
        <mat-cell fxFlex="33%" *matCellDef="let row"> {{row.model}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef fxFlex="120px">
          <button mat-button color="primary" matTooltip="{{'registration-certificates.management.NewRegistrationCertificate' | translate}}"
                (click)="editRegistrationCertificate()">
            <mat-icon class="mat-button-icon">note_add</mat-icon>{{'registration-certificates.management.NewRegistrationCertificate' | translate}}
          </button>
        </mat-header-cell>
        <mat-cell *matCellDef="let registrationCertificate" fxFlex="90px" fxFlexOffset="10px">
          <button mat-icon-button matTooltip="{{'registration-certificates.management.Edit' | translate}}" (click)="editRegistrationCertificate(registrationCertificate)">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button matTooltip="{{'registration-certificates.management.Delete' | translate}}" (click)="confirmDelete(registrationCertificate)">
            <mat-icon color="warn">delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25]" ></mat-paginator>
  </div>
</div>
