
export class TravelProductsRequest {

  constructor(territoryId?: string,
    purposeId?: string,
    dateFrom?: Date,
    dateTo?: Date,
    termId?: string,
    clientBirthdays?: Date[],
    isMultiple? : boolean
              ) {
      this.territoryId = territoryId;
      this.purposeId = purposeId;
      this.dateFrom = dateFrom;
      this.dateTo = dateTo;
      this.termId = termId;
      this.clientBirthdays = clientBirthdays;
      this.isMultiple = isMultiple;
  }

  public territoryId: string;
  public purposeId: string;
  public dateFrom: Date;
  public dateTo: Date;
  public termId?: string;
  public clientBirthdays: Date[];
  public isMultiple : boolean;
}
