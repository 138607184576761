
import { Component, OnInit, AfterViewInit, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';

import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { debounceTime, tap, switchMap, finalize, distinctUntilChanged, filter } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { ThemeService } from 'ng2-charts';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';

import { fadeInOut } from '../../services/animations';
import { ConfigurationService } from '../../services/configuration.service';
import { CatalogService } from 'src/app/services/catalog.service';
import { City } from 'src/app/models/city.model';

import { CarTypeModel } from 'src/app/models/cartype.model';
import { CarTypePropertyModel } from 'src/app/models/cartypeproperty.model';
import { CarTypeService } from 'src/app/services/cartype.service';
import { CarInsuranceService } from 'src/app/services/car-insurance.service';
import { CarInsuranceProductsRequest } from 'src/app/models/car-insurance-products-request.model';
import { CarInsuranceProductsResponse } from 'src/app/models/car-insurance-products-response.model';
import { CarInsuranceProduct } from 'src/app/models/car-insurance-product.model';
import { CarInsuranceProperty } from 'src/app/models/car-insurance-property.model';
import { CarInsurancePropertyType } from 'src/app/models/CarInsurancePropertyType';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-car-insurance-offers',
  templateUrl: './car-insurance-offers.component.html',
  styleUrls: ['./car-insurance-offers.component.scss'],
  animations: [fadeInOut]
})

export class CarInsuranceOffersComponent implements OnInit {

   kyivCodeTE = '8000000000';
   private cityId: string;
   private carTypeId: number;
   private carTypePropertyId: number;

   public selectedCity: City;
   public selectedCarType: CarTypeModel;
   public selectedCarTypeProperty : CarTypePropertyModel;
   public searchResult: string;
   public loadingIndicator: boolean;
   public loadingDataSource: boolean;
   public carInsuranceProducts: CarInsuranceProduct[];
   public dataSource: CarInsuranceProduct[];

   public pageEvent: PageEvent;
   public pageSize = 6;
   public currentPage = 0;
   public totalSize = 0;

 constructor (
    public configurations: ConfigurationService,
    private route: ActivatedRoute,
    private catalogService: CatalogService,
    private carService: CarTypeService,
    private carInsuranceService: CarInsuranceService,
    public _MatPaginatorIntl: MatPaginatorIntl,
    private navigationService: NavigationService,
    private router: Router ) {

    }

  ngOnInit(): void {

    this._MatPaginatorIntl.firstPageLabel = 'До Першої Сторінки';
    this._MatPaginatorIntl.itemsPerPageLabel = 'Кількість На Сторінці';
    this._MatPaginatorIntl.lastPageLabel = 'До Останньої Сторінки';
    this._MatPaginatorIntl.nextPageLabel = 'До Наступної Сторінки';
    this._MatPaginatorIntl.previousPageLabel = 'До Попередньої Сторінки';

    this.route.queryParams.subscribe(params => {

      this.cityId = params['id'] || this.kyivCodeTE;

      this.carTypeId = params['carType'] || 1;
      this.carTypePropertyId = params['carTypeProperty'] || 2;

      this.loadData();
    });

  }

  loadData()
  {
    this.loadingIndicator = true;
    this.loadingDataSource = true;

    this.catalogService.getCity(this.cityId).subscribe(
      city => this.onSelectedCityDataLoadSuccessful(city),
      error => this.onSelectedCityDataLoadFailed(error)
    );

    let filter = new CarInsuranceProductsRequest(this.cityId, this.carTypeId, this.carTypePropertyId);

    this.carInsuranceService.getProducts(filter).subscribe(
      response => this.onProductsLoadSuccessful(response),
      error => this.onProductsLoadFailed(error)
    )

  }

  private onProductsLoadSuccessful(response: CarInsuranceProductsResponse){
    let products = response.products;

    let positionId = 1;
    products.forEach(function (value) {
      value.localId = positionId++;
    });

    this.dataSource = products
              //.filter(p => p.franchisePrice == 1000 && p.price == 2520.7215)
              ;

    this.totalSize = this.dataSource.length;
    this.currentPage = 0;
    this.iterator();
    this.loadingDataSource = false;

  }

  private onProductsLoadFailed(error: any) {
    this.totalSize = 0;
    this.currentPage = 0;
    this.dataSource = [];
    this.iterator();
    this.loadingDataSource = false;
  }

  handlePageEvent(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.iterator();
  }

  private iterator() {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.dataSource.slice(start, end);
    this.carInsuranceProducts = part;
  }

  private onSelectedCityDataLoadSuccessful(city: City) {
    this.selectedCity = city;

    let carTypes = this.carService.getCarTypes().filter(p => p.Id == this.carTypeId);
    if(carTypes.length > 0)
      this.selectedCarType = carTypes[0];

    let carTypePropertities = this.carService.getCarTypeProperties().filter(p => p.Id == this.carTypePropertyId);
    if(carTypePropertities.length > 0)
      this.selectedCarTypeProperty = carTypePropertities[0];

    let cityName = city?.town;
    if(city?.mainRegion != null)
      cityName = cityName + " ( " + city?.mainRegion +" ) ";

    this.searchResult =  cityName + " " + this.selectedCarType?.Name + " ( " + this.selectedCarTypeProperty?.Name + " )";

    this.loadingIndicator = false;
  }

  private onSelectedCityDataLoadFailed(error: any) {
    this.loadingIndicator = false;
  }

  filterBase(item: CarInsuranceProperty){

    var carInsurancePropertyType : CarInsurancePropertyType = (<any>CarInsurancePropertyType)[item.carInsurancePropertyType];

    let result = carInsurancePropertyType == CarInsurancePropertyType.Base;
    return result;
  }

  filterAdditional(item: CarInsuranceProperty){

    var carInsurancePropertyType : CarInsurancePropertyType = (<any>CarInsurancePropertyType)[item.carInsurancePropertyType];

    let result = carInsurancePropertyType == CarInsurancePropertyType.Additional;
    return result;
  }

  markGreen(item: CarInsuranceProduct)
  {
    let result = item.directSettlement === true || item.trafficAccidentMedicine === true;
    return result;
  }

  onAuxiliaryProductChange(auxiliaryProduct, localId)
  {
    let selectedProduct = this.dataSource
                            .filter(p => p.localId == localId).shift();
    selectedProduct.selectedAuxiliaryProduct = auxiliaryProduct?.value;

    var discount = 0;
    if(selectedProduct != null && selectedProduct.discountPercent != null) {
      discount = selectedProduct.price * selectedProduct.discountPercent / 100;
    }

    selectedProduct.sum = selectedProduct?.selectedAuxiliaryProduct
                              ? selectedProduct.price + selectedProduct?.selectedAuxiliaryProduct?.sum - discount
                              : selectedProduct.price - discount;

  }

  public auxiliaryProductComparisonFunction = function( option, value ) : boolean {
    return option.id === value.id;
  }

  buy(item: CarInsuranceProduct)
  {
    this.router.navigate(
      ['/car-insurance/order'],
      { queryParams: { id: this.cityId,
                       carType: this.carTypeId,
                       carTypeProperty: this.carTypePropertyId,
                       insuranceCompany: item.insuranceCompany,
                       franchiseId: item.franchiseId,
                       directSettlement: item.directSettlement,
                       trafficAccidentMedicine: item.trafficAccidentMedicine,
                       auxiliaryProductId: item.selectedAuxiliaryProduct?.id  } }
    );
  }
}
