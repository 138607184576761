import { CarModelView } from "./carmodelview.model";

export class CarBrandView {

    constructor(name?: string, carModels?: CarModelView[]) {
        this.name = name;
        this.carModels = carModels;
    }

    public name: string;
    public carModels: CarModelView[];
}