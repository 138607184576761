<form #form="ngForm" [formGroup]="userProfileForm" novalidate (ngSubmit)="save()" fxLayout="column" [fxLayoutGap]="isEditMode ? '1em' : '0'" autocomplete="off">

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'users.editor.Email' | translate}}</mat-label>
      <input matInput formControlName="email" [class.auto-width]="emailConfirmed === false" [readonly]="!isEditMode" />
      <mat-error *ngIf="email.hasError('required')">
        {{'users.editor.EmailRequired' | translate}}
      </mat-error>
      <mat-error *ngIf="!email.hasError('required') && email.hasError('email')">
        {{'users.editor.InvalidEmail' | translate}}
      </mat-error>
    </mat-form-field>

    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'users.editor.PhoneNumber' | translate}}</mat-label>
      <input matInput formControlName="phoneNumber"
            mask="99 999 999 99 99"
            placeholder="38 050 000 00 00"
            [readonly]="!isEditMode" />



    </mat-form-field>
  </div>

  <div *ngIf="emailConfirmed === false" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <button  type="button" (click)="sendVerificationEmail()" [disabled]="isSendingEmail"
        mat-raised-button color="primary">{{'users.editor.SendVerificationEmail' | translate}}
    </button>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">

    <mat-form-field *ngIf="userHasPassword && (isEditMode && !isNewUser && ((userName.value != user.userName || confirmedEmailChanged)) || (isChangePassword && isEditingSelf))"
                    [floatLabel]="floatLabels" formGroupName="password" fxFlex
                    fxFlex.gt-sm="0 1 calc(50% - 0.5em)">
      <mat-label>{{'users.editor.CurrentPassword' | translate}}</mat-label>
      <input matInput type="password" formControlName="currentPassword" />
      <mat-error *ngIf="currentPassword.hasError('required')">
        {{'users.editor.CurrentPasswordRequired' | translate}}
      </mat-error>
    </mat-form-field>
  </div>

  <div *ngIf="isEditMode">
    <button mat-raised-button (click)="changePassword()" *ngIf="!(isChangePassword || isNewUser)" color="primary">{{'users.editor.ChangePassword' | translate}}</button>

    <div *ngIf="isChangePassword || isNewUser" formGroupName="password" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">

      <mat-form-field [floatLabel]="floatLabels" fxFlex>
        <mat-label>{{'users.editor.NewPassword' | translate}}</mat-label>
        <input matInput type="password" formControlName="newPassword" autocomplete="off" />
        <mat-error *ngIf="newPassword.hasError('required')">
          {{'users.editor.NewPasswordRequired' | translate}}
        </mat-error>
        <mat-error *ngIf="!newPassword.hasError('required') && newPassword.hasError('pattern')">
          {{'users.editor.NewPasswordRules' | translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field [floatLabel]="floatLabels" fxFlex>
        <mat-label>{{'users.editor.ConfirmPassword' | translate}}</mat-label>
        <input matInput type="password" formControlName="confirmPassword" />
        <mat-error *ngIf="confirmPassword.hasError('required')">
          {{'users.editor.ConfirmationPasswordRequired' | translate}}
        </mat-error>
        <mat-error *ngIf="!confirmPassword.hasError('required') && confirmPassword.hasError('notEqual')">
          {{'users.editor.PasswordMismatch' | translate}}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <!-- https://github.com/angular/material2/issues/8798 -->
  <div>&nbsp;</div>
</form>
