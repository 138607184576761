import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { EndpointBase } from './endpoint-base.service';
import { ConfigurationService } from './configuration.service';
import { CommonService } from './common.service';

@Injectable()
export class CarInsuranceEndpoint extends EndpointBase{

    get carInsuranceGetProductsUrl() { return this.configurations.baseUrl + '/api/v1/CarInsurance/Products'; }

    get carInsuranceGetProductUrl() { return this.configurations.baseUrl + '/api/v1/CarInsurance/Product'; }

    get productCreateUrl() { return this.configurations.baseUrl + '/api/v1/InsuranceCarRecord/create'; }

    get getCarInsuranceUrl() { return this.configurations.baseUrl + '/api/v1/InsuranceCarRecord/getById'; }

    get getCarInsurancesUrl() { return this.configurations.baseUrl + '/api/v1/InsuranceCarRecord/list'; }

    get releaseCarInsuranceUrl() { return this.configurations.baseUrl + '/api/v1/InsuranceCarRecord/release'; }

    get downloadCarInsurancePdfUrl() { return this.configurations.baseUrl + '/api/v1/InsuranceCarRecord/downloadPdf'; }

    constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService, commonService: CommonService) {
        super(http, authService, commonService);
      }

    getProducts<T>(filterObject: any): Observable<T> {

        const endpointUrl = this.carInsuranceGetProductsUrl;

        let filter = JSON.stringify(filterObject);

        return this.http.post<T>(endpointUrl, filter, this.requestHeadersClientToken).pipe<T>(
          catchError(error => {
              return this.handleErrorFromApi(error, () => this.getProducts(filterObject));
          }));
     }

     getProduct<T>(filterObject: any): Observable<T> {

      const endpointUrl = this.carInsuranceGetProductUrl;

      let filter = JSON.stringify(filterObject);

      return this.http.post<T>(endpointUrl, filter, this.requestHeadersClientToken).pipe<T>(
        catchError(error => {
          return this.handleErrorFromApi(error, () => this.getProducts(filterObject));
        }));
    }

    createProductEndpoint<T>(userObject: any): Observable<T> {
      const endpointUrl = this.productCreateUrl;

      return this.http.post<T>(endpointUrl, JSON.stringify(userObject), this.requestHeaders).pipe<T>(
        catchError(error => {
          return this.handleError(error, () => this.createProductEndpoint(userObject));
        }));
    }

    getInsuranceEndpoint<T>(id: string): Observable<T> {

      const endpointUrl = `${this.getCarInsuranceUrl}/${id}`;

      return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
        catchError(error => {
          return this.handleError(error, () => this.getInsuranceEndpoint(id));
        }));
    }

    getInsurancesEndpoint<T>(): Observable<T> {

      const endpointUrl = `${this.getCarInsurancesUrl}`;

      return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
        catchError(error => {
          return this.handleError(error, () => this.getInsurancesEndpoint());
        }));
    }

    releaseCarInsuranceEndpoint<T>(userObject: any): Observable<T> {
      const endpointUrl = this.releaseCarInsuranceUrl;
      return this.http.put<T>(endpointUrl, JSON.stringify(userObject), this.requestHeaders).pipe<T>(
        catchError(error => {
          return this.handleError(error, () => this.releaseCarInsuranceEndpoint(userObject));
        }));
    }

    downloadPdfEndpoint(id?: string): Observable<ArrayBuffer> {
      const endpointUrl = `${this.downloadCarInsurancePdfUrl}/${id}`;

      return this.http.get(endpointUrl, { headers: this.requestOnlyHeaders, responseType: 'arraybuffer' }).pipe(
        catchError(error => {
          return this.handleError(error, () => this.downloadPdfEndpoint(id));
        }));
    }
}
