
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { SettingsComponent } from './settings.component';
import { UserPreferencesComponent } from './user-preferences/user-preferences.component';
import { DriverLicenceListComponent } from './driver-licences/driver-licence-list.component';
import { PassportListComponent } from './passports/passport-list.component';
import { RegistrationCertificateListComponent } from './registration-certificates/registration-certificate-list.component';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { NgxMaskModule } from 'ngx-mask';
import { CarInsuranceListComponent } from './car-insurances/car-insurance-list.component';
import { TravelInsuranceListComponent } from './travel-insurances/travel-insurance-list.component';


@NgModule({
  imports: [
    SharedModule,
    NgxMaskModule.forChild()
  ],
  exports: [
    SettingsComponent
  ],
  declarations: [
    SettingsComponent,
    UserPreferencesComponent,
    DriverLicenceListComponent,
    PassportListComponent,
    RegistrationCertificateListComponent,
    DeleteAccountComponent,
    CarInsuranceListComponent,
    TravelInsuranceListComponent
  ]
})

export class SettingsModule { }
