
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AlertService, MessageSeverity } from '../../services/alert.service';
import { ConfigurationService } from '../../services/configuration.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { AccountService } from '../../services/account.service';
import { Utilities } from '../../services/utilities';

export interface PageInfo {
  title: string;
  icon: string;
  path: string;
  isDefault: boolean;
}

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss']
})

export class DeleteAccountComponent {

  constructor(
      private alertService: AlertService,
      private translationService: AppTranslationService,
      private accountService: AccountService,
      private snackBar: MatSnackBar,
      public configurations: ConfigurationService
  ) { }

  save() {
    this.snackBar.open('Видалити акаунт?', 'Відправлення підтвердження на e-mail', { duration: 5000 })
      .onAction().subscribe(() => {
        this.alertService.startLoadingMessage('', 'Відправлення підтвердження на e-mail');

        this.accountService.sendDeleteAccountToEmail()
          .subscribe(response => {
            this.alertService.stopLoadingMessage();
            this.alertService.showMessage('Видалення акаунту', 'Підтвердження на e-mail відправлено', MessageSeverity.success);

          },
            error => {
              this.alertService.stopLoadingMessage();
              this.alertService.showStickyMessage('Помилка видалення акаунту', `Помилка при відправленні підтвердження на e-mail.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
                MessageSeverity.error, error);
            });
      });
  }
}
