
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';

import { Router } from '@angular/router';

import { fadeInOut } from '../../../services/animations';
import { ConfigurationService } from '../../../services/configuration.service';


@Component({
  selector: 'salamandra',
  templateUrl: './salamandra.component.html',
  styleUrls: ['./salamandra.component.scss'],
  animations: [fadeInOut]
})

export class SalamandraComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    public configurations: ConfigurationService,
    private router: Router) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
  }

}
