import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'shorter'})
export class ShortStringPipe implements PipeTransform {
  transform(value: string): string {
   
    if(value.length > 40)
    {
        let newValue = value.substring(0, 34).concat('...');
        return `${newValue}`;
    }
    else{
        return `${value}`;
    }
  }
}