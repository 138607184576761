
export class RegistrationCertificate {

    constructor(id?: string,
            applicationUserId?: string,
            registrationNumber?: string,
            manufactureYear?: Date,
            dateOfFirstRegistration?: Date,
            dateOfRegistration?: Date,
            surnameOrCompany?: string,
            givenName?: string,
            address?: string,
            mainRegion?: string,
            region?: string,
            town?: string,
            ownership?: string,
            validity?: string,
            issuer?: string,
            make?: string,
            model?: string,
            commercialDescription?: string,
            vehicleIdentificationNumber?: string,
            maximumMass?: number,
            massVehicleInService?: number,
            vehicleCategory?: string,
            engineVolume?: string,
            fuelType?: string,
            color?: string,
            numberOfSeats?: number,
            numberOfStandingPlaces?: number,
            specialNotes?: string,

            carTypeId?: number,
            carTypePropertyId?: number,

            cityKoatuuId?: string,
            cityName?: string,

            createdBy?: string,
            createdDate?: Date,
            updatedBy?: string,
            updatedDate?: Date
    ) {
        this.id = id;
        this.applicationUserId = applicationUserId;

        this.registrationNumber = registrationNumber;
        this.manufactureYear = manufactureYear;
        this.dateOfFirstRegistration = dateOfFirstRegistration;
        this.dateOfRegistration = dateOfRegistration;
        this.surnameOrCompany = surnameOrCompany;
        this.givenName = givenName;
        this.address = address;
        this.mainRegion = mainRegion;
        this.region = region;
        this.town = town;
        this.ownership = ownership;
        this.validity = validity;
        this.issuer = issuer;
        this.make = make;
        this.model = model;
        this.commercialDescription = commercialDescription;
        this.vehicleIdentificationNumber = vehicleIdentificationNumber;
        this.maximumMass = maximumMass;
        this.massVehicleInService = massVehicleInService;
        this.vehicleCategory = vehicleCategory;
        this.engineVolume = engineVolume;
        this.fuelType = fuelType;
        this.color = color;
        this.numberOfSeats = numberOfSeats;
        this.numberOfStandingPlaces = numberOfStandingPlaces;
        this.specialNotes = specialNotes;
        this.carTypeId = carTypeId;
        this.carTypePropertyId = carTypePropertyId;

        this.createdBy = createdBy;
        this.createdDate = createdDate;
        this.updatedBy = updatedBy;
        this.updatedDate = updatedDate;
        this.cityKoatuuId = cityKoatuuId;
        this.cityName = cityName;
    }

    public id: string;
    public applicationUserId: string;

    public registrationNumber: string;
    public manufactureYear: Date;
    public dateOfFirstRegistration: Date;
    public dateOfRegistration: Date;
    public surnameOrCompany: string;
    public givenName: string;
    public address: string;
    public mainRegion: string;
    public region: string;
    public town: string;
    public ownership: string;
    public validity: string;
    public issuer: string;
    public make: string;
    public model: string;
    public commercialDescription: string;
    public vehicleIdentificationNumber: string;
    public maximumMass: number;
    public massVehicleInService: number;
    public vehicleCategory: string;
    public engineVolume: string;
    public fuelType: string;
    public color: string;
    public numberOfSeats: number;
    public numberOfStandingPlaces: number;
    public specialNotes: string;
    public carTypeId?: number;
    public carTypePropertyId?: number;
    public cityKoatuuId: string;
    public cityName: string;

    public createdBy: string;
    public createdDate: Date;
    public updatedBy: string;
    public updatedDate: Date;
}
