import { Directive, Input } from "@angular/core";
import { MatSelect } from "@angular/material/select";

@Directive({
  selector: 'mat-select [auto]'
})

export class SelectWidthAutoDirective {

  @Input('auto') set _(value:string)
  {
    this.matselect._overlayPanelClass=value
  }

  constructor(private matselect:MatSelect)
  {}
}
