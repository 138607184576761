
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateModule } from '@ngx-translate/core';
import { QuickAppProMaterialModule } from '../modules/material.module';

import { PageHeaderComponent } from './page-header/page-header.component';
import { UserEditorComponent } from '../admin/user-editor/user-editor.component';
import { AppDialogComponent } from './app-dialog/app-dialog.component';

import { GroupByPipe } from '../pipes/group-by.pipe';
import { CallbackPipe } from '../pipes/callback.pipe';
import { ShortStringPipe } from '../pipes/short-string.pipe';
import { NgxMaskModule } from 'ngx-mask';
import { MaskDateDirective } from './directives/mask-date.directive';
import { AuthService } from '../services/auth.service';
import { CommonModule } from '@angular/common';
import { MaskYearDirective } from './directives/mask-year.directive';
import { YearFormatDirective } from './directives/year-format';
import { SelectWidthAutoDirective } from './directives/auto-width.directive';
import { ShortDatePipe } from '../pipes/short-date.pipe';
import { FooterLogosComponent } from './footer/footer-logos.component';
import { FooterComponent } from './footer/footer.component';


@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    QuickAppProMaterialModule,
    TranslateModule,
    NgxMaskModule.forRoot()
  ],
  exports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule, ReactiveFormsModule,
    BrowserModule, BrowserAnimationsModule,
    QuickAppProMaterialModule,
    TranslateModule,
    PageHeaderComponent,
    GroupByPipe,
    CallbackPipe,
    UserEditorComponent,
    AppDialogComponent,
    ShortStringPipe,
    MaskDateDirective,
    MaskYearDirective,
    YearFormatDirective,
    SelectWidthAutoDirective,
    ShortDatePipe,
    FooterLogosComponent,
    FooterComponent
  ],
  declarations: [
    PageHeaderComponent,
    GroupByPipe,
    CallbackPipe,
    UserEditorComponent,
    AppDialogComponent,
    ShortStringPipe,
    MaskDateDirective,
    MaskYearDirective,
    YearFormatDirective,
    SelectWidthAutoDirective,
    ShortDatePipe,
    FooterLogosComponent,
    FooterComponent
  ],
  providers: [
    AuthService
  ]
})
export class SharedModule {

}
