import { ChangeDetectorRef, Component, Directive, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { fadeInOut } from "src/app/services/animations";
import { ConfigurationService } from "src/app/services/configuration.service";
import { AccountService } from "src/app/services/account.service";
import { User } from "src/app/models/user.model";
import { Observable, concatMap, debounceTime, distinctUntilChanged, filter, finalize, forkJoin, of, switchMap, tap } from "rxjs";
import { NgForm, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from "@angular/material-moment-adapter";
import { AlertService, MessageSeverity } from "src/app/services/alert.service";

import { ReleaseRequestModel } from "src/app/models/car-insurance/release-request.model";
import { TravelService } from "src/app/services/travel.service";
import { TravelInsuranceBookingRecordModel } from "src/app/models/travel-insurance/travel-insurance-booking-record.model";
import moment from "moment";

@Component({
  selector: 'app-travel-insurance-paid',
  templateUrl: './travel-insurance-paid.component.html',
  styleUrls: ['./travel-insurance-paid.component.scss'],
  animations: [fadeInOut],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ]
})

export class TravelInsurancePaidComponent implements OnInit {

  @ViewChild('orderNgForm', { static: true }) orderNgForm: NgForm;

  @Input() isEditMode = true;

  public orderId: string;
  public loadingDataSource: boolean;
  public user: User;

  sum: number;
  limit: number;

  searchResult:string;
  searchResult1:string;
  searchResult2:string;
  searchResult3:string;

  travelResult:string;
  insuranceCompanyName:string;
  email:string;

  constructor (
    public configurations: ConfigurationService,
    private route: ActivatedRoute,
    private travelService: TravelService,
    private router: Router,
    private accountService: AccountService,
    private ref: ChangeDetectorRef,
    private alertService: AlertService ) {

    }

    ngOnInit(): void {

      this.route.queryParams.subscribe(params => {

        this.orderId = params['order'];

        this.loadData(this.orderId);

      });

    }

    ngAfterViewInit() {

    }

    loadData(id: string)
    {
      this.loadingDataSource = true;

      this.alertService.showMessage('Туристична страховка', `Випуск туристичної страховки`, MessageSeverity.success);

      let bookedOrder: Observable<TravelInsuranceBookingRecordModel> = this.travelService.getInsurance(id);

      let releaseRequestModel = new ReleaseRequestModel();
      releaseRequestModel.id = id;

      let releaseOrder: Observable<TravelInsuranceBookingRecordModel> = this.travelService.releaseTravelInsurance(releaseRequestModel);

      bookedOrder.pipe(
        concatMap(bookedOrderResult => releaseOrder),
        concatMap(releaseOrderResult => of(releaseOrderResult))
      ).subscribe(result => {

        if(result.isMultiple == true) {
          this.searchResult = "Термін дії: з " + moment(result.insuranceValidFrom).format('DD-MM-YYYY') + ' період ' + result.termName;
        } else {
          this.searchResult = "Термін дії: з " + moment(result.insuranceValidFrom).format('DD-MM-YYYY')
                                  + ' по ' + moment(result.insuranceValidTo).format('DD-MM-YYYY');
        }

        this.searchResult1 = "Країни: " + result.territoryName;
        this.searchResult2 = 'Мета візиту: ' + result.purposeName;
        this.searchResult3 = "Кількість відвидувачів: " + result.travelDocuments.length;

        this.sum = result.sum;
        this.limit = result.limit;
        this.insuranceCompanyName = result.insuranceCompanyName;
        this.travelResult = 'Страховка з метою візиту \'' + result.purposeName + '\' для \'' + result.territoryName + '\' випущено';
        this.email = result.email;

        this.loadingDataSource = false;
      },
      error => {
        console.log(error);
        this.loadingDataSource = false;
      });
    }

    ngAfterContentChecked() {
      this.ref.detectChanges();
    }

    get floatLabels(): string {
      return this.isEditMode ? 'auto' : 'always';
    }
}
