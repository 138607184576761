
    <!-- ===============================================-->
    <!--    Main Content-->
    <!-- ===============================================-->
    <main class="main" id="top">


      <!-- ============================================-->
      <!-- <section> begin ============================-->
      <section class="bg-primary" data-zanim-timeline="{}" data-zanim-trigger="scroll">
        <div class="container">
          <div class="row align-items-center text-white">
            <div class="col-lg-12">
              <div class="border border-2 border-warning p-4 py-lg-5 text-left rounded-3" data-zanim-timeline="{}" data-zanim-trigger="scroll">
                <div class="overflow-hidden">
                  <h4 class="text-white" data-zanim-xs='{"delay":0}'>Автоцивілка онлайн (Поліс ОСЦПВ)</h4>
                  <hr class="short" data-zanim-xs='{"from":{"opacity":0,"width":0},"to":{"opacity":1,"width":"4.20873rem"},"duration":2.8}'
                    data-zanim-trigger="scroll" style="background-color:white"/>
                </div>
                <div class="overflow-hidden">
                  <h5 class="px-lg-1 text-100 mb-0" data-zanim-xs='{"delay":0.2}'>{{searchResult}}</h5>
                </div>
		            <div class="overflow-hidden">
                  <h6 class="px-lg-1 text-100 mb-0" data-zanim-xs='{"delay":0.3}'>Покриття ваших витрат при настанні ДТП з іншим учасником. Розширення страхового ліміту. Гнучкі тарифи та індивідуальний підхід. Дистанційне врегулювання. Підтримка 24/7.</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end of .container-->
      </section>

      <mat-progress-bar *ngIf="loadingIndicator" mode="query"></mat-progress-bar>
      <!-- <section> close ============================-->
      <!-- ============================================-->

      <!-- ============================================-->
      <!-- <section> begin ============================-->
      <section class="bg-100" data-zanim-timeline="{}" data-zanim-trigger="scroll">


        <div class="bg-holder" style="background-image:url(assets/img/video-1.jpg);">
          <video class="bg-video" autoplay="autoplay" loop="loop" muted="muted" playsinline="playsinline">
            <source src="assets/videos/morning-routine/video.mp4" type="video/mp4" />
            <source src="assets/videos/morning-routine/video.webm" type="video/webm" />
          </video>
        </div>

        <div class="container">
          <div class="row g-4" data-inertia='{"weight":1.5}' >

            <mat-spinner *ngIf="loadingDataSource"></mat-spinner>

            <div class="col-md-6 col-lg-4" *ngFor="let carInsuranceProduct of carInsuranceProducts">
              <div class="card">
                <img class="card-img-top" src="assets/img/salamandra.gif" alt="Salamandra" />

                <div class="card-body" data-zanim-timeline="{}" data-zanim-trigger="scroll">
                  <div class="overflow-hidden">
                      <h5 data-zanim-xs='{"delay":0}'>{{carInsuranceProduct?.insuranceCompanyName}}</h5>
                  </div>
                  <div class="overflow-hidden">
                    <h6 class="mb-1 text-success" data-zanim-xs='{"delay":0.2}'>{{carInsuranceProduct.franchisePrice | number:'1.2-2'}} грн.</h6>
                    <p class="fs--1 text-500" data-zanim-xs='{"delay":0.1}'>Франшиза</p>

                    <mat-form-field appearance="fill" class="align-items-end">
                          <mat-label>Додаткове покриття</mat-label>
                          <mat-select (selectionChange)="onAuxiliaryProductChange($event, carInsuranceProduct.localId)"
                              [(ngModel)]="carInsuranceProduct.selectedAuxiliaryProduct"
                              >
                            <mat-option></mat-option>
                            <mat-option *ngFor="let auxiliaryProduct of carInsuranceProduct.auxiliaryProducts" [value]="auxiliaryProduct">
                              <strong class="text-info">{{auxiliaryProduct.price | number:'1.2-2'}} грн.</strong>
                              <mark class="fs-0 text-primary"> за {{auxiliaryProduct.sum | number:'1.2-2'}} грн.</mark>
                            </mat-option>
                          </mat-select>
                    </mat-form-field>

                   <p class="fs--1 text-500" *ngIf="carInsuranceProduct.selectedAuxiliaryProduct != null" data-zanim-xs='{"delay":0.1}'>
                    Додаткове покриття з {{carInsuranceProduct.selectedAuxiliaryProduct.price | number:'1.2-2'}} грн.
                    за {{carInsuranceProduct.selectedAuxiliaryProduct.sum | number:'1.2-2'}} грн.</p>

                  </div>
                  <div class="overflow-hidden" *ngIf="carInsuranceProduct.discountPercent != null">
                    <h4 class="mt-3 fs-0 text-success headline-red" data-zanim-xs='{"delay":0.2}'><mark class="main-container-red">{{carInsuranceProduct.price | number:'1.2-2'}} грн.</mark></h4>
                    <h4 class="mt-3 fs-2 text-success headline-green" data-zanim-xs='{"delay":0.2}'><mark class="main-container-green">{{carInsuranceProduct.sum | number:'1.2-2'}} грн.</mark></h4>
                  </div>

                  <div class="overflow-hidden" *ngIf="carInsuranceProduct.discountPercent == null">
                    <h4 class="mt-3 fs-2 text-success headline-green" data-zanim-xs='{"delay":0.2}'><mark class="main-container-green">{{carInsuranceProduct.sum | number:'1.2-2'}} грн.</mark></h4>
                  </div>

                  <div class="overflow-hidden">

                    <ul class="no-style">
                      <li>Базові опції
                        <ul class="style-check" *ngFor="let carInsuranceProperty of carInsuranceProduct.carInsuranceProperties">
                          <li *ngIf="filterBase(carInsuranceProperty)">{{carInsuranceProperty.name}}</li>
                        </ul>
                      </li>
                      <li>Додаткові опції
                        <ul class="style-check" *ngFor="let carInsuranceProperty of carInsuranceProduct.carInsuranceProperties">
                          <li *ngIf="filterAdditional(carInsuranceProperty)"
                            [ngClass]="{'text-success': markGreen(carInsuranceProduct) }" >{{carInsuranceProperty.name}}</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div class="overflow-hidden">
                     <div class="d-inline-block" data-zanim-xs='{"delay":0.3}'>
                       <button class="ms-2 align-items-center" mat-raised-button color="primary" (click)="buy(carInsuranceProduct)" data-zanim-xs='{"from":{"opacity":0,"x":-30},"to":{"opacity":1,"x":0},"delay":0.8}'>
                        Придбати Автоцивілку
                       </button>
                     </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="row">
            <div class="col-auto mx-auto mt-4">
              <mat-paginator *ngIf="!loadingDataSource" class="mt-5 mystyle" #paginator [pageSize]="pageSize" [pageSizeOptions]="[6, 12, 18]" [showFirstLastButtons]="true" [length]="totalSize"
                [pageIndex]="currentPage" (page)="handlePageEvent($event)">
              </mat-paginator>
            </div>
          </div>

        </div>
      </section>

    </main>
    <!-- ===============================================-->
    <!--    End of Main Content-->
    <!-- ===============================================-->



    <!--===============================================-->
    <!--    Footer-->
    <!--===============================================-->


    <!-- ============================================-->
    <!-- <section> begin ============================-->
    <section style="background-color: #3D4C6F">

      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 mt-4 mt-lg-0">
            <div class="row">
              <div class="col-6 col-lg-4 text-white ms-lg-auto">
                <ul class="list-unstyled">
                  <li class="mb-3"><a class="text-white" href="contact.html">Зв'яжіться з нами</a></li>
                  <li class="mb-3"><a class="text-white" href="#!">FAQ</a></li>
                  <li class="mb-3"><a class="text-white" href="/policy">Політика конфіденційності</a></li>
                  <li class="mb-3"><a class="text-white" href="/termsofuse">Умови використання</a></li>
                </ul>
              </div>
              <div class="col-6 col-sm-5 ms-sm-auto">
                <ul class="list-unstyled">
                  <li class="mb-3"><a class="text-decoration-none d-flex align-items-center" href="#!"> <span class="brand-icon me-3"><span class="fab fa-twitter"></span></span>
                      <h5 class="fs-0 text-white mb-0 d-inline-block">Twitter</h5>
                    </a></li>
                  <li class="mb-3"><a class="text-decoration-none d-flex align-items-center" href="#!"> <span class="brand-icon me-3"><span class="fab fa-facebook-f"></span></span>
                      <h5 class="fs-0 text-white mb-0 d-inline-block">Facebook</h5>
                    </a></li>
                    <li class="mb-3 mb-3-custom">
                      <a class="text-decoration-none d-flex align-items-center" href='https://play.google.com/store/apps/details?id=com.finance.sydi.mobile_sydi_finance'>
                        <img
                          class="android"
                          alt='Get it on Google Play'
                          src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png' />
                      </a>
                    </li>

                    <li class="mb-3">
                      <a class="text-decoration-none d-flex align-items-center" href="https://apps.apple.com/">
                        <img
                          class="apple" style="margin-left:0%;width:73%"
                          src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1276560000&h=7e7b68fad19738b5649a1bfb78ff46e9"
                          alt="Download on the App Store">
                      </a>
                    </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end of .container-->

    </section>
    <!-- <section> close ============================-->
    <!-- ============================================-->


