<div fxLayout="column" fxLayoutGap="1em" fxFlex="50">

  <mat-form-field>

    <mat-select [(ngModel)]="configurations.language" placeholder="{{'preferences.Language' | translate}}">
      <mat-option *ngFor="let language of languages" [value]="language.locale">
        <span class="vertical-center">{{'preferences.' + language.name | translate}}<i *ngIf="language.isDefault" style="font-size: 0.75em;"> ({{'preferences.Default' | translate}})</i></span>
      </mat-option>
    </mat-select>

    <mat-hint>{{'preferences.LanguageHint' | translate}}</mat-hint>

  </mat-form-field>


</div>
