import { ChangeDetectorRef, Component, Directive, Input, OnInit, ViewChild } from "@angular/core";
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from "@angular/router";
import { InsuranceCompany } from "src/app/models/InsuranceCompany";
import { CarInsuranceProductRequest } from "src/app/models/car-insurance/car-insurance-product-request.model";
import { CarInsuranceProductResponse } from "src/app/models/car-insurance/car-insurance-product-response.model";
import { CarTypeModel } from "src/app/models/cartype.model";
import { CarTypePropertyModel } from "src/app/models/cartypeproperty.model";
import { City } from "src/app/models/city.model";
import { fadeInOut } from "src/app/services/animations";
import { CarInsuranceService } from "src/app/services/car-insurance.service";
import { CarTypeService } from "src/app/services/cartype.service";
import { CatalogService } from "src/app/services/catalog.service";
import { ConfigurationService } from "src/app/services/configuration.service";
import { NavigationService } from "src/app/services/navigation.service";
import { CarInsuranceProduct } from "src/app/models/car-insurance-product.model";
import { CarInsuranceProperty } from "src/app/models/car-insurance-property.model";
import { CarInsurancePropertyType } from "src/app/models/CarInsurancePropertyType";
import { AccountService } from "src/app/services/account.service";
import { User } from "src/app/models/user.model";
import { Role } from "src/app/models/role.model";
import { Observable, concatMap, debounceTime, distinctUntilChanged, filter, finalize, forkJoin, of, switchMap, tap } from "rxjs";
import { CarOrderModel } from "src/app/models/car-insurance/car-order.model";
import { NgForm, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from "@angular/material-moment-adapter";
import { dateValidator, yearValidator } from "src/app/shared/validators/equal.validator";
import { AlertService, MessageSeverity } from "src/app/services/alert.service";
import { CarBrandView } from "src/app/models/carbrandview.model";
import { CarModelView } from "src/app/models/carmodelview.model";
import { CarType } from "src/app/models/CarType";
import { CarTypeProperty } from "src/app/models/CarTypeProperty";
import { Moment } from "moment";
import { MatDatepicker } from "@angular/material/datepicker";
import moment from "moment";
import { CarInsuranceBookingRecord } from "src/app/models/car-insurance/car-insurance-booking-record.model";
import { ReleaseRequestModel } from "src/app/models/car-insurance/release-request.model";

@Component({
  selector: 'app-car-insurance-paid',
  templateUrl: './car-insurance-paid.component.html',
  styleUrls: ['./car-insurance-paid.component.scss'],
  animations: [fadeInOut],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ]
})

export class CarInsurancePaidComponent implements OnInit {

  @ViewChild('orderNgForm', { static: true }) orderNgForm: NgForm;

  @Input() isEditMode = true;

  public orderId: string;
  public loadingDataSource: boolean;

  public carInsuranceProduct: CarInsuranceProduct;
  public user: User;

  public searchResult: string;

  public sum: number;
  public franchisePrice: number;

  public insuranceCompanyName: string;
  public carMarkModel: string;
  public email: string;

  constructor (
    public configurations: ConfigurationService,
    private route: ActivatedRoute,
    private catalogService: CatalogService,
    private carService: CarTypeService,
    private carInsuranceService: CarInsuranceService,
    private router: Router,
    private accountService: AccountService,
    private ref: ChangeDetectorRef,
    private alertService: AlertService ) {

    }

    ngOnInit(): void {

      this.route.queryParams.subscribe(params => {

        this.orderId = params['order'];

        this.loadData(this.orderId);

      });

    }

    ngAfterViewInit() {

    }

    loadData(id: string)
    {
      this.loadingDataSource = true;

      this.alertService.showMessage('Автоцивілка', `Випуск Автоцивілки`, MessageSeverity.success);

      let bookedOrder: Observable<CarInsuranceBookingRecord> = this.carInsuranceService.getInsurance(id);

      let releaseRequestModel = new ReleaseRequestModel();
      releaseRequestModel.id = id;

      let releaseOrder: Observable<CarInsuranceBookingRecord> = this.carInsuranceService.releaseCarInsurance(releaseRequestModel);

      bookedOrder.pipe(
        concatMap(bookedOrderResult => releaseOrder),
        concatMap(releaseOrderResult => of(releaseOrderResult))
      ).subscribe(result => {

        this.sum = result.sum;
        this.franchisePrice = result.franchisePrice;
        this.insuranceCompanyName = result.insuranceCompanyName;
        this.carMarkModel = result.make + ' ' + result.model;
        this.email = result.email;

        this.loadingDataSource = false;
      },
      error => {
        console.log(error);
        this.loadingDataSource = false;
      });
    }

    ngAfterContentChecked() {
      this.ref.detectChanges();
    }

    get floatLabels(): string {
      return this.isEditMode ? 'auto' : 'always';
    }
}
