export class TravelDocumentModel {

  constructor(id?:string,
    applicationUserId?:string,
    seria?:string,
    number?:string,
    firstname?:string,
    lastname?:string,
    birthday?:Date) {
      this.id = id;
      this.applicationUserId = applicationUserId;
      this.seria = seria;
      this.number = number;
      this.firstname = firstname;
      this.lastname = lastname;
      this.birthday = birthday;
    }

  public id?:string;
  public applicationUserId?:string;
  public seria?:string;
  public number?:string;
  public firstname?:string;
  public lastname?:string;
  public birthday?:Date;
}
