<app-page-header title="DriverLicences" svgIcon="driverlicence"></app-page-header>

<form [hidden]="!isEditMode" #driverLicenceNgForm="ngForm" [formGroup]="driverLicenceForm" novalidate (ngSubmit)="save()"
      fxLayout="column"
      [fxLayoutGap]="isEditMode ? '1em' : '0'" autocomplete="off">

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">

    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'driver-licences.editor.Firstname' | translate}}</mat-label>
      <input matInput formControlName="firstname" />
      <mat-error *ngIf="firstname.hasError('required')">
        {{'driver-licences.editor.FirstnameRequired' | translate}}
      </mat-error>
      <mat-error *ngIf="!firstname.hasError('required') && firstname.hasError('pattern')">
          {{'driver-licences.editor.FirstnameRegex' | translate}}
      </mat-error>
    </mat-form-field>

    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'driver-licences.editor.Lastname' | translate}}</mat-label>
      <input matInput formControlName="lastname" />
      <mat-error *ngIf="lastname.hasError('required')">
        {{'driver-licences.editor.LastnameRequired' | translate}}
      </mat-error>
      <mat-error *ngIf="!lastname.hasError('required') && lastname.hasError('pattern')">
          {{'driver-licences.editor.LastnameRegex' | translate}}
      </mat-error>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">

    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'driver-licences.editor.Middlename' | translate}}</mat-label>
      <input matInput formControlName="middlename" />
      <mat-error *ngIf="middlename.hasError('required')">
        {{'driver-licences.editor.MiddlenameRequired' | translate}}
      </mat-error>
      <mat-error *ngIf="!middlename.hasError('required') && middlename.hasError('pattern')">
          {{'driver-licences.editor.MiddlenameRegex' | translate}}
      </mat-error>
    </mat-form-field>

    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'driver-licences.editor.Birthday' | translate}}</mat-label>

      <input matInput formControlName="birthday"
              [matDatepicker]="birthdayPicker"
              appMaskDate />
      <mat-hint>день.місяць.рік</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="birthdayPicker"></mat-datepicker-toggle>
      <mat-datepicker #birthdayPicker></mat-datepicker>

      <mat-error *ngIf="birthday.hasError('required')">
        {{'driver-licences.editor.BirthdayRequired' | translate}}
      </mat-error>
      <mat-error *ngIf="!birthday.hasError('required') && birthday.hasError('pattern')">
        {{'driver-licences.editor.BirthdayRegex' | translate}}
      </mat-error>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">

    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'driver-licences.editor.Seria' | translate}}</mat-label>
      <input matInput formControlName="seria"
              mask="UUU" [patterns]="casePatterns"
              placeholder="АЕС"
              [validation]="true"/>
      <mat-error *ngIf="seria.hasError('required')">
        {{'driver-licences.editor.SeriaRequired' | translate}}
      </mat-error>
      <mat-error *ngIf="!seria.hasError('required') && seria.hasError('pattern')">
          {{'driver-licences.editor.SeriaRegex' | translate}}
      </mat-error>
    </mat-form-field>

    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'driver-licences.editor.Number' | translate}}</mat-label>
      <input matInput formControlName="number"
             mask="999999"
             placeholder="123456"
             [showMaskTyped]="true" />
      <mat-error *ngIf="number.hasError('required')">
        {{'driver-licences.editor.NumberRequired' | translate}}
      </mat-error>
      <mat-error *ngIf="!number.hasError('required') && number.hasError('pattern')">
          {{'driver-licences.editor.NumberRegex' | translate}}
      </mat-error>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'driver-licences.editor.Issuer' | translate}}</mat-label>
      <input matInput formControlName="issuer" />

      <mat-error *ngIf="issuer.hasError('required')">
        {{'driver-licences.editor.IssuerRequired' | translate}}
      </mat-error>

      <mat-error *ngIf="!issuer.hasError('required') && issuer.hasError('pattern')">
          {{'driver-licences.editor.IssuerRegex' | translate}}
      </mat-error>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">

    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'driver-licences.editor.Issued' | translate}}</mat-label>
      <input matInput formControlName="issued"
                  [matDatepicker]="issuedPicker"
                  appMaskDate />
      <mat-hint>день.місяць.рік</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="issuedPicker"></mat-datepicker-toggle>
      <mat-datepicker #issuedPicker></mat-datepicker>

      <mat-error *ngIf="issued.hasError('required')">
        {{'driver-licences.editor.IssuedRequired' | translate}}
      </mat-error>
      <mat-error *ngIf="!issued.hasError('required') && issued.hasError('pattern')">
        {{'driver-licences.editor.IssuedRegex' | translate}}
      </mat-error>
    </mat-form-field>

    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'driver-licences.editor.ValidTo' | translate}}</mat-label>
      <input matInput formControlName="validTo" [matDatepicker]="validToPicker" />
      <mat-hint>день.місяць.рік</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="validToPicker"></mat-datepicker-toggle>
      <mat-datepicker #validToPicker></mat-datepicker>

      <mat-error *ngIf="validTo.hasError('required')">
        {{'driver-licences.editor.ValidToRequired' | translate}}
      </mat-error>
    </mat-form-field>
  </div>

  <mat-action-row>
    <button mat-raised-button class="spinner-button" [disabled]="isSaving" (click)="save()" color="primary">
      <mat-spinner *ngIf="isSaving" [diameter]="20" color="primary"></mat-spinner>
      <span>{{'settings.commands.Save' | translate}}</span></button>
    <button mat-raised-button  [disabled]="isSaving" (click)="cancel()" color="warn">{{'settings.commands.Cancel' | translate}}</button>
  </mat-action-row>

  <!-- https://github.com/angular/material2/issues/8798 -->
  <div>&nbsp;</div>
</form>


<div [hidden]="isEditMode" [@fadeInOut] class="page-content" >

  <div class="search-box">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'driver-licences.management.Search' | translate}}">
    </mat-form-field>
  </div>

  <div class="mat-elevation-z8">
    <mat-progress-bar *ngIf="loadingIndicator" mode="query"></mat-progress-bar>
    <mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="seria">
        <mat-header-cell fxFlex="20%" *matHeaderCellDef mat-sort-header> {{'driver-licences.management.Seria' | translate}} </mat-header-cell>
        <mat-cell fxFlex="20%" *matCellDef="let row"> {{row.seria}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="number">
        <mat-header-cell fxFlex="40%" *matHeaderCellDef mat-sort-header> {{'driver-licences.management.Number' | translate}} </mat-header-cell>
        <mat-cell fxFlex="40%" *matCellDef="let row"> {{row.number}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastname">
        <mat-header-cell fxFlex="40%" *matHeaderCellDef mat-sort-header> {{'driver-licences.management.Lastname' | translate}} </mat-header-cell>
        <mat-cell fxFlex="40%" *matCellDef="let row"> {{row.lastname}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef fxFlex="100px">
          <button mat-button color="primary" matTooltip="{{'driver-licences.management.NewDriverLicence' | translate}}" (click)="editDriverLicence()">
            <mat-icon class="mat-button-icon">note_add</mat-icon>{{'driver-licences.management.NewDriverLicence' | translate}}
          </button>
        </mat-header-cell>
        <mat-cell *matCellDef="let driverlicence" fxFlex="90px" fxFlexOffset="10px">
          <button mat-icon-button matTooltip="{{'driver-licences.management.Edit' | translate}}" (click)="editDriverLicence(driverlicence)">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button matTooltip="{{'driver-licences.management.Delete' | translate}}" (click)="confirmDelete(driverlicence)">
            <mat-icon color="warn">delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25]" ></mat-paginator>
  </div>
</div>
