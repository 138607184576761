<app-page-header title="TravelInsurances" svgIcon="travel"></app-page-header>

<div [@fadeInOut] class="page-content" >

  <div class="search-box">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'travel-insurances.management.Search' | translate}}">
    </mat-form-field>
  </div>

  <div class="mat-elevation-z8">
    <mat-progress-bar *ngIf="loadingIndicator" mode="query"></mat-progress-bar>
    <mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="insuranceCompanyName">
        <mat-header-cell fxFlex="30%" *matHeaderCellDef mat-sort-header> {{'travel-insurances.management.CompanyName' | translate}} </mat-header-cell>
        <mat-cell fxFlex="30%" *matCellDef="let row"> {{row.insuranceCompanyName}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="territoryName">
        <mat-header-cell fxFlex="34%" *matHeaderCellDef mat-sort-header> {{'travel-insurances.management.Territory' | translate}} </mat-header-cell>
        <mat-cell fxFlex="34%" *matCellDef="let row"> {{row.territoryName}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="purposeName">
        <mat-header-cell fxFlex="23%" *matHeaderCellDef mat-sort-header> {{'travel-insurances.management.Purpose' | translate}} </mat-header-cell>
        <mat-cell fxFlex="23%" *matCellDef="let row"> {{row.purposeName}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="insuranceValidFrom">
        <mat-header-cell fxFlex="23%" *matHeaderCellDef mat-sort-header> {{'travel-insurances.management.ValidFrom' | translate}} </mat-header-cell>
        <mat-cell fxFlex="23%" *matCellDef="let row"> {{row.insuranceValidFrom | shortDate}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef fxFlex="40px">
        </mat-header-cell>
        <mat-cell *matCellDef="let insurance" fxFlex="40px" fxFlexOffset="10px">
          <button mat-icon-button matTooltip="{{'travel-insurances.management.Download' | translate}}" (click)="downloadInsurance(insurance)">
            <mat-icon>download</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25]" ></mat-paginator>
  </div>
</div>
