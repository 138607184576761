
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, from, throwError } from 'rxjs';
import { mergeMap, switchMap, catchError, concatMap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { error } from 'console';
import { CommonService } from './common.service';

@Injectable()
export class EndpointBase {

  private taskPauser: Subject<any>;

  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    protected commonService: CommonService) {

  }

  protected get requestHeaders(): { headers: HttpHeaders | { [header: string]: string | string[]; } } {

    var accessToken = this.authService.accessToken;

    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + accessToken,
      'Content-Type': 'application/json',
      Accept: 'application/json, text/plain, */*'
    });

    return { headers };
  }

  protected get requestOnlyHeaders() : HttpHeaders{

    var accessToken = this.authService.accessToken;

    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + accessToken,
      'Content-Type': 'application/json',
      Accept: 'application/json, text/plain, */*'
    });

    return headers;
  }

  protected get requestHeadersClientToken(): { headers: HttpHeaders | { [header: string]: string | string[]; } } {

    var accessToken = this.authService.getClientToken();

    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + accessToken,
      'Content-Type': 'application/json',
      Accept: 'application/json, text/plain, */*'
    });

    return { headers };
  }

  public initClientCridentialToken() {
    return from(this.authService.initClientCredentials(true)).pipe();
  }

  public refreshLogin() {
    return this.authService.refreshLogin().pipe(
      catchError(error => {
        return this.handleError(error, () => this.refreshLogin());
      }));
  }

  protected handleError(error, continuation: () => Observable<any>) {
    if (error.status === 401) {
      if (this.commonService.isRefreshingLogin) {
        return this.pauseTask(continuation);
      }

      this.commonService.isRefreshingLogin = true;

      return from(this.authService.refreshLogin()).pipe(
        mergeMap(() => {

          console.log("this.authService.refreshLogin() passed");

          this.commonService.isRefreshingLogin = false;
          this.resumeTasks(true);

          return continuation();
        }),
        catchError(refreshLoginError => {

          console.log("this.authService.refreshLogin() NOT passed");

          this.commonService.isRefreshingLogin = false;
          this.resumeTasks(false);
          this.authService.reLogin();

          if (refreshLoginError.status === 401 || (refreshLoginError.error && refreshLoginError.error.error === 'invalid_grant')) {
            return throwError('session expired');
          } else {
            return throwError(refreshLoginError || 'server error');
          }
        }));
    }

    if (error.error && error.error.error === 'invalid_grant') {
      this.authService.reLogin();

      return throwError((error.error && error.error.error_description) ? `session expired (${error.error.error_description})` : 'session expired');
    } else {
      return throwError(error);
    }
  }

  protected handleErrorFromApi(error, continuation: () => Observable<any>) {
    if (error.status === 401) {
      if (this.commonService.isRefreshingClientToken) {
        //console.log("handleErrorFromApi 1");
        return this.pauseTask(continuation);
      }

      this.commonService.isRefreshingClientToken = true;

      //console.log("handleErrorFromApi 2");
      return from(this.authService.initClientCredentials(true)).pipe(
        mergeMap(() => {
          this.commonService.isRefreshingClientToken = false;
          this.resumeTasks(true);
          //console.log("handleErrorFromApi 3");
          return continuation();
        }),
        catchError(refreshLoginError => {
          this.commonService.isRefreshingClientToken = false;

          this.authService.initClientCredentials(true);

          //console.log("handleErrorFromApi 4");

          this.initClientCredentialsPromise();

          //console.log("handleErrorFromApi 5");

          this.resumeTasks(true);

          return continuation();
          console.log(refreshLoginError);
        }));
    }
  }

  initClientCredentialsPromise () {
    let promise = new Promise((resolve, reject) => {
      //TODO
      //console.log("initClientCredentialsPromise 1");
      this.authService.initClientCredentials(true).toPromise()
      .then(
        res => { // Success
          //console.log("initClientCredentialsPromise Success");
        }
      );

      //console.log("initClientCredentialsPromise 2");
    });
    return promise;
  }

  private pauseTask(continuation: () => Observable<any>) {
    if (!this.taskPauser) {
      this.taskPauser = new Subject();
    }

    return this.taskPauser.pipe(switchMap(continueOp => {
      return continueOp ? continuation() : throwError('session expired');
    }));
  }


  private resumeTasks(continueOp: boolean) {
    setTimeout(() => {
      if (this.taskPauser) {
        this.taskPauser.next(continueOp);
        this.taskPauser.complete();
        this.taskPauser = null;
      }
    });
  }
}
