
import { formatDate } from '@angular/common';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function EqualValidator(controlName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        const compareControl = control.parent ? control.parent.get(controlName) : null;
        const areEqual = compareControl && control.value === compareControl.value;
        return areEqual ? null : { notEqual: true };
    };
}

export function dateValidator(regExp : RegExp) : ValidatorFn {
  return (c : AbstractControl) : ValidationErrors => {
    return validateDateControl(c); }


    function validateDateControl(control: AbstractControl) : ValidationErrors { let rawDate = control.value;

      if(rawDate === "") {
        return;
      }

      if(rawDate == null && control.invalid) {
        return { "incorrect": true};
      }

      let formattedDate = formatDate(rawDate, 'dd.MM.yyyy', 'ru-UA');

      return validateDate(formattedDate, control, regExp);
    }

    function validateDate(result: string, control : AbstractControl, dateRegex : RegExp) : ValidationErrors {

      let valid = dateRegex.test(result);

      if(!valid)
        return { "incorrect": true};
      else
       return null;
    }
}

export function yearValidator(regExp : RegExp) : ValidatorFn {
  return (c : AbstractControl) : ValidationErrors => {
    return validateDateControl(c); }


    function validateDateControl(control: AbstractControl) : ValidationErrors { let rawDate = control.value;

      if(rawDate === "") {
        return;
      }

      if(rawDate == null && control.invalid) {
        return { "incorrect": true};
      }

      let formattedDate = formatDate(rawDate, 'yyyy', 'ru-UA');

      return validateDate(formattedDate, control, regExp);
    }

    function validateDate(result: string, control : AbstractControl, dateRegex : RegExp) : ValidationErrors {

      let valid = dateRegex.test(result);

      if(!valid)
        return { "incorrect": true};
      else
       return null;
    }
}
