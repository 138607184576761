
import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { CarInsuranceEndpoint } from './car-insurance-endpoint.service';
import { AuthService } from './auth.service';
import { City } from '../models/city.model';
import { CarInsuranceProductsRequest } from '../models/car-insurance-products-request.model';
import { CarInsuranceProduct } from '../models/car-insurance-product.model';
import { CarInsuranceProductsResponse } from '../models/car-insurance-products-response.model';
import { CarInsuranceProductResponse } from '../models/car-insurance/car-insurance-product-response.model';
import { CarInsuranceProductRequest } from '../models/car-insurance/car-insurance-product-request.model';
import { CarInsuranceBookingRecord } from '../models/car-insurance/car-insurance-booking-record.model';
import { ReleaseRequestModel } from '../models/car-insurance/release-request.model';


@Injectable()
export class CarInsuranceService {

    constructor(
        private authService: AuthService,
        private carInsuranceEndpoint: CarInsuranceEndpoint) {

    }

    getProducts(filter?: CarInsuranceProductsRequest) {
        return this.carInsuranceEndpoint.getProducts<CarInsuranceProductsResponse>(filter);
    }

    getProduct(filter?: CarInsuranceProductRequest) {
      return this.carInsuranceEndpoint.getProduct<CarInsuranceProductResponse>(filter);
    }

    getInsurance(id?: string) {
      return this.carInsuranceEndpoint.getInsuranceEndpoint<CarInsuranceBookingRecord>(id);
    }

    getInsurances() {
      return this.carInsuranceEndpoint.getInsurancesEndpoint<CarInsuranceBookingRecord[]>();
    }

    saveProduct(product?: CarInsuranceBookingRecord) {
      return this.carInsuranceEndpoint.createProductEndpoint<CarInsuranceBookingRecord>(product);
    }

    releaseCarInsurance(request?: ReleaseRequestModel) {
      return this.carInsuranceEndpoint.releaseCarInsuranceEndpoint<CarInsuranceBookingRecord>(request);
    }

    downloadPdf(url?: string) {
      return this.carInsuranceEndpoint.downloadPdfEndpoint(url);
    }

    get currentUser() {
      return this.authService.currentUser;
    }
}
