
    <!-- ===============================================-->
    <!--    Main Content-->
    <!-- ===============================================-->
    <main class="main" id="top">

      <!-- ============================================-->
      <!-- <section> begin ============================-->
      <section class="text-white py-0" data-zanim-timeline="{}" data-zanim-trigger="scroll">

        <div class="bg-holder" style="background-image:url(assets/img/video-1.jpg);">
          <video class="bg-video" autoplay="autoplay" loop="loop" muted="muted" playsinline="playsinline">
            <source src="assets/videos/morning-routine/video.mp4" type="video/mp4" />
            <source src="assets/videos/morning-routine/video.webm" type="video/webm" />
          </video>
        </div>
        <!--/.bg-holder-->

        <div class="container">
          <div class="row min-vh-100 py-8 align-items-center" data-inertia='{"weight":1.5}'>
            <div class="col-sm-9 col-lg-8">
              <div class="overflow-hidden">
                <h1 class="fs-4 fs-md-5" data-zanim-xs='{"delay":0.5}'>Онлайн Експерт Консультант</h1>
              </div>
              <div class="overflow-hidden">
                <p class="text-primary mt-4 mb-5 fs-1 fs-md-2 lh-xs" data-zanim-xs='{"delay":0.6}'>Великий досвід допомоги клієнтам у пошуку комплексних рішень</p>
              </div>
              <div class="overflow-hidden">
                <div data-zanim-xs='{"delay":0.7}'>

                  <mat-card class="mat-elevation-z6">
                    <mat-card-header>
                      <mat-card-title class="font-sans-serif">Туристичне Страхування</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>

                      <hr class="short" data-zanim-xs='{"from":{"opacity":0,"width":0},"to":{"opacity":1,"width":"4.20873rem"},"duration":2.8}' data-zanim-trigger="scroll" />

                      <div fxLayoutAlign="center center" class="mat-typography found-email-notice">
                        Туристична страховка онлайн. Онлайн оформлення страховки. Туристичні страховки по всьому світу. Різні цілі мандрування та терміни дії. Підтримка 24/7.
                      </div>

                      <form #filterNgForm="ngForm" [formGroup]="filterForm" (ngSubmit)="onClick()" novalidate >

                        <mat-form-field appearance="fill">
                          <mat-label>{{'travel-insurance.management.Term' | translate}}</mat-label>
                          <mat-select formControlName="term">
                            <mat-option *ngFor="let term of terms" [value]="term.id">
                              {{term.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                          <mat-label>{{'travel-insurance.management.Country' | translate}}</mat-label>
                          <mat-select formControlName="territory">
                            <mat-option *ngFor="let territory of territories" [value]="territory.id">
                              {{territory.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                          <mat-label>{{'travel-insurance.management.Purpose' | translate}}</mat-label>
                          <mat-select formControlName="purpose">
                            <mat-option *ngFor="let purpose of purposes" [value]="purpose.id">
                              {{purpose.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                          <mat-label>{{'travel-insurance.management.ValidFrom' | translate}}</mat-label>
                          <input matInput formControlName="validFrom"
                                  [matDatepicker]="validFromPicker"
                                  appMaskDate
                                  (dateChange)="changeValidFrom($event)"
                                  [min]="minValidFromDate"
                                  (focus)="validFromPicker.open()" (click)="validFromPicker.open()"
                                  />
                          <mat-hint>день.місяць.рік</mat-hint>
                          <mat-datepicker-toggle matSuffix [for]="validFromPicker"></mat-datepicker-toggle>
                          <mat-datepicker #validFromPicker></mat-datepicker>

                          <mat-error *ngIf="validFrom.hasError('required')">
                            {{'travel-insurance.management.ValidFromRequired' | translate}}
                          </mat-error>
                          <mat-error *ngIf="!validFrom.hasError('required') && validFrom.hasError('pattern')">
                            {{'travel-insurance.management.ValidFromRegex' | translate}}
                          </mat-error>

                          <mat-error *ngIf="!validFrom.hasError('required') && validFrom.hasError('matDatepickerMin')">
                            {{'travel-insurance.management.ValidFromMin' | translate}}
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                          <mat-label>{{'travel-insurance.management.ValidTo' | translate}}</mat-label>
                          <input matInput formControlName="validTo"
                                  [matDatepicker]="validToPicker"
                                  appMaskDate
                                  [min]="minValidToDate"
                                  (dateChange)="changeValidTo($event)"
                                  (focus)="validToPicker.open()" (click)="validToPicker.open()"
                                  />
                          <mat-hint>день.місяць.рік</mat-hint>
                          <mat-datepicker-toggle matSuffix [for]="validToPicker"></mat-datepicker-toggle>
                          <mat-datepicker #validToPicker></mat-datepicker>

                          <mat-error *ngIf="validTo.hasError('required')">
                            {{'travel-insurance.management.ValidToRequired' | translate}}
                          </mat-error>
                          <mat-error *ngIf="!validTo.hasError('required') && validTo.hasError('pattern')">
                            {{'travel-insurance.management.ValidToRegex' | translate}}
                          </mat-error>
                          <mat-error *ngIf="!validTo.hasError('required') && validTo.hasError('matDatepickerMin')">
                            {{'travel-insurance.management.ValidToMin' | translate}}
                          </mat-error>
                        </mat-form-field>

                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em" class="singleTripRow">
                          <mat-slide-toggle formControlName="multipleTrip" (change)="onToggle($event)" ></mat-slide-toggle>
                          <span>{{'travel-insurance.management.MultipleTrip' | translate}}</span>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
                          <table class="table" formArrayName="birthdays">
                            <tr>
                              <th>{{'travel-insurance.management.AddTraveler' | translate}}</th>
                              <th>
                                  <button type="button" (click)="addBirthday()" mat-mini-fab color="primary"><mat-icon>add</mat-icon></button>
                              </th>
                            </tr>
                            <tr *ngFor="let item of birthdays.controls; let i=index" [formGroupName]="i">
                              <td>
                                  <mat-form-field [floatLabel]="floatLabels" fxFlex>
                                    <mat-label>{{'travel-insurance.management.Birthday' | translate}}</mat-label>

                                    <input #myBirthday matInput formControlName="birthday"
                                            [matDatepicker]="birthdayPicker"
                                            appMaskDate
                                            [max]="maxBirthday"
                                           />

                                    <mat-hint>день.місяць.рік</mat-hint>
                                    <mat-datepicker-toggle matSuffix [for]="birthdayPicker"></mat-datepicker-toggle>
                                    <mat-datepicker #birthdayPicker></mat-datepicker>

                                    <mat-error *ngIf="item.get('birthday').errors?.required">
                                      {{'travel-insurance.management.BirthdayRequired' | translate}}
                                    </mat-error>
                                    <mat-error *ngIf="!item.get('birthday').errors?.required && item.get('birthday').errors?.pattern">
                                      {{'travel-insurance.management.BirthdayRegex' | translate}}
                                    </mat-error>
                                    <mat-error *ngIf="!item.get('birthday').errors?.required && item.get('birthday').errors?.matDatepickerMax" >
                                      {{'travel-insurance.management.BirthdayMax' | translate}}
                                    </mat-error>
                                  </mat-form-field>

                              </td>
                              <td>
                                  <button (click)="removeBirthday(i)" mat-mini-fab color="warn"><mat-icon>remove</mat-icon></button>
                              </td>
                            </tr>
                          </table>

                          <div *ngIf="birthdaysCtrl.invalid">
                            <span *ngIf="birthdaysCtrl.hasError('required')" class="mat-error">
                              {{'travel-insurance.management.TravelerRequired' | translate}}
                            </span>
                            <!-- <span *ngIf="birthdaysCtrl.hasError('minlength')" class="mat-error">
                              It should have at least {{birthdaysCtrl.getError('minlength').requiredLength}} emails
                            </span> -->
                          </div>
                        </div>
                      </form>
                    </mat-card-content>
                    <mat-card-actions>
                      <button mat-raised-button color="primary" [disabled]="loadingDataSource" (click)="onClick()">
                        {{'travel-insurance.commands.Search' | translate}}
                      </button>
                      <div fxFlex></div>
                    </mat-card-actions>
                  </mat-card>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end of .container-->

      </section>
      <!-- <section> close ============================-->
      <!-- ============================================-->

    </main>
    <!-- ===============================================-->
    <!--    End of Main Content-->
    <!-- ===============================================-->



    <!--===============================================-->
    <!--    Footer-->
    <!--===============================================-->


    <!-- ============================================-->
    <!-- <section> begin ============================-->
    <section style="background-color: #3D4C6F">

      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 mt-4 mt-lg-0">
            <div class="row">
              <div class="col-6 col-lg-4 text-white ms-lg-auto">
                <ul class="list-unstyled">
                  <li class="mb-3"><a class="text-white" href="contact.html">Зв'яжіться з нами</a></li>
                  <li class="mb-3"><a class="text-white" href="#!">FAQ</a></li>
                  <li class="mb-3"><a class="text-white" href="/policy">Політика конфіденційності</a></li>
                  <li class="mb-3"><a class="text-white" href="/termsofuse">Умови використання</a></li>
                </ul>
              </div>
              <div class="col-6 col-sm-5 ms-sm-auto">
                <ul class="list-unstyled">
                  <li class="mb-3"><a class="text-decoration-none d-flex align-items-center" href="#!"> <span class="brand-icon me-3"><span class="fab fa-twitter"></span></span>
                      <h5 class="fs-0 text-white mb-0 d-inline-block">Twitter</h5>
                    </a></li>
                  <li class="mb-3"><a class="text-decoration-none d-flex align-items-center" href="#!"> <span class="brand-icon me-3"><span class="fab fa-facebook-f"></span></span>
                      <h5 class="fs-0 text-white mb-0 d-inline-block">Facebook</h5>
                    </a></li>
                    <li class="mb-3 mb-3-custom">
                      <a class="text-decoration-none d-flex align-items-center" href='https://play.google.com/store/apps/details?id=com.finance.sydi.mobile_sydi_finance'>
                        <img
                          class="android"
                          alt='Get it on Google Play'
                          src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png' />
                      </a>
                    </li>

                    <li class="mb-3">
                      <a class="text-decoration-none d-flex align-items-center" href="https://apps.apple.com/">
                        <img
                          class="apple" style="margin-left:0%;width:73%"
                          src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1276560000&h=7e7b68fad19738b5649a1bfb78ff46e9"
                          alt="Download on the App Store">
                      </a>
                    </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end of .container-->

    </section>
    <!-- <section> close ============================-->
    <!-- ============================================-->

