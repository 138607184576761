



    <!-- ===============================================-->
    <!--    Main Content-->
    <!-- ===============================================-->
    <main class="main" id="top">


      <!-- ============================================-->
      <!-- <section> begin ============================-->
      <section class="py-0">

        <div class="bg-holder overlay overlay-1" style="background-image:url(../assets/img/background-1.jpg);">
        </div>
        <!--/.bg-holder-->

        <div class="container">
          <div class="row min-vh-100 align-items-center">
            <div class="col-md-9 col-lg-6 mx-auto" data-zanim-timeline="{}" data-zanim-trigger="scroll">
              <div class="mb-5"></div>
              <div class="mb-5" data-zanim-xs='{"delay":0,"duration":1}'><a href="index.html"><img src="assets/img/logo-4.png" alt="SYDI Finance" /></a></div>

              <div class="card" data-zanim-xs='{"delay":0.1,"duration":1}'>
                
                <mat-card class="email-confirmation-card mat-elevation-z6">
                  <mat-card-content>
                    <div class="overflow-hidden">
                      <p class="text-primary text-center mt-4 mb-5 fs-1 fs-md-2 lh-xs" data-zanim-xs='{"delay":0.6}'>{{message}}</p>
                    </div>
                  </mat-card-content>
                  <mat-card-actions align="end">
                    <a mat-raised-button [color]="isSuccess ? 'primary' : 'warn'" routerLink="/login">Завершити</a>
                  </mat-card-actions>
                </mat-card>

                
              </div>
            </div>
          </div>
        </div>
        <!-- end of .container-->

      </section>
      <!-- <section> close ============================-->
      <!-- ============================================-->


    </main>
    <!-- ===============================================-->
    <!--    End of Main Content-->
    <!-- ===============================================-->
