import { Component, OnInit, AfterViewInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { fadeInOut } from '../../services/animations';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { AccountService } from '../../services/account.service';
import { DriverLicenceService } from '../../services/driver-licence.service';
import { Utilities } from '../../services/utilities';
import { DriverLicence } from '../../models/driver-licence.model';
import { NavigationService } from 'src/app/services/navigation.service';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { dateValidator } from 'src/app/shared/validators/equal.validator';

@Component({
  selector: 'driver-licence-list',
  templateUrl: './driver-licence-list.component.html',
  styleUrls: ['./driver-licence-list.component.scss'],
  animations: [fadeInOut]
})

export class DriverLicenceListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild('driverLicenceNgForm', { static: true }) driverLicenceNgForm: NgForm;

  displayedColumns = ['seria', 'number', 'lastname', 'actions'];
  dataSource: MatTableDataSource<DriverLicence>;
  sourceDriverLicence: DriverLicence;
  loadingIndicator: boolean;

  public isSaving = false;
  isNewDriverLicence = false;
  @Input() driverLicence: DriverLicence = new DriverLicence();
  @Input() isEditMode = false;

  driverLicenceForm: UntypedFormGroup;
  private onDriverLicenceSaved = new Subject<DriverLicence>();
  driverLicenceSaved$ = this.onDriverLicenceSaved.asObservable();

  constructor(
    private translationService: AppTranslationService,
    private accountService: AccountService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private driverLicenceService: DriverLicenceService,
    private formBuilder: UntypedFormBuilder,
    private navigationService: NavigationService) {

    this.buildForm();

    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = this.translationService.getTranslation('PAGINATOR.ITEMS_PER_PAGE');
    this.paginator._intl.getRangeLabel = this.sydiRangeLabel;

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy() {
    this.onDriverLicenceSaved.unsubscribe();
  }

  get firstname() { return this.driverLicenceForm.get('firstname');  }

  get lastname() { return this.driverLicenceForm.get('lastname');  }

  get middlename() { return this.driverLicenceForm.get('middlename');  }

  get birthday() { return this.driverLicenceForm.get('birthday');  }

  get seria() { return this.driverLicenceForm.get('seria');  }

  get number() { return this.driverLicenceForm.get('number');  }

  get issuer() { return this.driverLicenceForm.get('issuer');  }

  get issued() { return this.driverLicenceForm.get('issued');  }

  get validTo() { return this.driverLicenceForm.get('validTo');  }

  public casePatterns = {
    U: {
      pattern: new RegExp('[a-zA-ZаАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ]'),
    }
  };

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  public confirmDelete(driverLicence: DriverLicence) {

    let message = this.translationService.getTranslation('driver-licences.editor.DeleteMessage',
           {seria : driverLicence.seria, number : driverLicence.number});
    this.snackBar.open( `${message}`,
                        this.translationService.getTranslation('driver-licences.editor.Yes'),
                        { duration: 5000 })
      .onAction().subscribe(() => {
        this.loadingIndicator = true;

        this.driverLicenceService.deleteDriverLicence(driverLicence.id)
          .subscribe(results => {
            this.loadingIndicator = false;

            this.dataSource.data = this.dataSource.data.filter((u) => u.id !== driverLicence.id);
          },
            error => {
              this.loadingIndicator = false;
            });
      });
  }

  public editDriverLicence(driverLicence?: DriverLicence) {
    this.isEditMode = true;

    if (!driverLicence) {
      this.isNewDriverLicence = true;
      this.driverLicence = new DriverLicence();

      const current = new Date();

      // it returns a timestamp
      const priorOneDay = new Date().setDate(current.getDate() - 1);
      const nextOneYear = new Date().setDate(current.getDate() + 365);
      this.driverLicence.issued = new Date(priorOneDay);
      this.driverLicence.validTo = new Date(nextOneYear);
      this.driverLicence.birthday = new Date();
    }
    else
    {
      this.isNewDriverLicence = false;
      this.driverLicence = driverLicence;
      this.resetDriverLicenceForm();
    }
  }

  public save() {

    if (!this.driverLicenceNgForm.submitted) {
      // Causes validation to update.
      this.driverLicenceNgForm.onSubmit(null);
      return;
    }

    if (!this.driverLicenceForm.valid) {
      return;
    }

    this.isSaving = true;

    const editedDriverLicence = this.getEditedDriverLicence();

    if (this.isNewDriverLicence) {
      this.driverLicenceService.newDriverLicence(editedDriverLicence).subscribe(
        driverLicence => this.newSaveCompleted(driverLicence),
        error => this.saveFailed(error));
    } else {
      this.driverLicenceService.updateDriverLicence(editedDriverLicence).subscribe(
        driverLicence => this.saveCompleted(driverLicence),
        error => this.saveFailed(error));
    }
  }

  public cancel() {
    this.resetForm(true);
  }



   private newSaveCompleted(driverLicence?: DriverLicence) {

    this.dataSource.data = [driverLicence, ...this.dataSource.data];  //refresh the dataSource

    if (driverLicence) {
      this.driverLicence = driverLicence;
    }

    this.isSaving = false;
    this.isEditMode = false;

    this.resetForm(true);

    this.onDriverLicenceSaved.next(this.driverLicence);
  }

  private saveCompleted(driverLicence?: DriverLicence) {

    this.dataSource.data = this.dataSource.data.filter((item, key)=>{
      if(item.id == driverLicence.id)
      {
        item.firstname = driverLicence.firstname;
        item.lastname = driverLicence.lastname;
        item.middlename = driverLicence.middlename;
        item.birthday = driverLicence.birthday;
        item.seria = driverLicence.seria;
        item.number = driverLicence.number;
        item.issuer = driverLicence.issuer;
        item.issued = driverLicence.issued;
        item.validTo = driverLicence.validTo;
      }
      return true;
    });

    if (driverLicence) {
      this.driverLicence = driverLicence;
    }

    this.isSaving = false;
    this.isEditMode = false;

    this.resetForm(true);

    this.onDriverLicenceSaved.next(this.driverLicence);
  }

  private saveFailed(error: any) {
    this.isSaving = false;
    this.isEditMode = false;
  }

  private buildForm() {

    let dateRegex = new RegExp("^\\d{1,2}.\\d{1,2}.\\d{4,4}$");

    this.driverLicenceForm = this.formBuilder.group({
      firstname: ['', [Validators.required,
        Validators.pattern("^[аАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ\']{2,40}$")]],
      lastname: ['', [Validators.required,
        Validators.pattern("^[аАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ\']{2,200}$")]],
      middlename: ['', [Validators.required,
        Validators.pattern("^[аАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ\']{1,200}$")]],
      birthday: ['', [Validators.required, dateValidator(dateRegex)]],
      seria: ['', [Validators.required,
        Validators.pattern("^[АБВГДЕЖЗИЙКЛМНОПРСТУФХЦЧШЩЬЮЯЇІЄҐ]{3,3}$")]],
      number: ['', [Validators.required, Validators.pattern("^[0-9]{6,6}$")]],
      issuer: ['', [Validators.required,
        Validators.pattern("^[аАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ1234567890\.\,\'\/\ ]{4,200}$")]],
      issued: ['', [Validators.required, dateValidator(dateRegex)]],
      validTo: [null],
    });
  }

  public resetForm(stopEditing = false) {
    if (stopEditing) {
      this.isEditMode = false;
    }

    this.driverLicence = new DriverLicence();

    this.resetDriverLicenceForm();
  }

  private resetDriverLicenceForm()
  {

    this.driverLicenceForm.reset({
      firstname: this.driverLicence.firstname || '',
      lastname: this.driverLicence.lastname || '',
      middlename: this.driverLicence.middlename || '',
      birthday: this.driverLicence.birthday || '',
      seria: this.driverLicence.seria || '',
      number: this.driverLicence.number || '',
      issuer: this.driverLicence.issuer || '',
      issued: this.driverLicence.issued || '',
      validTo: this.driverLicence.validTo,
    });
  }


  private getEditedDriverLicence(): DriverLicence {
    const formModel = this.driverLicenceForm.value;

    return {
      id: this.driverLicence.id,
      firstname: formModel.firstname,
      lastname: formModel.lastname,
      middlename: formModel.middlename,

      applicationUserId: this.driverLicenceService.currentUser.id,

      birthday: formModel.birthday,
      seria: formModel.seria,
      number: formModel.number,
      issuer: formModel.issuer,
      issued: formModel.issued,
      validTo: formModel.validTo,

      createdBy: this.driverLicence.createdBy,
      createdDate: this.driverLicence.createdDate,
      updatedBy: this.driverLicence.updatedBy,
      updatedDate: this.driverLicence.updatedDate
    };
  }

  private sydiRangeLabel = (page: number, pageSize: number, length: number) => {

    let ofTranlated = this.translationService.getTranslation('PAGINATOR.OF');
    if (length == 0 || pageSize == 0) { return `0 ${ofTranlated} ${length}`; }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${ofTranlated} ${length}`;
  }

  private refresh() {
    // Causes the filter to refresh there by updating with recently added data.
    this.applyFilter(this.dataSource.filter);
  }

  get floatLabels(): string {
    return this.isEditMode ? 'auto' : 'always';
  }

  private loadData() {
    this.loadingIndicator = true;

    this.driverLicenceService.getDriverLicences()
      .subscribe(results => {
        this.loadingIndicator = false;
        this.dataSource.data = results;
      },
        error => {
          this.loadingIndicator = false;
        });
  }

}
