<app-page-header title="Passports" svgIcon="passport"></app-page-header>

<form [hidden]="!isEditMode" #passportNgForm="ngForm" [formGroup]="passportForm" novalidate (ngSubmit)="save()"
      fxLayout="column"
      [fxLayoutGap]="isEditMode ? '1em' : '0'" autocomplete="off">

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">

    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'passports.editor.Firstname' | translate}}</mat-label>
      <input matInput formControlName="firstname" />
      <mat-error *ngIf="firstname.hasError('required')">
        {{'passports.editor.FirstnameRequired' | translate}}
      </mat-error>
      <mat-error *ngIf="!firstname.hasError('required') && firstname.hasError('pattern')">
          {{'passports.editor.FirstnameRegex' | translate}}
      </mat-error>
    </mat-form-field>

    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'passports.editor.Lastname' | translate}}</mat-label>
      <input matInput formControlName="lastname" />
      <mat-error *ngIf="lastname.hasError('required')">
        {{'passports.editor.LastnameRequired' | translate}}
      </mat-error>
      <mat-error *ngIf="!lastname.hasError('required') && lastname.hasError('pattern')">
          {{'passports.editor.LastnameRegex' | translate}}
      </mat-error>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">

    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'passports.editor.Middlename' | translate}}</mat-label>
      <input matInput formControlName="middlename" />
      <mat-error *ngIf="middlename.hasError('required')">
        {{'passports.editor.MiddlenameRequired' | translate}}
      </mat-error>
      <mat-error *ngIf="!middlename.hasError('required') && middlename.hasError('pattern')">
          {{'passports.editor.MiddlenameRegex' | translate}}
      </mat-error>
    </mat-form-field>

    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'passports.editor.Birthday' | translate}}</mat-label>

      <input #myBirthday matInput formControlName="birthday"
              [matDatepicker]="birthdayPicker"
              appMaskDate
             />

      <mat-hint>день.місяць.рік</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="birthdayPicker"></mat-datepicker-toggle>
      <mat-datepicker #birthdayPicker></mat-datepicker>

      <mat-error *ngIf="birthday.hasError('required')">
        {{'passports.editor.BirthdayRequired' | translate}}
      </mat-error>
      <mat-error *ngIf="!birthday.hasError('required') && birthday.hasError('pattern')">
        {{'passports.editor.BirthdayRegex' | translate}}
      </mat-error>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">

    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-slide-toggle color="primary" formControlName="isIdCard"
          (change)="toggle($event)">{{'passports.editor.IsIdCard' | translate}} <small>{{'passports.editor.IsIdCardDescription' | translate}}</small></mat-slide-toggle>
      <input matInput [hidden]="true">
    </mat-form-field>

    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'passports.editor.Seria' | translate}}</mat-label>

      <input matInput formControlName="seria"
              mask="UU" [patterns]="casePatterns"
              placeholder="АЕ"
              [validation]="true"
              oninput="this.value = this.value.toUpperCase()"
              >

      <mat-error *ngIf="seria.hasError('required')">
        {{'passports.editor.SeriaRequired' | translate}}
      </mat-error>
      <mat-error *ngIf="!seria.hasError('required') && seria.hasError('pattern')">
          {{'passports.editor.SeriaRegex' | translate}}
      </mat-error>
    </mat-form-field>

    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'passports.editor.Number' | translate}}</mat-label>
      <input matInput formControlName="number" *ngIf="!isChecked"
             mask="999999"
             placeholder="123456"
             [showMaskTyped]="true" />

      <input matInput formControlName="number" *ngIf="isChecked"
             mask="999999999"
             placeholder="123456789"
             [showMaskTyped]="true" />
      <mat-error *ngIf="number.hasError('required')">
        {{'passports.editor.NumberRequired' | translate}}
      </mat-error>
      <mat-error *ngIf="!number.hasError('required') && number.hasError('pattern')">
          {{'passports.editor.NumberRegex' | translate}}
      </mat-error>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'passports.editor.Issuer' | translate}}</mat-label>
      <input matInput formControlName="issuer" />

      <mat-error *ngIf="issuer.hasError('required')">
        {{'passports.editor.IssuerRequired' | translate}}
      </mat-error>

      <mat-error *ngIf="!issuer.hasError('required') && issuer.hasError('pattern')">
          {{'passports.editor.IssuerRegex' | translate}}
      </mat-error>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">

    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'passports.editor.RegistrationPlace' | translate}}</mat-label>
      <input matInput [(ngModel)]="selectedCity" formControlName="searchCity" [matAutocomplete]="auto" placeholder="Мінімум 3 символи">
      <button matSuffix mat-icon-button aria-label="Clear" (click)="clearCitySelection()">
        <mat-icon>cancel</mat-icon>
      </button>

      <mat-error *ngIf="!isValidSelectedCity">{{'passports.editor.RegistrationPlaceRequired' | translate}}</mat-error>

      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectedCity()" >
        <mat-option *ngIf="isLoadingCity" class="is-loading">{{'passports.editor.RegistrationPlaceLoading' | translate}}</mat-option>
        <ng-container *ngIf="!isLoadingCity">
          <mat-option *ngFor="let city of filteredCities" [value]="city">
            <span><b>{{city.town}}</b> {{city.region}}</span>
          </mat-option>
        </ng-container>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'passports.editor.Street' | translate}}</mat-label>
      <input matInput formControlName="street" />

      <mat-error *ngIf="street.hasError('required')">
        {{'passports.editor.StreetRequired' | translate}}
      </mat-error>

      <mat-error *ngIf="!street.hasError('required') && street.hasError('pattern')">
          {{'passports.editor.StreetRegex' | translate}}
      </mat-error>
    </mat-form-field>

    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'passports.editor.HouseNumber' | translate}}</mat-label>
      <input matInput formControlName="houseNumber" />

      <mat-error *ngIf="houseNumber.hasError('required')">
        {{'passports.editor.HouseNumberRequired' | translate}}
      </mat-error>

      <mat-error *ngIf="!houseNumber.hasError('required') && houseNumber.hasError('pattern')">
          {{'passports.editor.HouseNumberRegex' | translate}}
      </mat-error>
    </mat-form-field>

    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'passports.editor.ApartmentNumber' | translate}}</mat-label>
      <input matInput formControlName="apartmentNumber" />

      <mat-error *ngIf="apartmentNumber.hasError('required')">
        {{'passports.editor.ApartmentNumberRequired' | translate}}
      </mat-error>

      <mat-error *ngIf="!apartmentNumber.hasError('required') && apartmentNumber.hasError('pattern')">
          {{'passports.editor.ApartmentNumberRegex' | translate}}
      </mat-error>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">

    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'passports.editor.Issued' | translate}}</mat-label>
      <input matInput formControlName="issued"
              [matDatepicker]="issuedPicker"
              appMaskDate
              />
      <mat-hint>день.місяць.рік</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="issuedPicker"></mat-datepicker-toggle>
      <mat-datepicker #issuedPicker></mat-datepicker>

      <mat-error *ngIf="issued.hasError('required')">
        {{'passports.editor.IssuedRequired' | translate}}
      </mat-error>
      <mat-error *ngIf="!issued.hasError('required') && issued.hasError('pattern')">
        {{'passports.editor.IssuedRegex' | translate}}
      </mat-error>
    </mat-form-field>

    <mat-form-field [floatLabel]="floatLabels" fxFlex>
      <mat-label>{{'passports.editor.ValidTo' | translate}}</mat-label>
      <input matInput formControlName="validTo"
                      [matDatepicker]="validToPicker"
                      appMaskDate
                      />
      <mat-hint>день.місяць.рік</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="validToPicker"></mat-datepicker-toggle>
      <mat-datepicker #validToPicker></mat-datepicker>

      <mat-error *ngIf="validTo.hasError('required')">
        {{'passports.editor.ValidToRequired' | translate}}
      </mat-error>
    </mat-form-field>
  </div>

  <mat-action-row>
    <button mat-raised-button class="spinner-button" [disabled]="isSaving" (click)="save()" color="primary">
      <mat-spinner *ngIf="isSaving" [diameter]="20" color="primary"></mat-spinner>
      <span>{{'settings.commands.Save' | translate}}</span></button>
    <button mat-raised-button  [disabled]="isSaving" (click)="cancel()" color="warn">{{'settings.commands.Cancel' | translate}}</button>
  </mat-action-row>

  <!-- https://github.com/angular/material2/issues/8798 -->
  <div>&nbsp;</div>
</form>


<div [hidden]="isEditMode" [@fadeInOut] class="page-content" >

  <div class="search-box">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'passports.management.Search' | translate}}">
    </mat-form-field>
  </div>

  <div class="mat-elevation-z8">
    <mat-progress-bar *ngIf="loadingIndicator" mode="query"></mat-progress-bar>
    <mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="seria">
        <mat-header-cell fxFlex="20%" *matHeaderCellDef mat-sort-header> {{'passports.management.Seria' | translate}} </mat-header-cell>
        <mat-cell fxFlex="20%" *matCellDef="let row"> {{row.seria}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="number">
        <mat-header-cell fxFlex="40%" *matHeaderCellDef mat-sort-header> {{'passports.management.Number' | translate}} </mat-header-cell>
        <mat-cell fxFlex="40%" *matCellDef="let row"> {{row.number}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastname">
        <mat-header-cell fxFlex="40%" *matHeaderCellDef mat-sort-header> {{'passports.management.Lastname' | translate}} </mat-header-cell>
        <mat-cell fxFlex="40%" *matCellDef="let row"> {{row.lastname}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef fxFlex="100px">
          <button mat-button color="primary" matTooltip="{{'passports.management.NewPassport' | translate}}" (click)="editPassport()">
            <mat-icon class="mat-button-icon">note_add</mat-icon>{{'passports.management.NewPassport' | translate}}
          </button>
        </mat-header-cell>
        <mat-cell *matCellDef="let passport" fxFlex="90px" fxFlexOffset="10px">
          <button mat-icon-button matTooltip="{{'passports.management.Edit' | translate}}" (click)="editPassport(passport)">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button matTooltip="{{'passports.management.Delete' | translate}}" (click)="confirmDelete(passport)">
            <mat-icon color="warn">delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25]" ></mat-paginator>
  </div>
</div>
