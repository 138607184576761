import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { NavigationService } from 'src/app/services/navigation.service';

import { fadeInOut } from '../../services/animations';
import { ConfigurationService } from '../../services/configuration.service';
import { FormArray, FormGroup, NgForm, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TravelService } from 'src/app/services/travel.service';
import { TermModel } from 'src/app/models/travel-insurance/term.model';
import { TerritoryModel } from 'src/app/models/travel-insurance/territory.model';
import { PurposeModel } from 'src/app/models/travel-insurance/purpose.model';
import { TravelFilterResponse } from 'src/app/models/travel-insurance/travel-filter-response.model';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import moment from 'moment';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { dateValidator } from 'src/app/shared/validators/equal.validator';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: 'travel-insurance',
  templateUrl: './travel-insurance.component.html',
  styleUrls: ['./travel-insurance.component.scss'],
  animations: [fadeInOut],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ]
})

export class TravelInsuranceComponent implements OnInit, OnDestroy {

  @ViewChild('filterNgForm', { static: true }) filterNgForm: NgForm;

  @ViewChild('validFromPicker') validFromPicker: MatDatepicker<Date>;
  @ViewChild('validToPicker') validToPicker: MatDatepicker<Date>;

  public filterForm: UntypedFormGroup;

  public terms: TermModel[];
  public territories: TerritoryModel[];
  public purposes: PurposeModel[];
  public productId: string;

  public loadingDataSource = false;

  public birthdaysCtrl: FormArray;

  public maxBirthday = moment();

  public minValidFromDate = moment().add(1, 'days');
  public minValidToDate = moment().add(1, 'days');

  constructor(public configurations: ConfigurationService,
    private route: ActivatedRoute,
    private router: Router,
    private navigationService: NavigationService,
    private travelService: TravelService,
    private formBuilder: UntypedFormBuilder,
    private ref: ChangeDetectorRef ) {

  }

  ngOnInit() {
    this.birthdaysCtrl = this.formBuilder.array([], Validators.compose([Validators.required, Validators.minLength(1)]));

    this.buildForm();

    this.addBirthday();
    this.addBirthday();

    this.term.disable();
    this.loadData();
  }

  ngOnDestroy() {

  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  loadData() {
      this.loadingDataSource = true;

      let loadingTravelFilter: Observable<TravelFilterResponse> = this.travelService.getFilter();

      forkJoin(loadingTravelFilter).subscribe(results => {
        let filter = results[0];

        this.purposes = filter.purposes;
        this.terms = filter.terms;
        this.territories = filter.territories;

        this.productId = filter.productId;

        this.purpose.setValue(filter.purposes[0].id);
        this.term.setValue(filter.terms[0].id);
        this.territory.setValue(filter.territories[0].id);

        this.loadingDataSource = false;
      },
      error => {
        console.log(error);
        this.loadingDataSource = false;
      });
  }

    get term() { return this.filterForm.get('term'); }

    get territory() { return this.filterForm.get('territory'); }

    get purpose() { return this.filterForm.get('purpose'); }

    get multipleTrip() { return this.filterForm.get('multipleTrip');  }

    get birthdays() : FormArray {
       return this.filterForm.get("birthdays") as FormArray
    }

    get validFrom() { return this.filterForm.get('validFrom'); }

    get validTo() { return this.filterForm.get('validTo'); }

    private buildForm() {

      let dateRegex = new RegExp("^\\d{1,2}.\\d{1,2}.\\d{4,4}$");

      this.filterForm = this.formBuilder.group({
        term: ['', [Validators.required]],
        territory: ['', [Validators.required]],
        purpose: ['', [Validators.required]],
        multipleTrip: [false],
        birthdays: this.birthdaysCtrl,
        validFrom: ['', [Validators.required, dateValidator(dateRegex)]],
        validTo: ['', [Validators.required, dateValidator(dateRegex)]]
      });
    }

    newBirthday(): FormGroup {
      let dateRegex = new RegExp("^\\d{1,2}.\\d{1,2}.\\d{4,4}$");
      return this.formBuilder.group({
        birthday: ['', [Validators.required, dateValidator(dateRegex)]],
        years: '',
      });
    }

    addBirthday() {
      if(this.birthdaysCtrl.length < 5) //max, salamandra support
        this.birthdaysCtrl.push(this.newBirthday());
    }

    removeBirthday(i:number) {
      this.birthdaysCtrl.removeAt(i);
    }

    onClick() {

      this.filterForm.markAllAsTouched();

      if (!this.filterNgForm.submitted) {
        // Causes validation to update.
        this.filterNgForm.onSubmit(null);
      }

      if (!this.filterForm.valid) {
        return;
      }

      const formModel = this.filterForm.value;

      const termId = formModel.term;
      const territoryId = formModel.territory;
      const purposeId = formModel.purpose;
      const multipleTrip = formModel.multipleTrip;

      const birthdays = formModel.birthdays.map(({ birthday }) => birthday.format('DD-MM-YYYY'));
      const birthdaysJson = JSON.stringify(birthdays);

      const validFrom = formModel.validFrom.format('DD-MM-YYYY');
      const validTo = formModel.validTo ? formModel.validTo.format('DD-MM-YYYY') : undefined;

      let params;

      if(!multipleTrip) {
        params = {
          territoryId: territoryId,
          purposeId: purposeId,
          multipleTrip: multipleTrip,
          birthdays: birthdaysJson,
          validFrom :validFrom,
          validTo :validTo,
          productId: this.productId
        };
      } else {
        params = {
          termId: termId,
          territoryId: territoryId,
          purposeId: purposeId,
          multipleTrip: multipleTrip,
          birthdays: birthdaysJson,
          validFrom :validFrom,
          productId: this.productId
        };
      }

      this.router.navigate(['/travel-insurance/offers'], { queryParams: params });
    }

    public onToggle(event: MatSlideToggleChange) {
      if(event.checked) {
        this.term.enable();
        this.validTo.disable();
      } else {
        this.term.disable();
        this.validTo.enable();
      }
    }

    public changeValidFrom(event) {

      var dateTo = this.validTo.value;
      var dateFrom = this.validFrom.value;

      if(dateTo < dateFrom) {
        this.validTo.setValue("");
        this.validToPicker.open();
      }

    }

    public changeValidTo(event) {

      var dateTo = this.validTo.value;
      var dateFrom = this.validFrom.value;

      if(dateTo < dateFrom) {
        this.validFrom.setValue("");
        this.validFromPicker.open();
      }

    }
}
