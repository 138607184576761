import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { EndpointBase } from './endpoint-base.service';
import { ConfigurationService } from './configuration.service';
import { CommonService } from './common.service';

@Injectable()
export class DriverLicenceEndpoint extends EndpointBase {

    get driverLicenceListUrl() { return this.configurations.baseUrl + '/api/v1/driverlicence/list'; }

    get driverLicenceDeleteUrl() { return this.configurations.baseUrl + '/api/v1/driverlicence/delete'; }

    get driverLicenceCreateUrl() { return this.configurations.baseUrl + '/api/v1/driverlicence/create'; }

    get driverLicenceUpdateUrl() { return this.configurations.baseUrl + '/api/v1/driverlicence/update'; }

    constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService, commonService: CommonService) {
        super(http, authService, commonService);
      }

    getDriverLicencesEndpoint<T>(userId: string, page?: number, pageSize?: number): Observable<T> {
        const endpointUrl = page && pageSize ? `${this.driverLicenceListUrl}/${page}/${pageSize}/${userId}`
                                                    : `${this.driverLicenceListUrl}/${userId}`;

        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.getDriverLicencesEndpoint(userId, page, pageSize));
            }));
    }

    deleteDriverLicenceEndpoint<T>(driverLicenceId: string, userId: string): Observable<T> {
        const endpointUrl = `${this.driverLicenceDeleteUrl}/${driverLicenceId}/${userId}`;

        return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
          catchError(error => {
            return this.handleError(error, () => this.deleteDriverLicenceEndpoint(driverLicenceId, userId));
          }));
    }

    createDriverLicenceEndpoint<T>(userObject: any): Observable<T> {
      const endpointUrl = this.driverLicenceCreateUrl;

      return this.http.post<T>(endpointUrl, JSON.stringify(userObject), this.requestHeadersClientToken).pipe<T>(
        catchError(error => {
          return this.handleErrorFromApi(error, () => this.createDriverLicenceEndpoint(userObject));
        }));
    }

    updateDriverLicenceEndpoint<T>(userObject: any): Observable<T> {
      const endpointUrl = this.driverLicenceUpdateUrl;
      return this.http.put<T>(endpointUrl, JSON.stringify(userObject), this.requestHeaders).pipe<T>(
        catchError(error => {
          return this.handleError(error, () => this.updateDriverLicenceEndpoint(userObject));
        }));
    }
}
