import { InsuranceCompany } from "../InsuranceCompany";
import { TravelDocumentModel } from "./travel-document.model";

export class TravelInsuranceBookingRecordModel {

  constructor(id?: string,
              applicationUserId?: string,
              email?: string,
              phone?: string,

              firstname?: string,
              lastname?: string,
              middlename?: string,
              birthday?: Date,

              street? : string,
              houseNumber? : string,
              apartmentNumber? : string,
              documentCityKoatuuId? : string,
              documentCityName? : string,

              travelDocuments?:TravelDocumentModel[],

              insuranceCompany?: InsuranceCompany,
              insuranceCompanyName?: string,
              limit?: number,
              termId?: string,
              programId?: string,
              productId?: string,
              territoryId?: string,
              purposeId?: string,
              isMultiple?: boolean,
              travelerCount?: number,

              termName?:string,
              territoryName?:string,
              purposeName?:string,

              isConfirmedByClient?: boolean,
              price?: number,
              sum?: number,
              discount?: number,
              discountPercent?: number,
              paid?: boolean,

              orderId?: string,
              insuranceValidFrom?: Date,
              insuranceValidTo?: Date,

              mtibuLink?: string,
              previewFileLink?: string,
              ) {

                this.id = id;
                this.applicationUserId = applicationUserId;
                this.email = email;
                this.phone = phone;

                this.firstname = firstname;
                this.lastname = lastname;
                this.middlename = middlename;
                this.birthday = birthday;

                this.street = street;
                this.houseNumber = houseNumber;
                this.apartmentNumber = apartmentNumber;
                this.documentCityKoatuuId = documentCityKoatuuId;
                this.documentCityName = documentCityName;

                this.travelDocuments = travelDocuments;

                this.insuranceCompany = insuranceCompany;
                this.insuranceCompanyName = insuranceCompanyName;
                this.limit = limit;
                this.termId = termId;
                this.programId = programId;
                this.productId = productId;
                this.territoryId = territoryId;
                this.purposeId = purposeId;
                this.isMultiple = isMultiple;
                this.travelerCount = travelerCount;

                this.termName = termName;
                this.territoryName = territoryName;
                this.purposeName = purposeName;

                this.isConfirmedByClient = isConfirmedByClient;
                this.price = price;
                this.sum = sum;
                this.discount = discount;
                this.discountPercent = discountPercent;
                this.paid = paid;

                this.insuranceValidFrom = insuranceValidFrom;
                this.insuranceValidTo = insuranceValidTo;

                this.orderId = orderId;
                this.mtibuLink = mtibuLink;
                this.previewFileLink = previewFileLink;

  }

  public id: string;
  public applicationUserId: string;
  public email: string;
  public phone: string;

  public firstname?: string;
  public lastname?: string;
  public middlename?: string;
  public birthday?: Date;

  public street? : string;
  public houseNumber? : string;
  public apartmentNumber? : string;
  public documentCityKoatuuId? : string;
  public documentCityName? : string;

  public travelDocuments?:TravelDocumentModel[];

  public insuranceCompany: InsuranceCompany;
  public insuranceCompanyName?: string;
  public limit?:number;
  public termId?:string;
  public programId?:string;
  public productId?:string;
  public territoryId?:string;
  public purposeId?:string;
  public isMultiple?:boolean;
  public travelerCount?:number;
  public termName?:string;
  public territoryName?:string;
  public purposeName?:string;

  public isConfirmedByClient?: boolean;
  public price?: number;
  public sum?: number;
  public discount?: number;
  public discountPercent?: number;
  public paid?: boolean;

  public insuranceValidFrom?: Date;
  public insuranceValidTo?: Date;

  public orderId?:string;
  public mtibuLink?: string;
  public previewFileLink?: string;

}
