
export const environment = {
    production: true,
    baseUrl: 'https://api.sydi.finance',//'https://rproxy.sydi.lan:1293', // Change this to the address of your backend API if different from frontend address
    tokenUrl: null, // For IdentityServer/Authorization Server API. You can set to null if same as baseUrl
    loginUrl: '/login',
    googleClientId: '23528025274-68bjdgjs5jbfvilbugrk0p9fm2oe87g4.apps.googleusercontent.com',
    facebookClientId: '772362411089118',
    appleClientId: 'com.finance.sydi.appleclient',
    appleRedirectUrl: 'https://api.sydi.finance/api/AppleCallback/Web',
    configurationName: 'Production',
    currentUrl: 'https://sydi.finance',
    platonApiKey: '46XVZ7WY2X',
    platonApiPassword: 'Cw6AGX4ppcbFUbErFesX3bjP17rrhXnM',
    platonApiUrl: 'https://secure.platononline.com/payment/auth',
    
};
