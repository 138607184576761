import { ChangeDetectorRef, Component, Directive, Input, OnInit, ViewChild } from "@angular/core";
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from "@angular/router";
import { InsuranceCompany } from "src/app/models/InsuranceCompany";
import { CarInsuranceProductRequest } from "src/app/models/car-insurance/car-insurance-product-request.model";
import { CarInsuranceProductResponse } from "src/app/models/car-insurance/car-insurance-product-response.model";
import { CarTypeModel } from "src/app/models/cartype.model";
import { CarTypePropertyModel } from "src/app/models/cartypeproperty.model";
import { City } from "src/app/models/city.model";
import { fadeInOut } from "src/app/services/animations";
import { CarInsuranceService } from "src/app/services/car-insurance.service";
import { CarTypeService } from "src/app/services/cartype.service";
import { CatalogService } from "src/app/services/catalog.service";
import { ConfigurationService } from "src/app/services/configuration.service";
import { NavigationService } from "src/app/services/navigation.service";
import { CarInsuranceProduct } from "src/app/models/car-insurance-product.model";
import { CarInsuranceProperty } from "src/app/models/car-insurance-property.model";
import { CarInsurancePropertyType } from "src/app/models/CarInsurancePropertyType";
import { AccountService } from "src/app/services/account.service";
import { User } from "src/app/models/user.model";
import { Role } from "src/app/models/role.model";
import { Observable, debounceTime, distinctUntilChanged, filter, finalize, forkJoin, switchMap, tap } from "rxjs";
import { CarOrderModel } from "src/app/models/car-insurance/car-order.model";
import { NgForm, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from "@angular/material-moment-adapter";
import { dateValidator, yearValidator } from "src/app/shared/validators/equal.validator";
import { AlertService, MessageSeverity } from "src/app/services/alert.service";
import { CarBrandView } from "src/app/models/carbrandview.model";
import { CarModelView } from "src/app/models/carmodelview.model";
import { CarType } from "src/app/models/CarType";
import { CarTypeProperty } from "src/app/models/CarTypeProperty";
import { Moment } from "moment";
import { MatDatepicker } from "@angular/material/datepicker";
import moment from "moment";
import { CarInsuranceBookingRecord } from "src/app/models/car-insurance/car-insurance-booking-record.model";
import { MAT_SELECT_CONFIG } from "@angular/material/select";
import * as CryptoJS from 'crypto-js';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Buffer } from 'buffer';

@Component({
  selector: 'app-car-insurance-order',
  templateUrl: './car-insurance-order.component.html',
  styleUrls: ['./car-insurance-order.component.scss'],
  animations: [fadeInOut],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }

  ]
})

export class CarInsuranceOrderComponent implements OnInit {

  @ViewChild('orderNgForm', { static: true }) orderNgForm: NgForm;
  @ViewChild('paymentForm') paymentFormElement;

  @Input() isEditMode = true;

  public searchResult: string;
  public selectedCity: City;
  public selectedCarType: CarTypeModel;
  public selectedCarTypeProperty : CarTypePropertyModel;

  kyivCodeTE = '8000000000';
  private cityId: string;
  private carTypeId: number;
  private carTypePropertyId: number;
  public insuranceCompany: InsuranceCompany;
  private franchiseId: number;
  private directSettlement: boolean;
  private trafficAccidentMedicine: boolean;
  private auxiliaryProductId: number;

  public loadingDataSource: boolean;
  public isSaving: boolean;

  public carInsuranceProduct: CarInsuranceProduct;
  public user: User;
  public orderForm: UntypedFormGroup;

  public selectedDocument: string;

  filteredCities: City[] =
    [{id: '8000000000', town: 'М.КИЇВ', region: '',mainRegion:'', friendlyName:''},
     {id: '6310100000', town: 'ХАРКІВ' , region: '',mainRegion:'', friendlyName:''},
     {id: '1210100000', town: 'ДНІПРО' , region: '',mainRegion:'', friendlyName:''},
     {id: '4610100000', town: 'ЛЬВІВ' , region: '',mainRegion:'', friendlyName:''},
     {id: '5310100000', town: 'ПОЛТАВА' , region: '',mainRegion:'', friendlyName:''},
     {id: '0510100000', town: 'ВІННИЦЯ' , region: '',mainRegion:'', friendlyName:''},
     {id: '1410100000', town: 'ДОНЕЦЬК' , region: '',mainRegion:'', friendlyName:''},
     {id: '0110100000', town: 'СІМФЕРОПОЛЬ' , region: '',mainRegion:'', friendlyName:''},
     {id: '8500000000', town: 'М.СЕВАСТОПОЛЬ' , region: '',mainRegion:'', friendlyName:''},
    ];

  isLoadingCity = false;
  minLengthTerm = 3;
  passportSelectedCity: City = null;
  isValidSelectedCity = false;

  private optionalRegexPrefix = "^$|";

  public isLoadingMarks = false;
  carBrands: CarBrandView[];

  filteredCarBrands: CarBrandView[];
  filteredCarModels: CarModelView[];

  selectedCarBrand: CarBrandView = new CarBrandView();
  selectedCarModel: CarModelView = new CarModelView();

  public accepted:boolean = false;
  public minValidDate = moment().add(1, 'days');
  public maxManufactureYear = moment();

  public gatewayUrl:string;
  public payment:string;
  public key:string;
  public callbackUrl:string;
  public data:string;
  public req_token:string;
  public sign:string;
  public useremail:string;
  public order:string;//internal order id
  public ext1:string;//user id
  public ext2:string;//insurance company id
  public ext3:string;//insurance company name
  public ext4:string;//external order id

  constructor (
    public configurations: ConfigurationService,
    private route: ActivatedRoute,
    private catalogService: CatalogService,
    private carService: CarTypeService,
    private carInsuranceService: CarInsuranceService,
    private navigationService: NavigationService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private accountService: AccountService,
    private ref: ChangeDetectorRef,
    private alertService: AlertService,
    private http: HttpClient ) {
      this.selectedDocument = 'passport';
      this.isSaving = false;
      this.buildForm();
    }

    ngOnInit(): void {

      this.isLoadingMarks = true;

      this.route.queryParams.subscribe(params => {

        this.cityId = params['id'] || this.kyivCodeTE;
        this.carTypeId = params['carType'] || 1;
        this.carTypePropertyId = params['carTypeProperty'] || 2;

        this.insuranceCompany = params['insuranceCompany'] ? params['insuranceCompany'] as InsuranceCompany : undefined;
        this.franchiseId = params['franchiseId'] ? parseInt(params['franchiseId']) : undefined;
        this.directSettlement = params['directSettlement'] === 'true' ? true: false;
        this.trafficAccidentMedicine = params['trafficAccidentMedicine'] === 'true' ? true: false;
        this.auxiliaryProductId = params['auxiliaryProductId'] ? parseInt(params['auxiliaryProductId']) : undefined;

        this.loadData();

        this.bindSearchCity();

        this.acceptedByClient.disable();
        this.validTo.disable();
      });

    }

    ngAfterViewInit() {
      //this.alertService.showMessage('Автоцивілка', `Заявку на страхування відправлено`, MessageSeverity.success);
    }

    private buildForm() {
      let dateRegex = new RegExp("^\\d{1,2}.\\d{1,2}.\\d{4,4}$");

      let yearRegex = new RegExp("^\\d{4,4}$");

      this.selectedDocument = 'passport';

      var numberValidators = [Validators.required, Validators.pattern("^[0-9]{6,6}$")];
      if(this.selectedDocument === 'driverlicence') {
        numberValidators = [Validators.required, Validators.pattern("^[0-9]{9,9}$")];
      }

      var seriaValidators = [Validators.required, Validators.pattern("^[АБВГДЕЖЗИЙКЛМНОПРСТУФХЦЧШЩЬЮЯЇІЄҐ]{2,2}$")];
      if(this.selectedDocument === 'driverlicence') {
        seriaValidators = [Validators.required, Validators.pattern("^[АБВГДЕЖЗИЙКЛМНОПРСТУФХЦЧШЩЬЮЯЇІЄҐ]{3,3}$")];
      } else if(this.selectedDocument === 'idcard') {
        seriaValidators = null;
      }

      var taxIdValidators = [Validators.required, Validators.pattern("^[0-9]{10,10}$")];

      this.orderForm = this.formBuilder.group({
        email: [''],
        phoneNumber: ['', [Validators.required, Validators.pattern(/(?=.*\d).{12,12}/)]],
        seria: ['', seriaValidators],
        number: ['', numberValidators],
        issued: ['', [Validators.required, dateValidator(dateRegex)]],
        issuer: ['', [Validators.required,
          Validators.pattern("^[аАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ1234567890\.\,\'\/\ ]{4,200}$")]],
        firstname: ['', [Validators.required,
            Validators.pattern("^[аАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ\']{2,40}$")]],
        lastname: ['', [Validators.required,
            Validators.pattern("^[аАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ\']{2,200}$")]],
        middlename: ['', [Validators.required,
            Validators.pattern("^[аАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ\']{1,200}$")]],
        birthday: ['', [Validators.required, dateValidator(dateRegex)]],
        taxId: ['', taxIdValidators],
        searchCity:['', Validators.required],
        cityName:'',
        cityKoatuuId:'',
        street:['', [Validators.required,
          Validators.pattern("^[аАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ1234567890\.\,\'\/\ ]{2,100}$")]],
        houseNumber:['', [Validators.required,
          Validators.pattern("^[аАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ1234567890\.\,\'\/\ ]{1,20}$")]],
        apartmentNumber:['', [
          Validators.pattern(this.optionalRegexPrefix + "^[аАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ1234567890\.\,\'\/\ ]{1,20}$")]],
        make: ['',
            [Validators.required,
              Validators.pattern("^[a-zA-Z0-9аАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ\ \-]{2,100}$")]],
        model: ['',
            [Validators.required,
              Validators.pattern("^[a-zA-Z0-9аАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ\ ]{2,100}$")]],
        registrationNumber: ['',
                [Validators.required, Validators.pattern("^[A-Z0-9\ ]{6,10}$")]],
        manufactureYear: [moment(new Date()), [Validators.required, yearValidator(yearRegex)]],
        vehicleIdentificationNumber: ['',
          [Validators.required, Validators.pattern("^[a-zA-Z0-9аАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ]{17,17}$")]],
        acceptedByClient: ['', Validators.requiredTrue],
        validFrom: ['', [Validators.required, dateValidator(dateRegex)]],
        validTo: ['', [Validators.required, dateValidator(dateRegex)]]
      });
    }

    get email() { return this.orderForm.get('email'); }

    get phoneNumber() { return this.orderForm.get('phoneNumber'); }

    get seria() { return this.orderForm.get('seria'); }

    get number() { return this.orderForm.get('number'); }

    get issued() { return this.orderForm.get('issued'); }

    get issuer() { return this.orderForm.get('issuer'); }

    get firstname() { return this.orderForm.get('firstname'); }

    get lastname() { return this.orderForm.get('lastname'); }

    get middlename() { return this.orderForm.get('middlename'); }

    get birthday() { return this.orderForm.get('birthday'); }

    get taxId() { return this.orderForm.get('taxId'); }

    get searchCityControl() { return this.orderForm.get('searchCity'); }

    get cityNameControl() { return this.orderForm.get('cityName'); }

    get cityKoatuuIdControl() { return this.orderForm.get('cityKoatuuId'); }

    get street() { return this.orderForm.get('street');  }

    get houseNumber() { return this.orderForm.get('houseNumber');  }

    get apartmentNumber() { return this.orderForm.get('apartmentNumber');  }

    get make() { return this.orderForm.get('make');  }

    get model() { return this.orderForm.get('model');  }

    get registrationNumber() { return this.orderForm.get('registrationNumber');  }

    get manufactureYear() { return this.orderForm.get('manufactureYear');  }

    get vehicleIdentificationNumber() { return this.orderForm.get('vehicleIdentificationNumber');  }

    get acceptedByClient() { return this.orderForm.get('acceptedByClient');  }

    get validFrom() { return this.orderForm.get('validFrom'); }

    get validTo() { return this.orderForm.get('validTo'); }

    public casePatterns = {
      U: {
        pattern: new RegExp('[a-zA-ZаАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ]')
      }
    };

    public vincodePatterns = {
      P: {
        pattern: new RegExp('[A-Z0-9]')
      }
    };

    public vehicleNumberPatterns = {
      P: {
        pattern: new RegExp('[a-zA-Z0-9аАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ]')
      }
    };

    public taxIdPatterns = {
      P: {
        pattern: new RegExp('[0-9]')
      }
    };

    loadData()
    {
      this.loadingDataSource = true;

      let loadingCity: Observable<City> = this.catalogService.getCity(this.cityId);

      let filter = new CarInsuranceProductRequest(this.cityId,
          this.carTypeId,
          this.carTypePropertyId,
          this.insuranceCompany,
          this.franchiseId,
          this.directSettlement,
          this.trafficAccidentMedicine
        );

      let loadingProduct: Observable<CarInsuranceProductResponse> = this.carInsuranceService.getProduct(filter);

      let loadingUser: Observable<User> = this.accountService.getUser();

      var carType : CarType = this.carTypeId as CarType;
      var carTypeProperty : CarTypeProperty = this.carTypePropertyId as CarTypeProperty;

      let carBrands: Observable<CarBrandView[]> = this.catalogService.getCarBrands(carType, carTypeProperty);

      forkJoin(loadingCity, loadingProduct, loadingUser, carBrands).subscribe(results => {
        let city = results[0];
        let product = results[1];
        let user = results[2];

        this.carBrands = results[3];
        this.clearCarBrandSelection();

        this.onSelectedCityDataLoadSuccessful(city);
        this.onProductsLoadSuccessful(product);
        this.onCurrentUserDataLoadSuccessful(user, user.roles.map(r => new Role(r)));

        this.loadingDataSource = false;
        this.isLoadingMarks = false;

      },
      error => {
        console.log(error);
        this.loadingDataSource = false;
        this.isLoadingMarks = false;
      });
    }

    private onProductsLoadSuccessful(response: CarInsuranceProductResponse) {
      this.carInsuranceProduct = response.product;

      var discount = 0;

      if(this.carInsuranceProduct.discountPercent != null) {
        discount = this.carInsuranceProduct.price * this.carInsuranceProduct.discountPercent / 100;
      }

      if(this.auxiliaryProductId && this.carInsuranceProduct.auxiliaryProducts) {
        let selectedAuxiliaryProduct = this.carInsuranceProduct.auxiliaryProducts.find(x => x.id == this.auxiliaryProductId);

        if(selectedAuxiliaryProduct != null) {
          this.carInsuranceProduct.selectedAuxiliaryProduct = selectedAuxiliaryProduct;
          this.carInsuranceProduct.sum = this.carInsuranceProduct.price + selectedAuxiliaryProduct.sum - discount;
        }
      } else {
        this.carInsuranceProduct.sum = this.carInsuranceProduct.price - discount;
      }
    }

    private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {
      this.user = user;

      const emailControl = this.orderForm.controls.email;
      const phoneNumberControl = this.orderForm.controls.phoneNumber;

      emailControl.setValue(user.email);
      phoneNumberControl.setValue(user.phoneNumber);
    }

    private onSelectedCityDataLoadSuccessful(city: City) {
      this.selectedCity = city;
      this.passportSelectedCity = city;

      this.cityKoatuuIdControl.setValue(this.passportSelectedCity.id);
      this.cityNameControl.setValue(this.passportSelectedCity.town);
      this.searchCityControl.setValue(this.passportSelectedCity.town);

      let carTypes = this.carService.getCarTypes().filter(p => p.Id == this.carTypeId);
      if(carTypes.length > 0)
        this.selectedCarType = carTypes[0];

      let carTypePropertities = this.carService.getCarTypeProperties().filter(p => p.Id == this.carTypePropertyId);
      if(carTypePropertities.length > 0)
        this.selectedCarTypeProperty = carTypePropertities[0];

      let cityName = city?.town;
      if(city?.mainRegion != null)
        cityName = cityName + " ( " + city?.mainRegion +" ) ";

      this.searchResult =  cityName + " " + this.selectedCarType?.Name + " ( " + this.selectedCarTypeProperty?.Name + " )";

    }

    onDocumentChange(val: string) {
      this.selectedDocument = val;

      const seriaControl = this.orderForm.controls.seria;
      const numberControl = this.orderForm.controls.number;

      if(this.selectedDocument === 'idcard') {
        seriaControl?.disable();
      } else {
        seriaControl?.enable();
      }

      if(this.selectedDocument !== 'driverlicence' && numberControl.value != null) {
         var strshortened = numberControl.value.slice(0,6);
         numberControl.setValue(strshortened);
      }

      var numberValidators = [Validators.required, Validators.pattern("^[0-9]{6,6}$")];
      if(this.selectedDocument === 'idcard') {
        numberValidators = [Validators.required, Validators.pattern("^[0-9]{9,9}$")];
      }

      var seriaValidators = [Validators.required, Validators.pattern("^[АБВГДЕЖЗИЙКЛМНОПРСТУФХЦЧШЩЬЮЯЇІЄҐ]{2,2}$")];
      if(this.selectedDocument === 'driverlicence') {
        seriaValidators = [Validators.required, Validators.pattern("^[АБВГДЕЖЗИЙКЛМНОПРСТУФХЦЧШЩЬЮЯЇІЄҐ]{3,3}$")];
      } else if(this.selectedDocument === 'idcard') {
        seriaValidators = null;
      }

      seriaControl.setValidators(seriaValidators);
      seriaControl.updateValueAndValidity();

      numberControl.updateValueAndValidity();
      numberControl.setValidators(numberValidators);
    }

    filterBase(item: CarInsuranceProperty){

      var carInsurancePropertyType : CarInsurancePropertyType = (<any>CarInsurancePropertyType)[item.carInsurancePropertyType];

      let result = carInsurancePropertyType == CarInsurancePropertyType.Base;
      return result;
    }

    filterAdditional(item: CarInsuranceProperty){

      var carInsurancePropertyType : CarInsurancePropertyType = (<any>CarInsurancePropertyType)[item.carInsurancePropertyType];

      let result = carInsurancePropertyType == CarInsurancePropertyType.Additional;
      return result;
    }

    markGreen(item: CarInsuranceProduct)
    {
      let result = item.directSettlement === true || item.trafficAccidentMedicine === true;
      return result;
    }

    ngAfterContentChecked() {
      this.ref.detectChanges();
    }

    public validate() {

      this.orderForm.markAllAsTouched();

      if (!this.orderNgForm.submitted) {
        // Causes validation to update.
        this.orderNgForm.onSubmit(null);
      }

      if (!this.orderForm.valid) {
        return;
      }

      this.acceptedByClient.enable();
      this.isEditMode = false;
    }

    public async save() {

      if (!this.orderForm.valid) {
        return;
      }

      this.loadingDataSource = true;

      this.alertService.showMessage('Автоцивілка', `Відправка заявки на страхування`, MessageSeverity.success);

      const product = this.getCarInsuranceBookingRecord();

      this.carInsuranceService.saveProduct(product).subscribe(
        savedProduct => this.saveCompleted(savedProduct),
        error => this.saveFailed(error));

    }

    private saveCompleted(product: CarInsuranceBookingRecord) {
      this.alertService.showMessage('Автоцивілка', `Заявку на страхування відправлено`, MessageSeverity.success);

      var platonApiKey = this.configurations.platonApiKey;
      var platonApiPassword = this.configurations.platonApiPassword;
      var platonApiUrl = this.configurations.platonApiUrl;

      this.callbackUrl = this.configurations.currentBaseUrl + '/car-insurance/paid';

      this.payment = 'CC';
      this.req_token = 'Y';
      this.gatewayUrl = platonApiUrl;
      this.key = platonApiKey;

      var amount = product.sum.toFixed(2);

      const dataArray = {
        amount: amount,
        description: "SYDI.Finance, Автоцивілка від " + product.insuranceCompany,
        currency:"UAH",
        recurring:"Y"
      };

      this.data = Buffer.from(JSON.stringify(dataArray)).toString('base64');

      var valToSign = this.reverse(platonApiKey) + this.reverse(this.payment) + this.reverse(this.data)
                    + this.reverse(this.callbackUrl) + this.reverse(platonApiPassword);
      var valToSignUpper = valToSign.toUpperCase();
      this.sign = CryptoJS.MD5(valToSignUpper);

      this.useremail = product.email;
      this.order = product.id;//product id
      this.ext1 = product.applicationUserId;//user id
      this.ext2 = product.insuranceCompany.toString();
      this.ext3 = product.insuranceCompanyName;
      this.ext4 = product.orderId;//external order id
      this.ref.detectChanges();

      this.paymentFormElement.nativeElement.submit();

      //this.loadingDataSource = false;
      // this.router.navigate(
      //   ['/car-insurance/paid'],
      //   { queryParams: { order: product.id } }
      // );

    }

    private reverse(s) {
      return [...s].reverse().join("");
    }

    private saveFailed(error) {
      this.alertService.showStickyMessage(error, null, MessageSeverity.error);
      this.loadingDataSource = false;
    }

    private getCarInsuranceBookingRecord(): CarInsuranceBookingRecord {
      const formModel = this.orderForm.value;

      var discount = 0;

      if(this.carInsuranceProduct.discountPercent != null) {
          discount = this.carInsuranceProduct.price * this.carInsuranceProduct.discountPercent / 100;
      }

      var auxiliaryProductSum;
      var auxiliaryProductGuid;
      var auxiliaryProductName;
      if(this.carInsuranceProduct.selectedAuxiliaryProduct != null) {
        auxiliaryProductSum = this.carInsuranceProduct.selectedAuxiliaryProduct.sum;
        auxiliaryProductGuid = this.carInsuranceProduct.selectedAuxiliaryProduct.guid;
        auxiliaryProductName = this.carInsuranceProduct.selectedAuxiliaryProduct.name;
      }

      return {
              id: "",
              applicationUserId: this.carInsuranceService.currentUser.id,
              email: formModel.email,
              phone: formModel.phoneNumber,

              isIdCard: this.selectedDocument === 'idcard' ? true : false,
              isDriverLicence: this.selectedDocument === 'driverlicence' ? true: false,
              seria: formModel.seria,
              number: formModel.number,
              issuer: formModel.issuer,
              issued: formModel.issued,
              firstname: formModel.firstname,
              lastname: formModel.lastname,
              middlename: formModel.middlename,
              taxID: formModel.taxId,
              birthday: formModel.birthday,

              street: formModel.street,
              houseNumber: formModel.houseNumber,
              apartmentNumber: formModel.apartmentNumber,
              documentCityKoatuuId: formModel.cityKoatuuId,
              documentCityName: formModel.cityName,

              carType: this.carTypeId as CarType,
              carTypeProperty: this.carTypePropertyId as CarTypeProperty,
              cityKoatuuId: this.selectedCity.id,
              cityName: this.selectedCity.town,
              make: formModel.make,
              model: formModel.model,
              registrationNumber: formModel.registrationNumber,
              manufactureYear: formModel.manufactureYear,
              vehicleIdentificationNumber: formModel.vehicleIdentificationNumber,

              insuranceCompany: this.insuranceCompany,
              insuranceCompanyName: this.carInsuranceProduct?.insuranceCompanyName,
              //selectedAuxiliaryProduct: this.auxiliaryProductId,
              //isTaxi?: boolean,
              franchiseId: this.franchiseId,
              franchisePrice: this.franchiseId,
              directSettlement: this.directSettlement,
              trafficAccidentMedicine: this.trafficAccidentMedicine,

              carAuxiliaryProductId: this.auxiliaryProductId,
              carAuxiliaryProductGuid: auxiliaryProductGuid,
              carAuxiliaryProductName: auxiliaryProductName,
              carAuxiliaryProductSum: auxiliaryProductSum,
              carAuxiliaryProductPrice: this.auxiliaryProductId,

              isConfirmedByClient: formModel.acceptedByClient,
              price: this.carInsuranceProduct?.price,
              sum: this.carInsuranceProduct?.sum,
              discount: discount,
              discountPercent: this.carInsuranceProduct.discountPercent,
              //paid?: boolean,

              insuranceValidFrom: formModel.validFrom,
              insuranceValidTo: formModel.validTo
      };
    }

    public back() {

      setTimeout(() => {
        this.acceptedByClient.disable();
        this.isEditMode = true;
      });

    }

    get floatLabels(): string {
      return this.isEditMode ? 'auto' : 'always';
    }

    bindSearchCity() {
      this.searchCityControl.valueChanges
      .pipe(
        filter(res => {
          return res !== null && res.length >= this.minLengthTerm
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.filteredCities = [];
          this.isLoadingCity = true;
        }),
        switchMap(value => this.catalogService.getCities(value)
          .pipe(
            finalize(() => {
              this.isLoadingCity = false
            }),
          )
        )
      )
      .subscribe((data: any) => {
        this.filteredCities = data;

        //console.log(this.filteredCities);
      });

      this.make.valueChanges
      .subscribe(selectedValue => {

        let searchValue = selectedValue;

        if(searchValue !== null && searchValue.length >= 1) {
          this.filteredCarBrands = this.carBrands.filter(x => x.name?.toLowerCase().includes(searchValue?.toLowerCase()));
        } else {
          this.filteredCarBrands = this.carBrands;
        }

        console.log(this.filteredCarBrands);
      });

    this.model.valueChanges
      .subscribe(selectedValue => {

        let searchValue = selectedValue;

        let make = this.make.value;
        let makeId = this.carBrands?.findIndex(x => x.name === make);
        if(makeId >= 0) {
          if(searchValue !== null && searchValue.length >= 1) {
            this.filteredCarModels = this.carBrands[makeId].carModels?.filter(x => x.name?.toLowerCase().includes(searchValue?.toLowerCase()));
          } else {
            this.filteredCarModels = this.carBrands[makeId].carModels;
          }
        }
        console.log(this.filteredCarModels);
      });
    }

    public clearCitySelection() {
      this.passportSelectedCity = null;
      this.cityKoatuuIdControl.setValue('');
      this.cityNameControl.setValue('');
      this.searchCityControl.setValue('');
      this.filteredCities = [];
    }

    onSelected(value: City) {

      this.passportSelectedCity = value;

      this.cityKoatuuIdControl.setValue(this.passportSelectedCity?.id);
      this.cityNameControl.setValue(this.passportSelectedCity?.town);
      this.searchCityControl.setValue(this.passportSelectedCity?.town);

      if(value)
          this.isValidSelectedCity = true;
      else
          this.isValidSelectedCity = false;

    }

    displayWith(value: City) {
      let town = typeof value === 'string' ? value : value?.town;
      //console.log("displayWith " + town);
      return town;
    }


    public onSelectedCarBrand() {
      console.log(this.selectedCarBrand);
      this.selectedCarBrand = this.selectedCarBrand;
      this.make.setValue(this.selectedCarBrand.name);

      let make = this.make.value;

      this.selectedCarModel = null;
      this.model.setValue('');

      let makeId = this.carBrands?.findIndex(x => x.name === make);
      if(makeId >= 0) {
        this.filteredCarModels = this.carBrands[makeId].carModels;
      }
    }

    public onSelectedCarModel() {
      console.log(this.selectedCarModel);
      this.selectedCarModel = this.selectedCarModel;
      this.model.setValue(this.selectedCarModel.name);
    }

    public clearCarBrandSelection() {
      this.selectedCarBrand = null;
      this.selectedCarModel = null;
      this.make.setValue('');
      this.model.setValue('');
      this.filteredCarBrands = [];
      this.filteredCarModels = [];
    }

    public clearCarModelSelection() {
      this.selectedCarModel = null;
      this.model.setValue('');
      this.filteredCarModels = [];
    }

    displayCarBrandWith(value: CarBrandView) {
      let name = typeof value === 'string' ? value : value?.name;
      return name;
    }

    displayCarModelWith(value: CarModelView) {
      let name = typeof value === 'string' ? value : value?.name;
      return name;
    }

    public chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
      const ctrlValue = this.manufactureYear.value;
      ctrlValue.year(normalizedYear.year());
      this.manufactureYear.setValue(ctrlValue);
      datepicker.close();
    }

    public changeValidFrom(event) {
      let value = event.value;
      console.log('changeValidFrom', value);

      var nextYear = moment(value);
      nextYear = nextYear.add(1, 'years').add(-1, 'days');

      console.log('changeValidFrom 1', nextYear);

      this.validTo.setValue(nextYear);

    }
}
