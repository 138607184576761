
import { CarType } from "../CarType";
import { CarTypeProperty } from "../CarTypeProperty";
import { InsuranceCompany } from "../InsuranceCompany";


export class CarInsuranceBookingRecord {

  constructor(id?: string,
              applicationUserId?: string,
              email?: string,
              phone?: string,

              isIdCard?: boolean,
              isDriverLicence?: boolean,
              seria?: string,
              number?: string,
              issuer?: string,
              issued?: Date,
              firstname?: string,
              lastname?: string,
              middlename?: string,
              taxID?: string,
              birthday?: Date,

              street? : string,
              houseNumber? : string,
              apartmentNumber? : string,
              documentCityKoatuuId? : string,
              documentCityName? : string,

              carType?: CarType,
              carTypeProperty?: CarTypeProperty,
              cityKoatuuId?: string,
              cityName?: string,
              make?: string,
              model?: string,
              registrationNumber?: string,
              manufactureYear?: Date,
              vehicleIdentificationNumber?: string,

              insuranceCompany?: InsuranceCompany,
              insuranceCompanyName?: string,
              selectedAuxiliaryProduct?: string,
              isTaxi?: boolean,
              franchiseId?: number,
              franchisePrice?: number,
              directSettlement?: boolean,
              trafficAccidentMedicine?: boolean,

              carAuxiliaryProductId?: number,
              carAuxiliaryProductGuid?: string,
              carAuxiliaryProductName?: string,
              carAuxiliaryProductSum?: number,
              carAuxiliaryProductPrice?: number,

              isConfirmedByClient?: boolean,
              price?: number,
              sum?: number,
              discount?: number,
              discountPercent?: number,
              paid?: boolean,

              insuranceValidFrom?: Date,
              insuranceValidTo?: Date,

              mtibuLink?: string,
              previewFileLink?: string,
              orderId?:string
              ) {

                this.id = id;
                this.applicationUserId = applicationUserId;
                this.email = email;
                this.phone = phone;

                this.isIdCard = isIdCard;
                this.isDriverLicence = isDriverLicence;
                this.seria = seria;
                this.number = number;
                this.issuer = issuer;
                this.issued = issued;
                this.firstname = firstname;
                this.lastname = lastname;
                this.middlename = middlename;
                this.taxID = taxID;
                this.birthday = birthday;

                this.street = street;
                this.houseNumber = houseNumber;
                this.apartmentNumber = apartmentNumber;
                this.documentCityKoatuuId = documentCityKoatuuId;
                this.documentCityName = documentCityName;

                this.carType = carType;
                this.carTypeProperty = carTypeProperty;
                this.cityKoatuuId = cityKoatuuId;
                this.cityName = cityName;
                this.make = make;
                this.model = model;
                this.registrationNumber = registrationNumber;
                this.manufactureYear = manufactureYear;
                this.vehicleIdentificationNumber = vehicleIdentificationNumber;

                this.insuranceCompany = insuranceCompany;
                this.insuranceCompanyName = insuranceCompanyName;
                this.selectedAuxiliaryProduct = selectedAuxiliaryProduct;
                this.isTaxi = isTaxi;
                this.franchiseId = franchiseId;
                this.franchisePrice = franchisePrice;
                this.directSettlement = directSettlement;
                this.trafficAccidentMedicine = trafficAccidentMedicine;

                this.carAuxiliaryProductId = carAuxiliaryProductId;
                this.carAuxiliaryProductGuid = carAuxiliaryProductGuid;
                this.carAuxiliaryProductName = carAuxiliaryProductName;
                this.carAuxiliaryProductSum = carAuxiliaryProductSum;
                this.carAuxiliaryProductPrice = carAuxiliaryProductPrice;

                this.isConfirmedByClient = isConfirmedByClient;
                this.price = price;
                this.sum = sum;
                this.discount = discount;
                this.discountPercent = discountPercent;
                this.paid = paid;

                this.insuranceValidFrom = insuranceValidFrom;
                this.insuranceValidTo = insuranceValidTo;

                this.mtibuLink = mtibuLink;
                this.previewFileLink = previewFileLink;
                this.orderId = orderId;

  }

  public id: string;
  public applicationUserId: string;
  public email: string;
  public phone: string;

  public isIdCard?: boolean;
  public isDriverLicence?: boolean;
  public seria?: string;
  public number?: string;
  public issuer?: string;
  public issued?: Date;
  public firstname?: string;
  public lastname?: string;
  public middlename?: string;
  public taxID?: string;
  public birthday?: Date;

  public street? : string;
  public houseNumber? : string;
  public apartmentNumber? : string;
  public documentCityKoatuuId? : string;
  public documentCityName? : string;

  public carType?: CarType;
  public carTypeProperty?: CarTypeProperty;
  public cityKoatuuId?: string;
  public cityName?: string;
  public make?: string;
  public model?: string;
  public registrationNumber?: string;
  public manufactureYear?: Date;
  public vehicleIdentificationNumber?: string;

  public insuranceCompany: InsuranceCompany;
  public insuranceCompanyName?: string;
  public selectedAuxiliaryProduct?: string;
  public isTaxi?: boolean;
  public franchiseId?: number;
  public franchisePrice?: number;
  public directSettlement?: boolean;
  public trafficAccidentMedicine?: boolean;

  public carAuxiliaryProductId?: number;
  public carAuxiliaryProductGuid?: string;
  public carAuxiliaryProductName?: string;
  public carAuxiliaryProductSum?: number;
  public carAuxiliaryProductPrice?: number;

  public isConfirmedByClient?: boolean;
  public price?: number;
  public sum?: number;
  public discount?: number;
  public discountPercent?: number;
  public paid?: boolean;

  public insuranceValidFrom?: Date;
  public insuranceValidTo?: Date;

  public orderId?:string;
  public mtibuLink?: string;
  public previewFileLink?: string;

}
