<h1 mat-dialog-title>{{'todoDemo.editor.NewTask' | translate}}</h1>
<div mat-dialog-content>

  <form #form="ngForm" [formGroup]="taskForm" novalidate (ngSubmit)="save()" fxLayout="column" fxLayoutGap="1em" autocomplete="off">

    <mat-form-field fxFlex>
      <mat-label>{{'todoDemo.editor.Name' | translate}}</mat-label>
      <input matInput formControlName="taskName" />
      <mat-error *ngIf="taskName.hasError('required')">
        {{'todoDemo.editor.TaskNameRequired' | translate}}
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex>
      <mat-label>{{'todoDemo.editor.Description' | translate}}</mat-label>
      <input matInput formControlName="description" />
    </mat-form-field>

    <mat-checkbox formControlName="isImportant">{{'todoDemo.editor.Important' | translate}}</mat-checkbox>

    <!-- https://github.com/angular/material2/issues/8798 -->
    <div>&nbsp;</div>

  </form>

</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="form.onSubmit(null)" color="primary">Save</button>
  <button mat-raised-button (click)="cancel()" color="warn">Cancel</button>
</div>
