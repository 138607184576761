import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})

export class CommonService {

  public isRefreshingLogin: boolean;
  public isRefreshingClientToken: boolean;

  constructor() {

  }
}
