import { Component, OnInit, AfterViewInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';

import { fadeInOut } from '../../services/animations';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { AccountService } from '../../services/account.service';
import { RegistrationCertificateService } from '../../services/registration-certificate.service';
import { Utilities } from '../../services/utilities';
import { RegistrationCertificate } from '../../models/registration-certificate.model';
import { NavigationService } from 'src/app/services/navigation.service';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { debounceTime, tap, switchMap, finalize, distinctUntilChanged, filter } from 'rxjs/operators';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment, Moment} from 'moment';
import { CarTypeModel } from 'src/app/models/cartype.model';
import { CarTypePropertyModel } from 'src/app/models/cartypeproperty.model';
import { CarTypeService } from 'src/app/services/cartype.service';
import { CatalogService } from 'src/app/services/catalog.service';
import { CarType } from 'src/app/models/CarType';
import { CarTypeProperty } from 'src/app/models/CarTypeProperty';
import { CarBrandView } from 'src/app/models/carbrandview.model';
import { CarModelView } from 'src/app/models/carmodelview.model';
import { City } from 'src/app/models/city.model';

const moment = _rollupMoment || _moment;


@Component({
  selector: 'registration-certificate-list',
  templateUrl: './registration-certificate-list.component.html',
  styleUrls: ['./registration-certificate-list.component.scss'],
  animations: [fadeInOut],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
  ],
})

export class RegistrationCertificateListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild('registrationCertificateNgForm', { static: true }) registrationCertificateNgForm: NgForm;

  displayedColumns = ['registrationNumber', 'make', 'model', 'actions'];
  dataSource: MatTableDataSource<RegistrationCertificate>;
  sourceRegistrationCertificate: RegistrationCertificate;
  loadingIndicator: boolean;

  public isSaving = false;
  public isLoadingMarks = false;

  isNewRegistrationCertificate = false;
  @Input() registrationCertificate: RegistrationCertificate = new RegistrationCertificate();
  @Input() isEditMode = false;

  registrationCertificateForm: UntypedFormGroup;
  private onRegistrationCertificateSaved = new Subject<RegistrationCertificate>();
  registrationCertificateSaved$ = this.onRegistrationCertificateSaved.asObservable();

  descriptionCarType: string;

  selectedCarType: CarTypeModel = new CarTypeModel();
  selectedCarTypeProperty: CarTypePropertyModel = new CarTypePropertyModel();

  carTypes: CarTypeModel[];
  carTypePropertities: CarTypePropertyModel[];
  carBrands: CarBrandView[];

  filteredCarBrands: CarBrandView[];
  filteredCarModels: CarModelView[];

  selectedCarBrand: CarBrandView = new CarBrandView();
  selectedCarModel: CarModelView = new CarModelView();

  defaultFilteredCities: City[] =
    [{id: '8000000000', town: 'М.КИЇВ', region: '', mainRegion:'', friendlyName:'' },
     {id: '6310100000', town: 'ХАРКІВ', region: '', mainRegion:'', friendlyName:'' },
     {id: '1210100000', town: 'ДНІПРО', region: '', mainRegion:'', friendlyName:'' },
     {id: '4610100000', town: 'ЛЬВІВ', region: '', mainRegion:'', friendlyName:'' },
     {id: '5310100000', town: 'ПОЛТАВА', region: '', mainRegion:'', friendlyName:'' },
     {id: '0510100000', town: 'ВІННИЦЯ', region: '', mainRegion:'', friendlyName:'' },
     {id: '1410100000', town: 'ДОНЕЦЬК', region: '', mainRegion:'', friendlyName:'' },
     {id: '0110100000', town: 'СІМФЕРОПОЛЬ', region: '', mainRegion:'', friendlyName:'' },
     {id: '8500000000', town: 'М.СЕВАСТОПОЛЬ', region: '', mainRegion:'', friendlyName:'' },
    ];

    filteredCities: City[] = this.defaultFilteredCities;

  isLoadingCity = false;
  minLengthCity = 3;
  selectedCity: City = null;
  isValidSelectedCity = false;

  constructor(
    private translationService: AppTranslationService,
    private accountService: AccountService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private registrationCertificateService: RegistrationCertificateService,
    private carTypeService: CarTypeService,
    private catalogService: CatalogService,
    private formBuilder: UntypedFormBuilder,
    private navigationService: NavigationService) {

    this.buildForm();

    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.loadData();

    this.carTypes = this.carTypeService.getCarTypes();
    if(this.carTypes.length > 0)
      this.selectedCarType = this.carTypes[0];

    this.carTypePropertities = this.carTypeService.getCarTypeProperties().filter((item) => item.CarTypeId == this.selectedCarType.Id);
    if(this.carTypePropertities.length > 0)
      this.selectedCarTypeProperty = this.carTypePropertities[0];

    this.descriptionCarType = this.selectedCarType.Description;
    this.carTypeId.setValue(this.selectedCarType.Id);
    this.carTypePropertyId.setValue(this.selectedCarTypeProperty.Id);

    this.searchCity.valueChanges
      .pipe(
        filter(res => {
          return res !== null && res.length >= this.minLengthCity
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.filteredCities = [];
          this.isLoadingCity = true;
        }),
        switchMap(value => this.catalogService.getCities(value)
          .pipe(
            finalize(() => {
              this.isLoadingCity = false
            }),
          )
        )
      )
      .subscribe((data: any) => {
        this.filteredCities = data;

        console.log(this.filteredCities);
      });
  }

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = this.translationService.getTranslation('PAGINATOR.ITEMS_PER_PAGE');
    this.paginator._intl.getRangeLabel = this.sydiRangeLabel;

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }

  ngOnDestroy() {
    this.onRegistrationCertificateSaved.unsubscribe();
  }

  isString(value) {
    return typeof value === 'string' || value instanceof String;
  }

  get registrationNumber() { return this.registrationCertificateForm.get('registrationNumber');  }

  get make() { return this.registrationCertificateForm.get('make');  }

  get model() { return this.registrationCertificateForm.get('model');  }

  get manufactureYear() { return this.registrationCertificateForm.get('manufactureYear');  }

  get carTypeId() { return this.registrationCertificateForm.get('carTypeId');  }

  get carTypePropertyId() { return this.registrationCertificateForm.get('carTypePropertyId');  }

  get vehicleIdentificationNumber() { return this.registrationCertificateForm.get('vehicleIdentificationNumber');  }

  get searchCity() { return this.registrationCertificateForm.get('searchCity'); }

  get cityName() { return this.registrationCertificateForm.get('cityName'); }

  get cityKoatuuId() { return this.registrationCertificateForm.get('cityKoatuuId'); }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  public confirmDelete(registrationCertificate: RegistrationCertificate) {

    let message = this.translationService.getTranslation('registration-certificates.editor.DeleteMessage',
           {make : registrationCertificate.make, model : registrationCertificate.model});
    this.snackBar.open( `${message}`,
                        this.translationService.getTranslation('registration-certificates.editor.Yes'),
                        { duration: 5000 })
      .onAction().subscribe(() => {
        this.loadingIndicator = true;

        this.registrationCertificateService.deleteRegistrationCertificate(registrationCertificate.id)
          .subscribe(results => {
            this.loadingIndicator = false;

            this.dataSource.data = this.dataSource.data.filter((u) => u.id !== registrationCertificate.id);
          },
            error => {
              this.loadingIndicator = false;
            });
      });
  }

  public editRegistrationCertificate(registrationCertificate?: RegistrationCertificate) {
    this.isEditMode = true;

    if (!registrationCertificate) {
      this.isNewRegistrationCertificate = true;
      this.registrationCertificate = new RegistrationCertificate();
      this.registrationCertificate.manufactureYear = new Date();
      this.registrationCertificate.carTypeId = 1;
      this.registrationCertificate.carTypePropertyId = 1;
      this.selectedCity = new City();
      this.searchCity.setValue('');
    }
    else
    {
      this.isNewRegistrationCertificate = false;
      this.registrationCertificate = registrationCertificate;
      this.selectedCity = new City(registrationCertificate.cityKoatuuId, registrationCertificate.cityName);
      this.isValidSelectedCity = true;
      this.searchCity.setValue(this.registrationCertificate.cityName);
    }

    this.carTypeId.setValue(this.registrationCertificate.carTypeId);
    this.carTypePropertyId.setValue(this.registrationCertificate.carTypePropertyId);
    this.buildForm();
    this.onCarTypeChange(this.carTypeId);
    this.onCarTypePropertyChange(this.carTypePropertyId);

    this.make.valueChanges
      .subscribe(selectedValue => {

        let searchValue = selectedValue;

        if(searchValue !== null && searchValue.length >= 1) {
          this.filteredCarBrands = this.carBrands.filter(x => x.name?.toLowerCase().includes(searchValue?.toLowerCase()));
        } else {
          this.filteredCarBrands = this.carBrands;
        }

        console.log(this.filteredCarBrands);
      });

    this.model.valueChanges
      .subscribe(selectedValue => {

        let searchValue = selectedValue;

        let make = this.make.value;
        let makeId = this.carBrands?.findIndex(x => x.name === make);
        if(makeId >= 0) {
          if(searchValue !== null && searchValue.length >= 1) {
            this.filteredCarModels = this.carBrands[makeId].carModels?.filter(x => x.name?.toLowerCase().includes(searchValue?.toLowerCase()));
          } else {
            this.filteredCarModels = this.carBrands[makeId].carModels;
          }
        }
        console.log(this.filteredCarModels);
      });

      this.searchCity.valueChanges
      .pipe(
        filter(res => {
          return res !== null && res.length >= this.minLengthCity
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.filteredCities = [];
          this.isLoadingCity = true;
        }),
        switchMap(value => this.catalogService.getCities(value)
          .pipe(
            finalize(() => {
              this.isLoadingCity = false
            }),
          )
        )
      )
      .subscribe((data: any) => {
        this.filteredCities = data;

        console.log(this.filteredCities);
      });
  }

  public save() {

    if (!this.registrationCertificateNgForm.submitted) {
      // Causes validation to update.
      this.registrationCertificateNgForm.onSubmit(null);
      return;
    }

    if (!this.registrationCertificateForm.valid) {
      return;
    }

    this.isSaving = true;

    const editedRegistrationCertificate = this.getEditedRegistrationCertificate();

    if (this.isNewRegistrationCertificate) {
      this.registrationCertificateService.newRegistrationCertificate(editedRegistrationCertificate).subscribe(
        registrationCertificate => this.newSaveCompleted(registrationCertificate),
        error => this.saveFailed(error));
    } else {
      this.registrationCertificateService.updateRegistrationCertificate(editedRegistrationCertificate).subscribe(
        registrationCertificate => this.saveCompleted(registrationCertificate),
        error => this.saveFailed(error));
    }
  }

  public cancel() {
    this.registrationCertificate = new RegistrationCertificate();
    this.resetForm(true);
  }


   private newSaveCompleted(registrationCertificate?: RegistrationCertificate) {

    this.dataSource.data = [registrationCertificate, ...this.dataSource.data];  //refresh the dataSource

    if (registrationCertificate) {
      this.registrationCertificate = registrationCertificate;
    }

    this.isSaving = false;
    this.isEditMode = false;

    this.resetForm(true);

    this.onRegistrationCertificateSaved.next(this.registrationCertificate);
  }

  private saveCompleted(registrationCertificate?: RegistrationCertificate) {

    this.dataSource.data = this.dataSource.data.filter((item, key)=>{
      if(item.id == registrationCertificate.id)
      {
        item.make = registrationCertificate.make;
        item.model = registrationCertificate.model;
        item.registrationNumber = registrationCertificate.registrationNumber;
        item.manufactureYear = registrationCertificate.manufactureYear;
        item.carTypeId = registrationCertificate.carTypeId;
        item.carTypePropertyId = registrationCertificate.carTypePropertyId;
        item.vehicleIdentificationNumber = registrationCertificate.vehicleIdentificationNumber;
        item.cityName = registrationCertificate.cityName;
        item.cityKoatuuId = registrationCertificate.cityKoatuuId;
      }
      return true;
    });

    if (registrationCertificate) {
      this.registrationCertificate = registrationCertificate;
    }

    this.isSaving = false;
    this.isEditMode = false;

    this.resetForm(true);

    this.onRegistrationCertificateSaved.next(this.registrationCertificate);
  }

  private saveFailed(error: any) {
    this.isSaving = false;
    this.isEditMode = false;
  }

  private buildForm() {

    this.registrationCertificateForm = this.formBuilder.group({
      make: [this.registrationCertificate.make,
        [Validators.required,
          Validators.pattern("^[a-zA-Z0-9аАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ\ \-]{2,100}$")]],
      model: [this.registrationCertificate.model,
        [Validators.required,
          Validators.pattern("^[a-zA-Z0-9аАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ\ ]{2,100}$")]],
      registrationNumber: [this.registrationCertificate.registrationNumber,
        [Validators.required,
          Validators.pattern("^[A-Z0-9\ ]{6,10}$")]],
      manufactureYear: [moment(this.registrationCertificate.manufactureYear),
          Validators.required],
      carTypeId: this.registrationCertificate.carTypeId,
      carTypePropertyId: this.registrationCertificate.carTypePropertyId,
      vehicleIdentificationNumber: [this.registrationCertificate.vehicleIdentificationNumber,
        [Validators.required,
          Validators.pattern("^[a-zA-Z0-9аАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ]{17,17}$")]],
      searchCity:[this.registrationCertificate.cityName,
          Validators.required],
      cityName: this.registrationCertificate.cityName,
      cityKoatuuId: this.registrationCertificate.cityKoatuuId
    });
  }

  public resetForm(stopEditing = false) {
    if (stopEditing) {
      this.isEditMode = false;
    }

    this.resetRegistrationCertificateForm();
  }

  public chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.manufactureYear.value;
    ctrlValue.year(normalizedYear.year());
    this.manufactureYear.setValue(ctrlValue);
    datepicker.close();
  }

  public onCarTypeChange(carTypeId)
  {
    this.selectedCarType = this.carTypes[carTypeId.value - 1];
    this.descriptionCarType = this.selectedCarType.Description;

    this.carTypePropertities = this.carTypeService.getCarTypeProperties().filter((item) => item.CarTypeId == carTypeId.value);
  }

  public onCarTypePropertyChange(carTypePropertyId)
  {
    if(this.carTypePropertities.length > 0)
    {
      let props = this.carTypePropertities.filter((item) => item.Id == carTypePropertyId.value);
      this.selectedCarTypeProperty = props[0];

      this.loadCarBrands(this.selectedCarType.Id, this.selectedCarTypeProperty.Id);
    }
  }

  public onSelectedCarBrand() {
    console.log(this.selectedCarBrand);
    this.selectedCarBrand = this.selectedCarBrand;
    this.make.setValue(this.selectedCarBrand.name);

    let make = this.make.value;

    this.selectedCarModel = null;
    this.model.setValue('');

    let makeId = this.carBrands?.findIndex(x => x.name === make);
    if(makeId >= 0) {
      this.filteredCarModels = this.carBrands[makeId].carModels;
    }
  }

  public onSelectedCarModel() {
    console.log(this.selectedCarModel);
    this.selectedCarModel = this.selectedCarModel;
    this.model.setValue(this.selectedCarModel.name);
  }

  public clearCarBrandSelection() {
    this.selectedCarBrand = null;
    this.selectedCarModel = null;
    this.make.setValue('');
    this.model.setValue('');
    this.filteredCarBrands = [];
    this.filteredCarModels = [];
  }

  public clearCarModelSelection() {
    this.selectedCarModel = null;
    this.model.setValue('');
    this.filteredCarModels = [];
  }

  displayCarBrandWith(value: CarBrandView) {
    return value?.name;
  }

  displayCarModelWith(value: CarModelView) {
    return value?.name;
  }

  onSelectedCity() {
    console.log(this.selectedCity);
    this.selectedCity = this.selectedCity;

    this.cityKoatuuId.setValue(this.selectedCity.id);
    this.cityName.setValue(this.selectedCity.town);
    this.searchCity.setValue(this.selectedCity.town);

    if(this.selectedCity != null)
        this.isValidSelectedCity = true;
    else
        this.isValidSelectedCity = false;
  }

  public clearCitySelection() {
    this.selectedCity = null;
    this.cityKoatuuId.setValue('');
    this.cityName.setValue('');
    this.searchCity.setValue('');
    this.filteredCities = [];
  }

  private loadCarBrands(carType?: CarType, carTypeProperty?: CarTypeProperty) {
    this.isLoadingMarks = true;

    this.catalogService.getCarBrands(carType, carTypeProperty)
      .subscribe(results => {
        this.isLoadingMarks = false;
        this.carBrands = results;
        this.filteredCarBrands = [];
        this.filteredCarModels = [];
      },
      error => {
        this.isLoadingMarks = false;
      });
  }

  private resetRegistrationCertificateForm()
  {
    this.registrationCertificateForm.reset({
      make: this.registrationCertificate.make || '',
      model: this.registrationCertificate.model || '',
      manufactureYear: this.registrationCertificate.manufactureYear || 2022,
      registrationNumber: this.registrationCertificate.registrationNumber || '',
      carTypeId: this.registrationCertificate.carTypeId || 1,
      carTypePropertyId: this.registrationCertificate.carTypePropertyId || 1,
      vehicleIdentificationNumber: this.registrationCertificate.vehicleIdentificationNumber || '',
      cityKoatuuId: this.registrationCertificate.cityKoatuuId || '',
      cityName: this.registrationCertificate.cityName || '',
      searchCity: this.registrationCertificate.cityName || ''
    });
  }


  private getEditedRegistrationCertificate(): RegistrationCertificate {
    const formModel = this.registrationCertificateForm.value;

    return {
      id: this.registrationCertificate.id,
      make: formModel.make,
      model: formModel.model,
      manufactureYear: formModel.manufactureYear,
      registrationNumber: formModel.registrationNumber,

      carTypeId: formModel.carTypeId,
      carTypePropertyId: formModel.carTypePropertyId,
      vehicleIdentificationNumber: formModel.vehicleIdentificationNumber,
      cityKoatuuId: formModel.cityKoatuuId,
      cityName: formModel.cityName,

      applicationUserId: this.registrationCertificateService.currentUser.id,

      dateOfFirstRegistration: this.registrationCertificate.dateOfFirstRegistration,
      dateOfRegistration: this.registrationCertificate.dateOfRegistration,
      surnameOrCompany: this.registrationCertificate.surnameOrCompany,
      givenName: this.registrationCertificate.givenName,
      address: this.registrationCertificate.address,
      mainRegion: this.registrationCertificate.mainRegion,
      region: this.registrationCertificate.region,
      town: this.registrationCertificate.town,
      ownership: this.registrationCertificate.ownership,
      validity: this.registrationCertificate.validity,
      issuer: this.registrationCertificate.issuer,
      commercialDescription: this.registrationCertificate.commercialDescription,
      maximumMass: this.registrationCertificate.maximumMass,
      massVehicleInService: this.registrationCertificate.massVehicleInService,
      vehicleCategory: this.registrationCertificate.vehicleCategory,
      engineVolume: this.registrationCertificate.engineVolume,
      fuelType: this.registrationCertificate.fuelType,
      color: this.registrationCertificate.color,
      numberOfSeats: this.registrationCertificate.numberOfSeats,
      numberOfStandingPlaces: this.registrationCertificate.numberOfStandingPlaces,
      specialNotes: this.registrationCertificate.specialNotes,

      createdBy: this.registrationCertificate.createdBy,
      createdDate: this.registrationCertificate.createdDate,
      updatedBy: this.registrationCertificate.updatedBy,
      updatedDate: this.registrationCertificate.updatedDate
    };
  }

  private sydiRangeLabel = (page: number, pageSize: number, length: number) => {

    let ofTranlated = this.translationService.getTranslation('PAGINATOR.OF');
    if (length == 0 || pageSize == 0) { return `0 ${ofTranlated} ${length}`; }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${ofTranlated} ${length}`;
  }

  private refresh() {
    // Causes the filter to refresh there by updating with recently added data.
    this.applyFilter(this.dataSource.filter);
  }

  get floatLabels(): string {
    return this.isEditMode ? 'auto' : 'always';
  }

  private loadData() {
    this.loadingIndicator = true;

    this.registrationCertificateService.getRegistrationCertificates()
      .subscribe(results => {
        this.loadingIndicator = false;
        this.dataSource.data = results;
      },
        error => {
          this.loadingIndicator = false;
        });
  }

}
