import { HttpClient } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { ConfigurationService } from "./configuration.service";
import { EndpointBase } from "./endpoint-base.service";
import { CommonService } from "./common.service";
import { Injectable } from "@angular/core";
import { Observable, catchError } from "rxjs";

@Injectable()
export class TravelEndpoint extends EndpointBase {

    get travelFilterUrl() { return this.configurations.baseUrl + '/api/v1/TravelInsurance/filter'; }

    get travelProductsUrl() { return this.configurations.baseUrl + '/api/v1/TravelInsurance/Products'; }

    get travelProductUrl() { return this.configurations.baseUrl + '/api/v1/TravelInsurance/Product'; }

    get productCreateUrl() { return this.configurations.baseUrl + '/api/v1/TravelInsurance/create'; }

    get releaseTravelInsuranceUrl() { return this.configurations.baseUrl + '/api/v1/TravelInsurance/release'; }

    get downloadTravelInsurancePdfUrl() { return this.configurations.baseUrl + '/api/v1/TravelInsurance/downloadPdf'; }

    get getTravelInsuranceUrl() { return this.configurations.baseUrl + '/api/v1/TravelInsurance/getById'; }

    get getTravelInsurancesUrl() { return this.configurations.baseUrl + '/api/v1/TravelInsurance/list'; }

    constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService, commonService: CommonService) {
        super(http, authService, commonService);
      }


    getFilterEndpoint<T>(): Observable<T> {
        const endpointUrl = `${this.travelFilterUrl}`;

        return this.http.get<T>(endpointUrl, this.requestHeadersClientToken).pipe<T>(
            catchError(error => {
                return this.handleErrorFromApi(error, () => this.getFilterEndpoint());
            }));
    }

    getProductsEndpoint<T>(model: any): Observable<T> {
      const endpointUrl = `${this.travelProductsUrl}`;

      var jsonModel = JSON.stringify(model);
      return this.http.post<T>(endpointUrl, jsonModel , this.requestHeadersClientToken).pipe<T>(
          catchError(error => {
              return this.handleErrorFromApi(error, () => this.getProductsEndpoint(model));
          }));
    }

    getProductEndpoint<T>(model: any): Observable<T> {
      const endpointUrl = `${this.travelProductUrl}`;

      var jsonModel = JSON.stringify(model);
      return this.http.post<T>(endpointUrl, jsonModel , this.requestHeaders).pipe<T>(
          catchError(error => {
              return this.handleError(error, () => this.getProductEndpoint(model));
          }));
    }

    createProductEndpoint<T>(userObject: any): Observable<T> {
      const endpointUrl = this.productCreateUrl;

      return this.http.post<T>(endpointUrl, JSON.stringify(userObject), this.requestHeaders).pipe<T>(
        catchError(error => {
          return this.handleError(error, () => this.createProductEndpoint(userObject));
        }));
    }

    releaseTravelInsuranceEndpoint<T>(userObject: any): Observable<T> {
      const endpointUrl = this.releaseTravelInsuranceUrl;
      return this.http.put<T>(endpointUrl, JSON.stringify(userObject), this.requestHeaders).pipe<T>(
        catchError(error => {
          return this.handleError(error, () => this.releaseTravelInsuranceEndpoint(userObject));
        }));
    }

    downloadPdfEndpoint(id?: string): Observable<ArrayBuffer> {
      const endpointUrl = `${this.downloadTravelInsurancePdfUrl}/${id}`;

      return this.http.get(endpointUrl, { headers: this.requestOnlyHeaders, responseType: 'arraybuffer' }).pipe(
        catchError(error => {
          return this.handleError(error, () => this.downloadPdfEndpoint(id));
        }));
    }

    getInsuranceEndpoint<T>(id: string): Observable<T> {

      const endpointUrl = `${this.getTravelInsuranceUrl}/${id}`;

      return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
        catchError(error => {
          return this.handleError(error, () => this.getInsuranceEndpoint(id));
        }));
    }

    getInsurancesEndpoint<T>(): Observable<T> {

      const endpointUrl = `${this.getTravelInsurancesUrl}`;

      return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
        catchError(error => {
          return this.handleError(error, () => this.getInsurancesEndpoint());
        }));
    }
}
