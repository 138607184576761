import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { fromEvent, Observable, Subject } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { LocalStoreManager } from './local-store-manager.service';
import { OidcHelperService } from './oidc-helper.service';
import { ConfigurationService } from './configuration.service';
import { DBkeys } from './db-keys';
import { JwtHelper } from './jwt-helper';
import { Utilities } from './utilities';
import { AccessToken, LoginResponse } from '../models/login-response.model';
import { User } from '../models/user.model';
import { UserLogin } from '../models/user-login.model';
import { PermissionValues } from '../models/permission.model';


@Injectable({
  providedIn: 'root',
})

export class AuthService {

  private pingUrl = '/api/check/auth-availability';

  public get loginUrl() { return this.configurations.loginUrl; }

  public get getPingUrl() { return this.configurations.baseUrl + this.pingUrl; }

  get logoutRedirectUrl(): string {
    const url = this.localStorage.getDataObject<string>(DBkeys.LOGOUT_REDIRECT_URL);
    return url;
  }

  set logoutRedirectUrl(url: string) {
    this.localStorage.savePermanentData(url, DBkeys.LOGOUT_REDIRECT_URL);
    //console.log("set loginRedirectUrl,  url:" + url);
  }

  get loginRedirectUrl(): string {

    const url = this.localStorage.getDataObject<string>(DBkeys.LOGIN_REDIRECT_URL);
    //console.log("get loginRedirectUrl,  url:" + url);
    return url;
  }

  set loginRedirectUrl(url: string) {

    this.localStorage.savePermanentData(url, DBkeys.LOGIN_REDIRECT_URL);

  }


  public reLoginDelegate: () => void;

  private previousIsLoggedInCheck = false;
  private loginStatus = new Subject<boolean>();

  constructor(
    private router: Router,
    private oidcHelperService: OidcHelperService,
    private configurations: ConfigurationService,
    private localStorage: LocalStoreManager,
    private http: HttpClient
    ) {

    this.initializeLoginStatus();
  }

  private initializeLoginStatus() {
    this.localStorage.getInitEvent().subscribe(() => {
      this.reevaluateLoginStatus();
    });
  }

  gotoPage(page: string, preserveParams = true) {

    const navigationExtras: NavigationExtras = {
      queryParamsHandling: preserveParams ? 'merge' : '', preserveFragment: preserveParams
    };

    this.router.navigate([page], navigationExtras);
  }

  gotoHomePage() {
    this.router.navigate(['/']);
  }

  redirectLoginUser() {
    const redirect = this.loginRedirectUrl && this.loginRedirectUrl !== '/' && this.loginRedirectUrl !== ConfigurationService.defaultHomeUrl ? this.loginRedirectUrl : '/';
    this.loginRedirectUrl = null;

    const urlParamsAndFragment = Utilities.splitInTwo(redirect, '#');
    const urlAndParams = Utilities.splitInTwo(urlParamsAndFragment.firstPart, '?');

    const navigationExtras: NavigationExtras = {
      fragment: urlParamsAndFragment.secondPart,
      queryParams: Utilities.getQueryParamsFromString(urlAndParams.secondPart),
      queryParamsHandling: 'merge'
    };

    this.router.navigate([urlAndParams.firstPart], navigationExtras);
  }

  redirectLogoutUser() {
    const redirect = this.logoutRedirectUrl ? this.logoutRedirectUrl : ConfigurationService.defaultHomeUrl;
    this.logoutRedirectUrl = null;

    this.router.navigate([redirect]);
  }

  redirectForLogin() {
    this.loginRedirectUrl = this.router.url;
    this.router.navigate([this.loginUrl]);
  }

  reLogin() {
    if (this.reLoginDelegate) {
      console.log("reLogin -> reLoginDelegate()");
      this.reLoginDelegate();
    } else {
      console.log("reLogin -> redirectForLogin()");
      this.redirectForLogin();
    }
  }

  refreshLogin() {
    return this.oidcHelperService.refreshLogin()
      .pipe(map(resp => this.processLoginResponse(resp, this.rememberMe)));
  }

  loginWithPassword(user: UserLogin) {
    if (this.isLoggedIn) {
      this.logout();
    }

    return this.oidcHelperService.loginWithPassword(user.userName, user.password)
      .pipe(map(resp => this.processLoginResponse(resp, user.rememberMe)));
  }

  loginWithExternalToken(token: string, provider: string, email?: string) {
    if (this.isLoggedIn) {
      this.logout();
    }

    return this.oidcHelperService.loginWithExternalToken(token, provider, email)
      .pipe(map(resp => this.processLoginResponse(resp)));
  }

  initClientCredentials(update?: boolean) {

      return this.oidcHelperService.loginWithClientCredentials()
        .pipe(map(resp => this.processClientCredentialsToken(resp, update)));

  }

  pingByClientSecret () {

    var accessToken = this.getClientToken();

    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + accessToken,
      'Content-Type': 'application/json',
      Accept: 'application/json, text/plain, */*'
    });

    var res = this.http.post<LoginResponse>(this.getPingUrl, null, { headers: headers });
    return res;
  }

  initLoginWithGoogle(rememberMe?: boolean) {
    //if (this.isLoggedIn) {
      this.logout();
    //}

    this.localStorage.savePermanentData(rememberMe, DBkeys.REMEMBER_ME);
    this.oidcHelperService.initLoginWithGoogle();
  }

  initLoginWithFacebook(rememberMe?: boolean) {

    //if (this.isLoggedIn) {
      this.logout();
    //}

    this.localStorage.savePermanentData(rememberMe, DBkeys.REMEMBER_ME);
    this.oidcHelperService.initLoginWithFacebook();
  }

  getFacebookEmail (accessToken) {
    return this.oidcHelperService.getFacebookEmail(accessToken);
  }

  initLoginWithTwitter(rememberMe?: boolean) {
    if (this.isLoggedIn) {
      this.logout();
    }

    this.localStorage.savePermanentData(rememberMe, DBkeys.REMEMBER_ME);
    this.oidcHelperService.initLoginWithTwitter();
  }

  initLoginWithApple(rememberMe?: boolean) {
    //if (this.isLoggedIn) {
      this.logout();
    //}

    this.localStorage.savePermanentData(rememberMe, DBkeys.REMEMBER_ME);
    this.oidcHelperService.initLoginWithApple();
  }

  getTwitterAccessToken(oauthToken: string, oauthVerifier: string) {
    return this.oidcHelperService.getTwitterAccessToken(oauthToken, oauthVerifier);
  }

  private processLoginResponse(response: LoginResponse, rememberMe?: boolean) {
    const accessToken = response.access_token;

    if (accessToken == null) {
      throw new Error('accessToken cannot be null');
    }

    rememberMe = rememberMe || this.rememberMe;

    const refreshToken = response.refresh_token || this.refreshToken;
    const expiresIn = response.expires_in;
    const tokenExpiryDate = new Date();
    tokenExpiryDate.setSeconds(tokenExpiryDate.getSeconds() + expiresIn);
    const accessTokenExpiry = tokenExpiryDate;
    const jwtHelper = new JwtHelper();
    const decodedAccessToken = jwtHelper.decodeToken(accessToken) as AccessToken;

    const permissions: PermissionValues[] = Array.isArray(decodedAccessToken.permission) ? decodedAccessToken.permission : [decodedAccessToken.permission];

    if (!this.isLoggedIn) {
      this.configurations.import(decodedAccessToken.configuration);
    }

    const user = new User(
      decodedAccessToken.sub,
      decodedAccessToken.name,
      decodedAccessToken.fullname,
      decodedAccessToken.email,
      decodedAccessToken.jobtitle,
      decodedAccessToken.phone_number,
      Array.isArray(decodedAccessToken.role) ? decodedAccessToken.role : [decodedAccessToken.role]);
    user.isEnabled = true;

    this.saveUserDetails(user, permissions, accessToken, refreshToken, accessTokenExpiry, rememberMe);

    this.reevaluateLoginStatus(user);

    return user;
  }

  private saveUserDetails(user: User, permissions: PermissionValues[], accessToken: string, refreshToken: string, expiresIn: Date, rememberMe: boolean) {
    if (rememberMe) {
      this.localStorage.savePermanentData(accessToken, DBkeys.ACCESS_TOKEN);
      this.localStorage.savePermanentData(refreshToken, DBkeys.REFRESH_TOKEN);
      this.localStorage.savePermanentData(expiresIn, DBkeys.TOKEN_EXPIRES_IN);
      this.localStorage.savePermanentData(permissions, DBkeys.USER_PERMISSIONS);
      this.localStorage.savePermanentData(user, DBkeys.CURRENT_USER);
    } else {
      this.localStorage.saveSyncedSessionData(accessToken, DBkeys.ACCESS_TOKEN);
      this.localStorage.saveSyncedSessionData(refreshToken, DBkeys.REFRESH_TOKEN);
      this.localStorage.saveSyncedSessionData(expiresIn, DBkeys.TOKEN_EXPIRES_IN);
      this.localStorage.saveSyncedSessionData(permissions, DBkeys.USER_PERMISSIONS);
      this.localStorage.saveSyncedSessionData(user, DBkeys.CURRENT_USER);
    }

    this.localStorage.savePermanentData(rememberMe, DBkeys.REMEMBER_ME);
  }

  private processClientCredentialsToken(response: LoginResponse, update?: boolean) {

    //console.log("processClientCredentialsToken, update:" + update);

    const accessToken = response.access_token;

    if (accessToken == null) {
      throw new Error('accessToken cannot be null');
    }

    this.revaluateClientToken(accessToken, update)
  }

  public revaluateClientToken(accessToken: string, update?: boolean)
  {
    //console.log("revaluateClientToken, update:" + update);

    let token = window.sessionStorage.getItem('CLIENTTOKEN') as string;
    if(token == null || update === true) {
      //console.log("CLIENTTOKEN was set");
      window.sessionStorage.setItem('CLIENTTOKEN', accessToken);
    }
  }

  public getClientToken()
  {
    let token = window.sessionStorage.getItem('CLIENTTOKEN') as string;
    return token;
  }

  logout(): void {
    this.localStorage.deleteData(DBkeys.ACCESS_TOKEN);
    this.localStorage.deleteData(DBkeys.REFRESH_TOKEN);
    this.localStorage.deleteData(DBkeys.TOKEN_EXPIRES_IN);
    this.localStorage.deleteData(DBkeys.USER_PERMISSIONS);
    this.localStorage.deleteData(DBkeys.CURRENT_USER);

    this.configurations.clearLocalChanges();

    this.reevaluateLoginStatus();
  }

  public reevaluateLoginStatus(currentUser?: User) {
    const user = currentUser || this.localStorage.getDataObject<User>(DBkeys.CURRENT_USER);

    const isLoggedIn = user != null;

    if (this.previousIsLoggedInCheck !== isLoggedIn) {
     const interval = setTimeout(() => {
       this.loginStatus.next(isLoggedIn);
     });
    }

    this.previousIsLoggedInCheck = isLoggedIn;

    //return () => {
    //  clearInterval(interval);
    //};
  }

  getLoginStatusEvent(): Observable<boolean> {
    return this.loginStatus.asObservable();
  }

  get currentUser(): User {

    const user = this.localStorage.getDataObject<User>(DBkeys.CURRENT_USER);
    this.reevaluateLoginStatus(user);
    return user;
  }

  get userPermissions(): PermissionValues[] {
    return this.localStorage.getDataObject<PermissionValues[]>(DBkeys.USER_PERMISSIONS) || [];
  }

  get accessToken(): string {
    return this.oidcHelperService.accessToken;
  }

  get accessTokenExpiryDate(): Date {
    return this.oidcHelperService.accessTokenExpiryDate;
  }

  get refreshToken(): string {
    return this.oidcHelperService.refreshToken;
  }

  get isSessionExpired(): boolean {
    return this.oidcHelperService.isSessionExpired;
  }

  get isLoggedIn(): boolean {
    return this.currentUser != null;
  }

  get rememberMe(): boolean {
    return this.localStorage.getDataObject<boolean>(DBkeys.REMEMBER_ME) === true;
  }

  get clientCredentialsToken(): string {
    return this.oidcHelperService.clientCredentialsToken;
  }
}
