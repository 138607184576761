import { CarType } from "../CarType";
import { CarTypeProperty } from "../CarTypeProperty";
import { InsuranceCompany } from "../InsuranceCompany";

export class CarInsuranceProductRequest {

  constructor(cityId?: string,
              carType?: CarType,
              carTypeProperty?: CarTypeProperty,
              insuranceCompany?: InsuranceCompany,
              franchiseId?: number,
              directSettlement?: boolean,
              trafficAccidentMedicine?: boolean
              ) {
      this.cityId = cityId;
      this.carType = carType;
      this.carTypeProperty = carTypeProperty;
      this.insuranceCompany = insuranceCompany;
      this.franchiseId = franchiseId;
      this.directSettlement = directSettlement;
      this.trafficAccidentMedicine = trafficAccidentMedicine;
  }

  public cityId: string;
  public carType: CarType;
  public carTypeProperty: CarTypeProperty;

  public insuranceCompany: InsuranceCompany;
  public franchiseId: number;
  public directSettlement: boolean;
  public trafficAccidentMedicine: boolean;

}
