import { ChangeDetectorRef, Component, Directive, Input, OnInit, ViewChild } from "@angular/core";
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from "@angular/router";
import { InsuranceCompany } from "src/app/models/InsuranceCompany";
import { City } from "src/app/models/city.model";
import { fadeInOut } from "src/app/services/animations";
import { CatalogService } from "src/app/services/catalog.service";
import { ConfigurationService } from "src/app/services/configuration.service";
import { NavigationService } from "src/app/services/navigation.service";
import { AccountService } from "src/app/services/account.service";
import { User } from "src/app/models/user.model";
import { Role } from "src/app/models/role.model";
import { Observable, debounceTime, distinctUntilChanged, filter, finalize, forkJoin, switchMap, tap } from "rxjs";
import { FormArray, NgForm, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from "@angular/material-moment-adapter";
import { dateValidator, yearValidator } from "src/app/shared/validators/equal.validator";
import { AlertService, MessageSeverity } from "src/app/services/alert.service";
import { CarType } from "src/app/models/CarType";
import { CarTypeProperty } from "src/app/models/CarTypeProperty";
import { Moment } from "moment";
import { MatDatepicker } from "@angular/material/datepicker";
import moment from "moment";
import { MAT_SELECT_CONFIG } from "@angular/material/select";
import * as CryptoJS from 'crypto-js';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Buffer } from 'buffer';
import { TravelProductRequest } from "src/app/models/travel-insurance/travel-product-request";
import { TravelFilterResponse } from "src/app/models/travel-insurance/travel-filter-response.model";
import { TravelService } from "src/app/services/travel.service";
import { TravelProductResponse } from "src/app/models/travel-insurance/travel-product-response";
import { TravelProductModel } from "src/app/models/travel-insurance/travel-product.model";
import { TravelInsuranceBookingRecordModel } from "src/app/models/travel-insurance/travel-insurance-booking-record.model";
import { TravelDocumentModel } from "src/app/models/travel-insurance/travel-document.model";

@Component({
  selector: 'app-travel-insurance-order',
  templateUrl: './travel-insurance-order.component.html',
  styleUrls: ['./travel-insurance-order.component.scss'],
  animations: [fadeInOut],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }

  ]
})

export class TravelInsuranceOrderComponent implements OnInit {

  @ViewChild('orderNgForm', { static: true }) orderNgForm: NgForm;
  @ViewChild('paymentForm') paymentFormElement;

  @Input() isEditMode = true;

  selectedProductId: string;
  selectedTermId: string;
  selectedTerritoryId: string;
  selectedPurposeId: string;
  selectedMultipleTrip: boolean;
  selectedBirthdays: Array<Date> = [];

  selectedValidFrom: string;
  selectedValidTo: string;

  selectedTermName: string;
  selectedTerritoryName: string;
  selectedPurposeName: string;
  selectedProgramId: string;
  selectedLimit: number;

  searchResult:string;
  searchResult1:string;
  searchResult2:string;
  searchResult3:string;
  public travelProduct: TravelProductModel;

  kyivCodeTE = '8000000000';

  public insuranceCompany: InsuranceCompany;
  public loadingDataSource: boolean;
  public isSaving: boolean;

  public user: User;
  public orderForm: UntypedFormGroup;

  filteredCities: City[] =
    [{id: '8000000000', town: 'М.КИЇВ', region: '',mainRegion:'', friendlyName:''},
     {id: '6310100000', town: 'ХАРКІВ' , region: '',mainRegion:'', friendlyName:''},
     {id: '1210100000', town: 'ДНІПРО' , region: '',mainRegion:'', friendlyName:''},
     {id: '4610100000', town: 'ЛЬВІВ' , region: '',mainRegion:'', friendlyName:''},
     {id: '5310100000', town: 'ПОЛТАВА' , region: '',mainRegion:'', friendlyName:''},
     {id: '0510100000', town: 'ВІННИЦЯ' , region: '',mainRegion:'', friendlyName:''},
     {id: '1410100000', town: 'ДОНЕЦЬК' , region: '',mainRegion:'', friendlyName:''},
     {id: '0110100000', town: 'СІМФЕРОПОЛЬ' , region: '',mainRegion:'', friendlyName:''},
     {id: '8500000000', town: 'М.СЕВАСТОПОЛЬ' , region: '',mainRegion:'', friendlyName:''},
    ];

  isLoadingCity = false;
  minLengthTerm = 3;
  passportSelectedCity: City = null;
  isValidSelectedCity = false;

  private optionalRegexPrefix = "^$|";

  public accepted:boolean = false;
  public minValidDate = moment().add(1, 'days');
  public maxManufactureYear = moment();

  public gatewayUrl:string;
  public payment:string;
  public key:string;
  public callbackUrl:string;
  public data:string;
  public req_token:string;
  public sign:string;
  public useremail:string;
  public order:string;//internal order id
  public ext1:string;//user id
  public ext2:string;//insurance company id
  public ext3:string;//insurance company name
  public ext4:string;//external order id

  constructor (
    public configurations: ConfigurationService,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private ref: ChangeDetectorRef,
    private alertService: AlertService,
    private http: HttpClient,
    private travelService: TravelService,
    private catalogService: CatalogService,
    private router: Router ) {

      this.isSaving = false;
      this.buildForm();
    }

    ngOnInit(): void {

      this.passportSelectedCity = this.filteredCities[0];

      this.cityKoatuuIdControl.setValue(this.passportSelectedCity.id);
      this.cityNameControl.setValue(this.passportSelectedCity.town);
      this.searchCityControl.setValue(this.passportSelectedCity.town);

      this.route.queryParams.subscribe(params => {

        this.selectedProductId = params['productId'];
        this.selectedTermId = params['termId'];
        this.selectedTerritoryId = params['territoryId'];
        this.selectedPurposeId = params['purposeId'];
        this.selectedMultipleTrip = params['multipleTrip'] == "true" ? true : false;

        var decoded = decodeURIComponent(params['birthdays']);
        var birthdayArray = JSON.parse(decoded);
        this.selectedBirthdays = birthdayArray.map(element => { return moment.utc(element, 'DD-MM-YYYY').toDate(); });

        this.selectedValidFrom = params['validFrom'];
        this.selectedValidTo = params['validTo'];

        this.selectedProgramId = params['programId'];
        this.selectedLimit = Number(params['limit']);

        var model = new TravelProductRequest();
        model.isMultiple = this.selectedMultipleTrip;
        model.dateFrom = moment.utc(this.selectedValidFrom, 'DD-MM-YYYY').toDate();
        model.dateTo = moment.utc(this.selectedValidTo, 'DD-MM-YYYY').toDate();
        model.termId = this.selectedTermId;
        model.territoryId = this.selectedTerritoryId;
        model.purposeId = this.selectedPurposeId;
        model.clientBirthdays = this.selectedBirthdays.map(element => { return moment.utc(element, 'DD-MM-YYYY').toDate(); });
        model.limit = this.selectedLimit;
        model.programId = this.selectedProgramId;

        this.loadData(model);

        this.acceptedByClient.disable();

        this.bindSearchCity();

        model.clientBirthdays.forEach(element => {

          const travelerForm = this.formBuilder.group({
            firstname: ['', [Validators.required, Validators.pattern("^[a-zA-Z']{2,40}$")]],
            lastname: ['', [Validators.required,Validators.pattern("^[a-zA-Z']{2,40}$")]],
            seria: ['', [Validators.required, Validators.pattern("^[A-Z]{2,3}$")]],
            number: ['', [Validators.required, Validators.pattern("^[0-9]{6,9}$")]],
            birthday: [moment(element), [Validators.required]]
          });

          this.travelers.push(travelerForm);
        });

      });

    }

    ngAfterViewInit() {
      //this.alertService.showMessage('Автоцивілка', `Заявку на страхування відправлено`, MessageSeverity.success);
    }

    private buildForm() {
      let dateRegex = new RegExp("^\\d{1,2}.\\d{1,2}.\\d{4,4}$");

      var numberValidators = [Validators.required, Validators.pattern("^[0-9]{6,9}$")];
      var seriaValidators = [Validators.required, Validators.pattern("^[A-Z]{2,3}$")];

      var taxIdValidators = [Validators.required, Validators.pattern("^[0-9]{10,10}$")];

      this.orderForm = this.formBuilder.group({
        email: [''],
        phoneNumber: ['', [Validators.required, Validators.pattern(/(?=.*\d).{12,12}/)]],
        acceptedByClient: ['', Validators.requiredTrue],
        firstname: ['', [Validators.required,
          Validators.pattern("^[аАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ\']{2,40}$")]],
        lastname: ['', [Validators.required,
          Validators.pattern("^[аАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ\']{2,200}$")]],
        middlename: ['', [Validators.required,
          Validators.pattern("^[аАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ\']{1,200}$")]],
        birthday: ['', [Validators.required, dateValidator(dateRegex)]],
        searchCity:['', Validators.required],
        cityName:'',
        cityKoatuuId:'',
        street:['', [Validators.required,
          Validators.pattern("^[аАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ1234567890\.\,\'\/\ ]{2,100}$")]],
        houseNumber:['', [Validators.required,
          Validators.pattern("^[аАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ1234567890\.\,\'\/\ ]{1,20}$")]],
        apartmentNumber:['', [
          Validators.pattern(this.optionalRegexPrefix + "^[аАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ1234567890\.\,\'\/\ ]{1,20}$")]],

        travelers: this.formBuilder.array([])

      });
    }

    get email() { return this.orderForm.get('email'); }

    get phoneNumber() { return this.orderForm.get('phoneNumber'); }

    get acceptedByClient() { return this.orderForm.get('acceptedByClient');  }

    get firstname() { return this.orderForm.get('firstname'); }

    get lastname() { return this.orderForm.get('lastname'); }

    get middlename() { return this.orderForm.get('middlename'); }

    get birthday() { return this.orderForm.get('birthday'); }

    get street() { return this.orderForm.get('street');  }

    get houseNumber() { return this.orderForm.get('houseNumber');  }

    get apartmentNumber() { return this.orderForm.get('apartmentNumber');  }

    get searchCityControl() { return this.orderForm.get('searchCity'); }

    get cityNameControl() { return this.orderForm.get('cityName'); }

    get cityKoatuuIdControl() { return this.orderForm.get('cityKoatuuId'); }

    get travelers() {
      return this.orderForm.controls["travelers"] as FormArray;
    }

    public casePatterns = {
      U: {
        pattern: new RegExp('[a-zA-ZаАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ]')
      }
    };

    loadData(model:TravelProductRequest)
    {
      this.loadingDataSource = true;

      let loadingTravelFilter: Observable<TravelFilterResponse> = this.travelService.getFilter();
      let loadingTravelProduct: Observable<TravelProductResponse> = this.travelService.getProduct(model);
      let loadingUser: Observable<User> = this.accountService.getUser();

      forkJoin(loadingTravelProduct, loadingTravelFilter, loadingUser).subscribe(results => {
        this.travelProduct = results[0].product;

        this.insuranceCompany = this.travelProduct.insuranceCompany ? this.travelProduct.insuranceCompany as InsuranceCompany : undefined;

        let filter = results[1];
        let user = results[2];

        this.onCurrentUserDataLoadSuccessful(user, user.roles.map(r => new Role(r)));

        this.selectedTermName = filter.terms.filter((item) => item.id == this.selectedTermId)[0]?.name;
        this.selectedTerritoryName = filter.territories.filter((item) => item.id == this.selectedTerritoryId)[0]?.name;
        this.selectedPurposeName = filter.purposes.filter((item) => item.id == this.selectedPurposeId)[0]?.name;

        if(this.selectedMultipleTrip == true) {
          this.searchResult = "Термін дії: з " + this.selectedValidFrom + ' період ' + this.selectedTermName;
        } else {
          this.searchResult = "Термін дії: з " + this.selectedValidFrom + ' по ' + this.selectedValidTo;
        }

        this.searchResult1 = "Країни: " + this.selectedTerritoryName;
        this.searchResult2 = 'Мета візиту: ' + this.selectedPurposeName;
        this.searchResult3 = "Кількість відвидувачів: " + this.selectedBirthdays.length;

        this.loadingDataSource = false;
      },
      error => {
        console.log(error);
        this.loadingDataSource = false;
      });
    }

    private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {
      this.user = user;

      const emailControl = this.orderForm.controls.email;
      const phoneNumberControl = this.orderForm.controls.phoneNumber;

      emailControl.setValue(user.email);
      phoneNumberControl.setValue(user.phoneNumber);
    }

    ngAfterContentChecked() {
      this.ref.detectChanges();
    }

    public validate() {

      this.orderForm.markAllAsTouched();

      if (!this.orderNgForm.submitted) {
        // Causes validation to update.
        this.orderNgForm.onSubmit(null);
      }

      if (!this.orderForm.valid) {
        return;
      }

      this.acceptedByClient.enable();
      this.isEditMode = false;
    }

    public async save() {

      if (!this.orderForm.valid) {
        return;
      }

      this.loadingDataSource = true;

      this.alertService.showMessage('Туристична страховка', `Відправка заявки на страхування`, MessageSeverity.success);

      const product = this.getTravelInsuranceBookingRecord();

      this.travelService.saveProduct(product).subscribe(
         savedProduct => this.saveCompleted(savedProduct),
         error => this.saveFailed(error));

    }

    private saveCompleted(product: TravelInsuranceBookingRecordModel) {

      this.alertService.showMessage('Туристична страховка', `Заявку на страхування відправлено`, MessageSeverity.success);
      //this.loadingDataSource = false;

      // this.router.navigate(
      //    ['/travel-insurance/paid'],
      //    { queryParams: { order: product.id } }
      // );

      // return;

      var platonApiKey = this.configurations.platonApiKey;
      var platonApiPassword = this.configurations.platonApiPassword;
      var platonApiUrl = this.configurations.platonApiUrl;

      this.callbackUrl = this.configurations.currentBaseUrl + '/travel-insurance/paid';

      this.payment = 'CC';
      this.req_token = 'Y';
      this.gatewayUrl = platonApiUrl;
      this.key = platonApiKey;

      var amount = product.sum.toFixed(2);

      const dataArray = {
        amount: amount,
        description: "SYDI.Finance, Туристична страховка від " + product.insuranceCompany,
        currency:"UAH",
        recurring:"Y"
      };

      this.data = Buffer.from(JSON.stringify(dataArray)).toString('base64');

      var valToSign = this.reverse(platonApiKey) + this.reverse(this.payment) + this.reverse(this.data)
                    + this.reverse(this.callbackUrl) + this.reverse(platonApiPassword);
      var valToSignUpper = valToSign.toUpperCase();
      this.sign = CryptoJS.MD5(valToSignUpper);

      this.useremail = product.email;
      this.order = product.id;//product id
      this.ext1 = product.applicationUserId;//user id
      this.ext2 = product.insuranceCompany.toString();
      this.ext3 = product.insuranceCompanyName;
      this.ext4 = product.orderId;//external order id
      this.ref.detectChanges();

      this.paymentFormElement.nativeElement.submit();

      // this.router.navigate(
      //   ['/car-insurance/paid'],
      //   { queryParams: { order: product.id } }
      // );

    }

    private reverse(s) {
      return [...s].reverse().join("");
    }

    private saveFailed(error) {
      this.alertService.showStickyMessage(error, null, MessageSeverity.error);
      this.loadingDataSource = false;
    }

    private getTravelInsuranceBookingRecord(): TravelInsuranceBookingRecordModel {
      const formModel = this.orderForm.value;

      const passports = formModel.travelers.map(item =>
          new TravelDocumentModel("",
            this.travelService.currentUser.id,
            item.seria,
            item.number,
            item.firstname,
            item.lastname,
            item.birthday
            )
        );

      return {
              id: "",
              applicationUserId: this.travelService.currentUser.id,
              email: formModel.email,
              phone: formModel.phoneNumber,

              travelDocuments: passports,

              firstname: formModel.firstname,
              lastname: formModel.lastname,
              middlename: formModel.middlename,
              birthday: formModel.birthday,

              street: formModel.street,
              houseNumber: formModel.houseNumber,
              apartmentNumber: formModel.apartmentNumber,
              documentCityKoatuuId: formModel.cityKoatuuId,
              documentCityName: formModel.cityName,

              insuranceCompany: this.insuranceCompany,
              insuranceCompanyName: this.travelProduct?.insuranceCompanyName,
              price: this.travelProduct?.price,
              sum: this.travelProduct?.sum,

              termId: this.selectedTermId,
              programId: this.selectedProgramId,
              purposeId: this.selectedPurposeId,
              limit: this.travelProduct?.limit,
              isMultiple: this.selectedMultipleTrip,
              territoryId: this.selectedTerritoryId,
              travelerCount: this.selectedBirthdays.length,
              productId:this.selectedProductId,

              termName:this.selectedTermName,
              territoryName:this.selectedTerritoryName,
              purposeName: this.selectedPurposeName,

              isConfirmedByClient: formModel.acceptedByClient,

              //discount?: number,
              //discountPercent?: number,
              //paid?: boolean,

              insuranceValidFrom: moment.utc(this.selectedValidFrom, 'DD-MM-YYYY').toDate(),
              insuranceValidTo: moment.utc(this.selectedValidTo, 'DD-MM-YYYY').toDate()
      };
    }

    public back() {

      setTimeout(() => {
        this.acceptedByClient.disable();
        this.isEditMode = true;
      });

    }

    get floatLabels(): string {
      return this.isEditMode ? 'auto' : 'always';
    }

    public clearCitySelection() {
      this.passportSelectedCity = null;
      this.cityKoatuuIdControl.setValue('');
      this.cityNameControl.setValue('');
      this.searchCityControl.setValue('');
      this.filteredCities = [];
    }

    onSelected(value: City) {

      this.passportSelectedCity = value;

      this.cityKoatuuIdControl.setValue(this.passportSelectedCity?.id);
      this.cityNameControl.setValue(this.passportSelectedCity?.town);
      this.searchCityControl.setValue(this.passportSelectedCity?.town);

      if(value)
          this.isValidSelectedCity = true;
      else
          this.isValidSelectedCity = false;

    }

    displayWith(value: City) {
      let town = typeof value === 'string' ? value : value?.town;
      //console.log("displayWith " + town);
      return town;
    }

    bindSearchCity() {
      this.searchCityControl.valueChanges
      .pipe(
        filter(res => {
          return res !== null && res.length >= this.minLengthTerm
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.filteredCities = [];
          this.isLoadingCity = true;
        }),
        switchMap(value => this.catalogService.getCities(value)
          .pipe(
            finalize(() => {
              this.isLoadingCity = false
            }),
          )
        )
      )
      .subscribe((data: any) => {
        this.filteredCities = data;

        //console.log(this.filteredCities);
      });
    }
}
