import { Component, OnInit, AfterViewInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import { fadeInOut } from '../../services/animations';
import { AppTranslationService } from '../../services/app-translation.service';
import { AccountService } from '../../services/account.service';
import { RegistrationCertificateService } from '../../services/registration-certificate.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment, Moment} from 'moment';
import { CatalogService } from 'src/app/services/catalog.service';
import { CarInsuranceBookingRecord } from 'src/app/models/car-insurance/car-insurance-booking-record.model';
import { CarInsuranceService } from 'src/app/services/car-insurance.service';
import { DomSanitizer } from '@angular/platform-browser';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'car-insurance-list',
  templateUrl: './car-insurance-list.component.html',
  styleUrls: ['./car-insurance-list.component.scss'],
  animations: [fadeInOut],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
  ],
})

export class CarInsuranceListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['registrationNumber', 'make', 'model', 'insuranceValidFrom', 'actions'];
  dataSource: MatTableDataSource<CarInsuranceBookingRecord>;

  loadingIndicator: boolean;

  @Input() insurance: CarInsuranceBookingRecord = new CarInsuranceBookingRecord();

  descriptionCarType: string;

  constructor(
    private translationService: AppTranslationService,
    private accountService: AccountService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private registrationCertificateService: RegistrationCertificateService,
    private carInsuranceService: CarInsuranceService,
    private catalogService: CatalogService,
    private formBuilder: UntypedFormBuilder,
    private navigationService: NavigationService,
    private sanitizer: DomSanitizer) {

    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.loadData();

  }

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = this.translationService.getTranslation('PAGINATOR.ITEMS_PER_PAGE');
    this.paginator._intl.getRangeLabel = this.sydiRangeLabel;

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }

  ngOnDestroy() {

  }

  isString(value) {
    return typeof value === 'string' || value instanceof String;
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  public downloadInsurance(insurance?: CarInsuranceBookingRecord) {

    var id = insurance.id;

    this.carInsuranceService.downloadPdf(id).subscribe(async (arrayBuffer: ArrayBuffer): Promise<void> => {

      var file = new Blob([arrayBuffer], {type: 'application/pdf'});
       var fileURL = URL.createObjectURL(file);
       window.open(fileURL, '_blank');

    }, (error: any) => {
      console.log(error);
    });

  }

  private sydiRangeLabel = (page: number, pageSize: number, length: number) => {

    let ofTranlated = this.translationService.getTranslation('PAGINATOR.OF');
    if (length == 0 || pageSize == 0) { return `0 ${ofTranlated} ${length}`; }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${ofTranlated} ${length}`;
  }

  private refresh() {
    // Causes the filter to refresh there by updating with recently added data.
    this.applyFilter(this.dataSource.filter);
  }

  get floatLabels(): string {
    return 'always';
  }

  private loadData() {
    this.loadingIndicator = true;

    this.carInsuranceService.getInsurances()
      .subscribe(results => {
        this.loadingIndicator = false;
        this.dataSource.data = results;
      },
        error => {
          this.loadingIndicator = false;
        });
  }

}
