import { Component, OnInit, AfterViewInit, ViewChild, Input, OnDestroy, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { fadeInOut } from '../../services/animations';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { AccountService } from '../../services/account.service';
import { PassportService } from '../../services/passport.service';
import { Utilities } from '../../services/utilities';
import { Passport } from '../../models/passport.model';
import { NavigationService } from 'src/app/services/navigation.service';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators, FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, finalize, Subject, switchMap, tap } from 'rxjs';
import { City } from 'src/app/models/city.model';
import { CatalogService } from 'src/app/services/catalog.service';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment, Moment} from 'moment';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { dateValidator } from 'src/app/shared/validators/equal.validator';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'passport-list',
  templateUrl: './passport-list.component.html',
  styleUrls: ['./passport-list.component.scss'],
  animations: [fadeInOut],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
})

export class PassportListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild('passportNgForm', { static: true }) passportNgForm: NgForm;

  displayedColumns = ['seria', 'number', 'lastname', 'actions'];
  dataSource: MatTableDataSource<Passport>;
  sourcePassport: Passport;
  loadingIndicator: boolean;

  public isSaving = false;
  isNewPassport = false;
  isChecked = false;

  @Input() passport: Passport = new Passport();
  @Input() isEditMode = false;

  passportForm: UntypedFormGroup;
  private onPassportSaved = new Subject<Passport>();
  passportSaved$ = this.onPassportSaved.asObservable();

  filteredCities: City[] =
    [{id: '8000000000', town: 'М.КИЇВ', region: '',mainRegion:'', friendlyName:''},
     {id: '6310100000', town: 'ХАРКІВ' , region: '',mainRegion:'', friendlyName:''},
     {id: '1210100000', town: 'ДНІПРО' , region: '',mainRegion:'', friendlyName:''},
     {id: '4610100000', town: 'ЛЬВІВ' , region: '',mainRegion:'', friendlyName:''},
     {id: '5310100000', town: 'ПОЛТАВА' , region: '',mainRegion:'', friendlyName:''},
     {id: '0510100000', town: 'ВІННИЦЯ' , region: '',mainRegion:'', friendlyName:''},
     {id: '1410100000', town: 'ДОНЕЦЬК' , region: '',mainRegion:'', friendlyName:''},
     {id: '0110100000', town: 'СІМФЕРОПОЛЬ' , region: '',mainRegion:'', friendlyName:''},
     {id: '8500000000', town: 'М.СЕВАСТОПОЛЬ' , region: '',mainRegion:'', friendlyName:''},
    ];

  isLoadingCity = false;
  minLengthTerm = 3;
  selectedCity: City = null;
  isValidSelectedCity = false;

  private optionalRegexPrefix = "^$|";

  constructor(
    private translationService: AppTranslationService,
    private accountService: AccountService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private passportService: PassportService,
    private formBuilder: UntypedFormBuilder,
    private catalogService: CatalogService,
    private navigationService: NavigationService) {

    this.buildForm();

    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.loadData();

    this.bindSearchCity();
  }

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = this.translationService.getTranslation('PAGINATOR.ITEMS_PER_PAGE');
    this.paginator._intl.getRangeLabel = this.sydiRangeLabel;

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }

  ngOnDestroy() {
    this.onPassportSaved.unsubscribe();
  }

  get firstname() { return this.passportForm.get('firstname');  }

  get lastname() { return this.passportForm.get('lastname');  }

  get middlename() { return this.passportForm.get('middlename');  }

  get birthday() { return this.passportForm.get('birthday');  }

  get seria() { return this.passportForm.get('seria');  }

  get number() { return this.passportForm.get('number');  }

  get issuer() { return this.passportForm.get('issuer');  }

  get issued() { return this.passportForm.get('issued');  }

  get validTo() { return this.passportForm.get('validTo');  }

  get isIdCard() { return this.passportForm.get('isIdCard');  }

  get street() { return this.passportForm.get('street');  }

  get houseNumber() { return this.passportForm.get('houseNumber');  }

  get apartmentNumber() { return this.passportForm.get('apartmentNumber');  }

  get searchCity() { return this.passportForm.get('searchCity'); }

  get cityName() { return this.passportForm.get('cityName'); }

  get cityKoatuuId() { return this.passportForm.get('cityKoatuuId'); }

  public casePatterns = {
    U: {
      pattern: new RegExp('[a-zA-ZаАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ]'),
    }
  };

  bindSearchCity() {
    this.searchCity.valueChanges
    .pipe(
      filter(res => {
        return res !== null && res.length >= this.minLengthTerm
      }),
      distinctUntilChanged(),
      debounceTime(1000),
      tap(() => {
        this.filteredCities = [];
        this.isLoadingCity = true;
      }),
      switchMap(value => this.catalogService.getCities(value)
        .pipe(
          finalize(() => {
            this.isLoadingCity = false
          }),
        )
      )
    )
    .subscribe((data: any) => {
      this.filteredCities = data;

      console.log(this.filteredCities);
    });
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  public toggle(event: MatSlideToggleChange) {
    console.log('toggle', event.checked);

    this.isChecked = event.checked;
    this.updateSeriaNumber();

  }

  private updateSeriaNumber()
  {
    const seriaControl = this.passportForm.controls.seria;
    const numberControl = this.passportForm.controls.number;

    if(this.isChecked) {
      seriaControl.disable();
    } else {
      seriaControl.enable();
    }

    if(!this.isChecked && numberControl.value != null) {
      var strshortened = numberControl.value.slice(0,6);
      numberControl.setValue(strshortened);
    }

    var numberValidators = this.isChecked
                            ? [Validators.required, Validators.pattern("^[0-9]{9,9}$")]
                            : [Validators.required, Validators.pattern("^[0-9]{6,6}$")];
    var seriaValidators = this.isChecked
                            ? null
                            : [Validators.required, Validators.pattern("^[АБВГДЕЖЗИЙКЛМНОПРСТУФХЦЧШЩЬЮЯЇІЄҐ]{2,2}$")];

    numberControl.setValidators(numberValidators);
    seriaControl.setValidators(seriaValidators);

    seriaControl.updateValueAndValidity();
    numberControl.updateValueAndValidity();
  }

  public confirmDelete(passport: Passport) {

    let message = this.translationService.getTranslation('passports.editor.DeleteMessage',
           {seria : passport.seria, number : passport.number});
    this.snackBar.open( `${message}`,
                        this.translationService.getTranslation('passports.editor.Yes'),
                        { duration: 5000 })
      .onAction().subscribe(() => {
        this.loadingIndicator = true;

        this.passportService.deletePassport(passport.id)
          .subscribe(results => {
            this.loadingIndicator = false;

            this.dataSource.data = this.dataSource.data.filter((u) => u.id !== passport.id);
          },
            error => {
              this.loadingIndicator = false;
            });
      });
  }

  public editPassport(passport?: Passport) {
    this.isEditMode = true;

    if (!passport) {
      this.isNewPassport = true;
      this.passport = new Passport();
      this.isChecked = false;
      this.updateSeriaNumber();

      const current = new Date();

      // it returns a timestamp
      const priorOneDay = new Date().setDate(current.getDate() - 1);
      const nextOneYear = new Date().setDate(current.getDate() + 365);
      this.passport.issued = new Date(priorOneDay);
      this.passport.validTo = new Date(nextOneYear);
      this.passport.birthday = new Date();
      this.selectedCity = new City();
      this.searchCity.setValue('');
    }
    else
    {
      this.isChecked = passport.isIdCard;

      this.updateSeriaNumber();
      this.isNewPassport = false;
      this.selectedCity = new City(passport.cityKoatuuId, passport.cityName);

      this.cityKoatuuId.setValue(this.selectedCity.id);
      this.cityName.setValue(this.selectedCity.town);
      this.searchCity.setValue(this.selectedCity.town);

      this.isValidSelectedCity = true;
      this.passport = passport;
      this.resetPassportForm();
    }

  }

  public save() {

    if (!this.passportNgForm.submitted) {
      // Causes validation to update.
      this.passportNgForm.onSubmit(null);
      return;
    }

    if (!this.passportForm.valid) {
      return;
    }

    this.isSaving = true;

    const editedPassport = this.getEditedPassport();

    if (this.isNewPassport) {
      this.passportService.newPassport(editedPassport).subscribe(
        driverLicence => this.newSaveCompleted(driverLicence),
        error => this.saveFailed(error));
    } else {
      this.passportService.updatePassport(editedPassport).subscribe(
        driverLicence => this.saveCompleted(driverLicence),
        error => this.saveFailed(error));
    }
  }

  public cancel() {
    this.resetForm(true);
  }

  private newSaveCompleted(passport?: Passport) {

    this.dataSource.data = [passport, ...this.dataSource.data];  //refresh the dataSource

    if (passport) {
      this.passport = passport;
    }

    this.isSaving = false;
    this.isEditMode = false;

    this.resetForm(true);

    this.onPassportSaved.next(this.passport);
  }

  private saveCompleted(passport?: Passport) {

    this.dataSource.data = this.dataSource.data.filter((item, key)=>{
      if(item.id == passport.id)
      {
        item.firstname = passport.firstname;
        item.lastname = passport.lastname;
        item.middlename = passport.middlename;
        item.birthday = passport.birthday;
        item.seria = passport.seria;
        item.number = passport.number;
        item.issuer = passport.issuer;
        item.issued = passport.issued;
        item.validTo = passport.validTo;
        item.isIdCard = passport.isIdCard;
        item.street = passport.street;
        item.houseNumber = passport.houseNumber;
        item.apartmentNumber = passport.apartmentNumber;
        item.cityKoatuuId = passport.cityKoatuuId;
        item.cityName = passport.cityName;
      }
      return true;
    });

    if (passport) {
      this.passport = passport;
    }

    this.isSaving = false;
    this.isEditMode = false;

    this.resetForm(true);

    this.onPassportSaved.next(this.passport);
  }

  private saveFailed(error: any) {
    this.isSaving = false;
    this.isEditMode = false;
  }

  private buildForm() {

    let dateRegex = new RegExp("^\\d{1,2}.\\d{1,2}.\\d{4,4}$");

    var numberValidators = this.isChecked
                            ? [Validators.required, Validators.pattern("^[0-9]{9,9}$")]
                            : [Validators.required, Validators.pattern("^[0-9]{6,6}$")];
    var seriaValidators = this.isChecked
                            ? null
                            : [Validators.required, Validators.pattern("^[АБВГДЕЖЗИЙКЛМНОПРСТУФХЦЧШЩЬЮЯЇІЄҐ]{2,2}$")];

    this.passportForm = this.formBuilder.group({
      firstname: ['', [Validators.required,
        Validators.pattern("^[аАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ\']{2,40}$")]],
      lastname: ['', [Validators.required,
        Validators.pattern("^[аАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ\']{2,200}$")]],
      middlename: ['', [Validators.required,
        Validators.pattern("^[аАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ\']{1,200}$")]],
      birthday: ['', [Validators.required, dateValidator(dateRegex)]],
      seria: ['', seriaValidators],
      number: ['', numberValidators],
      issuer: ['', [Validators.required,
        Validators.pattern("^[аАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ1234567890\.\,\'\/\ ]{4,200}$")]],
      issued: ['', [Validators.required, dateValidator(dateRegex)]],
      validTo: [null],
      isIdCard: false,
      street:['', [Validators.required,
        Validators.pattern("^[аАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ1234567890\.\,\'\/\ ]{2,100}$")]],
      houseNumber:['', [Validators.required,
        Validators.pattern("^[аАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ1234567890\.\,\'\/\ ]{1,20}$")]],
      apartmentNumber:['', [
        Validators.pattern(this.optionalRegexPrefix + "^[аАбБвВгГдДеЕжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩьЬюЮяЯїЇіІєЄґҐ1234567890\.\,\'\/\ ]{1,20}$")]],
      searchCity:['', Validators.required],
      cityName:'',
      cityKoatuuId:''
    });
  }

  public resetForm(stopEditing = false) {
    if (stopEditing) {
      this.isEditMode = false;
    }

    this.passport = new Passport();

    this.resetPassportForm();
  }

  public clearCitySelection() {
    this.selectedCity = null;
    this.cityKoatuuId.setValue('');
    this.cityName.setValue('');
    this.searchCity.setValue('');
    this.filteredCities = [];
  }

  onSelectedCity() {
    console.log(this.selectedCity);
    this.selectedCity = this.selectedCity;

    this.cityKoatuuId.setValue(this.selectedCity.id);
    this.cityName.setValue(this.selectedCity.town);
    this.searchCity.setValue(this.selectedCity.town);

    if(this.selectedCity != null)
        this.isValidSelectedCity = true;
    else
        this.isValidSelectedCity = false;
  }

  displayWith(value: City) {
    return value?.town;
  }

  private resetPassportForm()
  {

    this.passportForm.reset({
      firstname: this.passport.firstname || '',
      lastname: this.passport.lastname || '',
      middlename: this.passport.middlename || '',
      birthday: this.passport.birthday || '',
      seria: this.passport.seria || '',
      number: this.passport.number || '',
      issuer: this.passport.issuer || '',
      issued: this.passport.issued || '',
      validTo: this.passport.validTo,
      isIdCard: this.passport.isIdCard,
      street: this.passport.street || '',
      houseNumber: this.passport.houseNumber || '',
      apartmentNumber: this.passport.apartmentNumber || '',
      cityKoatuuId: this.passport.cityKoatuuId || '',
      cityName: this.passport.cityName || '',
      searchCity: this.passport.cityName || ''
    });
  }


  private getEditedPassport(): Passport {
    const formModel = this.passportForm.value;

    return {
      id: this.passport.id,
      firstname: formModel.firstname,
      lastname: formModel.lastname,
      middlename: formModel.middlename,

      applicationUserId: this.passportService.currentUser.id,

      birthday: formModel.birthday,
      seria: formModel.seria,
      number: formModel.number,
      issuer: formModel.issuer,
      issued: formModel.issued,
      validTo: formModel.validTo,

      isIdCard: formModel.isIdCard,
      street: formModel.street,
      houseNumber: formModel.houseNumber,
      apartmentNumber: formModel.apartmentNumber,

      cityKoatuuId: formModel.cityKoatuuId,
      cityName: formModel.cityName,

      birthPlace: this.passport.birthPlace,
      nationality: this.passport.nationality,
      sex: this.passport.sex,
      recordNumber: this.passport.recordNumber,

      createdBy: this.passport.createdBy,
      createdDate: this.passport.createdDate,
      updatedBy: this.passport.updatedBy,
      updatedDate: this.passport.updatedDate
    };
  }

  private sydiRangeLabel = (page: number, pageSize: number, length: number) => {

    let ofTranlated = this.translationService.getTranslation('PAGINATOR.OF');
    if (length == 0 || pageSize == 0) { return `0 ${ofTranlated} ${length}`; }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${ofTranlated} ${length}`;
  }

  private refresh() {
    // Causes the filter to refresh there by updating with recently added data.
    this.applyFilter(this.dataSource.filter);
  }

  get floatLabels(): string {
    return this.isEditMode ? 'auto' : 'always';
  }

  private loadData() {
    this.loadingIndicator = true;

    this.passportService.getPassports()
      .subscribe(results => {
        this.loadingIndicator = false;
        this.dataSource.data = results;
      },
        error => {
          this.loadingIndicator = false;
        });
  }

}
