import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({name: 'shortDate'})
export class ShortDatePipe implements PipeTransform {
  transform(value: Date): string {

    let dateFormat = moment(value).format('DD.MM.YYYY');
    return dateFormat;
  }
}
