<div>

  <div class="example-container mat-elevation-z8">
    <mat-progress-bar *ngIf="loadingIndicator" mode="query"></mat-progress-bar>
    <mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="date">
        <mat-header-cell fxFlex="38%" *matHeaderCellDef mat-sort-header> {{'notifications.Date' | translate}} </mat-header-cell>
        <mat-cell fxFlex="38%" *matCellDef="let notification" [class.unread]="!notification.isRead">{{getPrintedDate(notification.date)}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="header">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'notifications.Notification' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let notification" [class.unread]="!notification.isRead"> {{notification.header}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell fxFlex="100px" *matHeaderCellDef>
        </mat-header-cell>
        <mat-cell fxFlex="100px" *matCellDef="let notification">
          <button mat-icon-button matTooltip="{{'notifications.Pin' | translate}}" (click)="togglePin(notification)">
            <mat-icon color="accent">{{notification.isPinned ? 'favorite' : 'favorite_border'}}</mat-icon>
          </button>
          <button mat-icon-button matTooltip="{{'notifications.Delete' | translate}}" (click)="confirmDelete(notification)">
            <mat-icon color="warn">delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>

</div>
