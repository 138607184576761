import { Injectable } from '@angular/core';
import { HttpResponseBase } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { AppTranslationService } from '../services/app-translation.service';
import { Utilities } from '../services/utilities';
import { CarTypeModel } from '../models/cartype.model';
import { CarTypePropertyModel } from '../models/cartypeproperty.model';


@Injectable()
export class CarTypeService {

    /** 
        Car = 1,
        Truck = 2,
        Bus = 3,
        Trailer = 4,
        Motorbike = 5
    */
    getCarTypes() {
        return [
         new CarTypeModel(1, 'Легкове авто', 'Тип двигуна' ),
         new CarTypeModel(2, 'Вантажне авто', 'Вантажопідйомність' ),
         new CarTypeModel(3, 'Автобус', 'Місць' ),
         new CarTypeModel(4, 'Причіп', 'Тип' ),
         new CarTypeModel(5, 'Мотоцикл', 'Тип двигуна' )
        ];
    }

    /*
        CarBelow1600 = 1,
        CarFrom1601To2000 = 2,
        CarFrom2001To3000 = 3,
        CarMoreThenOrEqual3001 = 4,
        ElectroEngine = 14,

        TruckBelow2 = 5,
        TruckMoreThenOrEqual2 = 6,

        BusBelow20 = 7,
        BusMoreThenOrEqual21 = 8,

        TrailerForCar = 9,
        TrailerForTruck = 10,

        MotorbikeBelow300 = 11,
        MotorbikeMoreThenOrEqual300 = 12,
        
     */
    getCarTypeProperties() {
        return [
         new CarTypePropertyModel(1, 1, 'до 1600 см3' ),
         new CarTypePropertyModel(2, 1, 'з 1601 до 2000 см3' ),
         new CarTypePropertyModel(3, 1, 'з 2001 до 3000 см3' ),
         new CarTypePropertyModel(4, 1, 'з 3001 см3' ),
         new CarTypePropertyModel(14, 1, 'Електро' ),

         new CarTypePropertyModel(5, 2, 'до 2 т.' ),
         new CarTypePropertyModel(6, 2, '2т. і більше' ),

         new CarTypePropertyModel(7, 3, 'до 20 місць' ),
         new CarTypePropertyModel(8, 3, '20 місць та більше' ),

         new CarTypePropertyModel(9, 4, 'Причіп для легкового' ),
         new CarTypePropertyModel(10, 4, 'Причіп для вантажного' ),

         new CarTypePropertyModel(11, 5, 'до 300 см3' ),
         new CarTypePropertyModel(12, 5, '300 см3 та більше' ),
        ];
    }
}