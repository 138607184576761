// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/

import { Directive } from "@angular/core";
import { MAT_DATE_FORMATS } from "@angular/material/core";

export const YEAR_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'YYYY',
    monthYearA11yLabel: 'YYYY',
  },
};

@Directive({
  selector: '[appYearFormat]',
  providers: [
      { provide: MAT_DATE_FORMATS, useValue: YEAR_FORMATS },
  ],
})

export class YearFormatDirective {
}
