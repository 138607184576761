import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { TravelEndpoint } from "./travel-endpoint.service";
import { TravelFilterResponse } from "../models/travel-insurance/travel-filter-response.model";
import { TravelProductsRequest } from "../models/travel-insurance/travel-products-request";
import { TravelProductModel } from "../models/travel-insurance/travel-product.model";
import { TravelProductsResponse } from "../models/travel-insurance/travel-products-response";
import { TravelProductRequest } from "../models/travel-insurance/travel-product-request";
import { TravelProductResponse } from "../models/travel-insurance/travel-product-response";
import { TravelInsuranceBookingRecordModel } from "../models/travel-insurance/travel-insurance-booking-record.model";
import { ReleaseRequestModel } from "../models/travel-insurance/release-request.model";

@Injectable()
export class TravelService {

  constructor(
    private authService: AuthService,
    private travelEndpoint: TravelEndpoint) {

  }

  getFilter() {
    return this.travelEndpoint.getFilterEndpoint<TravelFilterResponse>();
  }

  getProducts(model:TravelProductsRequest) {
    return this.travelEndpoint.getProductsEndpoint<TravelProductsResponse>(model);
  }

  getProduct(model:TravelProductRequest) {
    return this.travelEndpoint.getProductEndpoint<TravelProductResponse>(model);
  }

  saveProduct(product?: TravelInsuranceBookingRecordModel) {
    return this.travelEndpoint.createProductEndpoint<TravelInsuranceBookingRecordModel>(product);
  }

  releaseTravelInsurance(request?: ReleaseRequestModel) {
    return this.travelEndpoint.releaseTravelInsuranceEndpoint<TravelInsuranceBookingRecordModel>(request);
  }

  downloadPdf(url?: string) {
    return this.travelEndpoint.downloadPdfEndpoint(url);
  }

  getInsurance(id?: string) {
    return this.travelEndpoint.getInsuranceEndpoint<TravelInsuranceBookingRecordModel>(id);
  }

  getInsurances() {
    return this.travelEndpoint.getInsurancesEndpoint<TravelInsuranceBookingRecordModel[]>();
  }

  get currentUser() {
    return this.authService.currentUser;
  }
}
