
    <!-- ===============================================-->
    <!--    Main Content-->
    <!-- ===============================================-->
    <main class="main" id="top">

      <!-- ============================================-->
      <!-- <section> begin ============================-->
      <section class="text-white py-0" data-zanim-timeline="{}" data-zanim-trigger="scroll">

        <div class="bg-holder" style="background-image:url(assets/img/video-1.jpg);">
          <video class="bg-video" autoplay="autoplay" loop="loop" muted="muted" playsinline="playsinline">
            <source src="assets/videos/morning-routine/video.mp4" type="video/mp4" />
            <source src="assets/videos/morning-routine/video.webm" type="video/webm" />
          </video>
        </div>
        <!--/.bg-holder-->

        <div class="container">
          <div class="row min-vh-100 py-8 align-items-center" data-inertia='{"weight":1.5}'>
            <div class="col-sm-9 col-lg-8">
              <div class="overflow-hidden">
                <h1 class="fs-4 fs-md-5" data-zanim-xs='{"delay":0.5}'>Онлайн Експерт Консультант</h1>
              </div>
              <div class="overflow-hidden">
                <p class="text-primary mt-4 mb-5 fs-1 fs-md-2 lh-xs" data-zanim-xs='{"delay":0.6}'>Великий досвід допомоги клієнтам у пошуку комплексних рішень</p>
              </div>
              <div class="overflow-hidden">
                <div data-zanim-xs='{"delay":0.7}'><a class="btn btn-primary me-3 mt-3" href="car-insurance">Пошук Автоцивілки<span class="fas fa-chevron-right ms-2"></span></a>
                                                   <a class="btn btn-warning mt-3" href="travel-insurance">Пошук Туристичної Страховки<span class="fas fa-chevron-right ms-2"></span></a></div>
              </div>
            </div>
          </div>
        </div>
        <!-- end of .container-->

      </section>
      <!-- <section> close ============================-->
      <!-- ============================================-->

    </main>
    <!-- ===============================================-->
    <!--    End of Main Content-->
    <!-- ===============================================-->



    <!--===============================================-->
    <!--    Footer-->
    <!--===============================================-->


    <!-- ============================================-->
    <!-- <section> begin ============================-->
    <section style="background-color: #3D4C6F">

      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 mt-4 mt-lg-0">
            <div class="row">
              <div class="col-6 col-lg-4 text-white ms-lg-auto">
                <ul class="list-unstyled">
                  <li class="mb-3"><a class="text-white" href="contact.html">Зв'яжіться з нами</a></li>
                  <li class="mb-3"><a class="text-white" href="#!">FAQ</a></li>
                  <li class="mb-3"><a class="text-white" href="/policy">Політика конфіденційності</a></li>
                  <li class="mb-3"><a class="text-white" href="/termsofuse">Умови використання</a></li>
                </ul>
              </div>
              <div class="col-6 col-sm-5 ms-sm-auto">
                <ul class="list-unstyled">
                  <li class="mb-3"><a class="text-decoration-none d-flex align-items-center" href="#!"> <span class="brand-icon me-3"><span class="fab fa-twitter"></span></span>
                      <h5 class="fs-0 text-white mb-0 d-inline-block">Twitter</h5>
                    </a></li>
                  <li class="mb-3"><a class="text-decoration-none d-flex align-items-center" href="#!"> <span class="brand-icon me-3"><span class="fab fa-facebook-f"></span></span>
                      <h5 class="fs-0 text-white mb-0 d-inline-block">Facebook</h5>
                    </a></li>
                  <li class="mb-3 mb-3-custom">
                    <a class="text-decoration-none d-flex align-items-center" href='https://play.google.com/store/apps/details?id=com.finance.sydi.mobile_sydi_finance'>
                      <img
                        class="android"
                        alt='Get it on Google Play'
                        src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png' />
                    </a>
                  </li>

                  <li class="mb-3">
                    <a class="text-decoration-none d-flex align-items-center" href="https://apps.apple.com/">
                      <img
                        class="apple" style="margin-left:0%;width:73%"
                        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1276560000&h=7e7b68fad19738b5649a1bfb78ff46e9"
                        alt="Download on the App Store">
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end of .container-->

    </section>
    <!-- <section> close ============================-->
    <!-- ============================================-->

