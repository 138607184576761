import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { EndpointBase } from './endpoint-base.service';
import { ConfigurationService } from './configuration.service';
import { CommonService } from './common.service';

@Injectable()
export class RegistrationCertificateEndpoint extends EndpointBase {

    get registrationCertificateListUrl() { return this.configurations.baseUrl + '/api/v1/registrationcertificate/list'; }

    get registrationCertificateDeleteUrl() { return this.configurations.baseUrl + '/api/v1/registrationcertificate/delete'; }

    get registrationCertificateCreateUrl() { return this.configurations.baseUrl + '/api/v1/registrationcertificate/create'; }

    get registrationCertificateUpdateUrl() { return this.configurations.baseUrl + '/api/v1/registrationcertificate/update'; }

    constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService, commonService: CommonService) {
        super(http, authService, commonService);
      }

    getRegistrationCertificatesEndpoint<T>(userId: string, page?: number, pageSize?: number): Observable<T> {
        const endpointUrl = page && pageSize ? `${this.registrationCertificateListUrl}/${page}/${pageSize}/${userId}`
                                                    : `${this.registrationCertificateListUrl}/${userId}`;

        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError(error => {
                return this.handleError(error, () => this.getRegistrationCertificatesEndpoint(userId, page, pageSize));
            }));
    }

    deleteRegistrationCertificateEndpoint<T>(passportId: string, userId: string): Observable<T> {
        const endpointUrl = `${this.registrationCertificateDeleteUrl}/${passportId}/${userId}`;

        return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
          catchError(error => {
            return this.handleError(error, () => this.deleteRegistrationCertificateEndpoint(passportId, userId));
          }));
    }

    createRegistrationCertificateEndpoint<T>(userObject: any): Observable<T> {
      const endpointUrl = this.registrationCertificateCreateUrl;

      return this.http.post<T>(endpointUrl, JSON.stringify(userObject), this.requestHeadersClientToken).pipe<T>(
        catchError(error => {
          return this.handleErrorFromApi(error, () => this.createRegistrationCertificateEndpoint(userObject));
        }));
    }

    updateRegistrationCertificateEndpoint<T>(userObject: any): Observable<T> {
      const endpointUrl = this.registrationCertificateUpdateUrl;
      return this.http.put<T>(endpointUrl, JSON.stringify(userObject), this.requestHeaders).pipe<T>(
        catchError(error => {
          return this.handleError(error, () => this.updateRegistrationCertificateEndpoint(userObject));
        }));
    }
}
