import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { DriverLicenceEndpoint } from './driver-licence-endpoint.service';
import { AuthService } from './auth.service';
import { DriverLicence } from '../models/driver-licence.model';

@Injectable()
export class DriverLicenceService {
  
  constructor(
    private authService: AuthService,
    private driverLicenceEndpoint: DriverLicenceEndpoint) {

  }

  getDriverLicences(page?: number, pageSize?: number) {

    const userId = this.currentUser.id;

    return this.driverLicenceEndpoint.getDriverLicencesEndpoint<DriverLicence[]>(userId, page, pageSize);
  }

  deleteDriverLicence(driverLicenceId: string) {
    
    const userId = this.currentUser.id;
    return this.driverLicenceEndpoint.deleteDriverLicenceEndpoint<DriverLicence>(driverLicenceId, userId);
  }

  newDriverLicence(driverLicence: DriverLicence) {

    driverLicence.createdBy = this.currentUser.id;
    driverLicence.updatedBy = this.currentUser.id;
    return this.driverLicenceEndpoint.createDriverLicenceEndpoint<DriverLicence>(driverLicence);
  }

  updateDriverLicence(driverLicence: DriverLicence) {
    
    driverLicence.updatedBy = this.currentUser.id;
    return this.driverLicenceEndpoint.updateDriverLicenceEndpoint<DriverLicence>(driverLicence);
  }

  get currentUser() {
    return this.authService.currentUser;
  }
}