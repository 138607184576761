import { Component } from "@angular/core";

@Component({
  selector: 'app-footer-logos',
  templateUrl: './footer-logos.component.html',
  styleUrls: ['./footer-logos.component.scss']
})

export class FooterLogosComponent {

}
