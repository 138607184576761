export class City {
    // Note: Using only optional constructor properties without backing store disables typescript's type checking for the type
    constructor(id?: string, town?: string, region?: string, mainRegion?: string) {
  
      this.id = id;
      this.town = town;
      this.region = region;
      this.mainRegion = mainRegion;
    }
  
    get friendlyName(): string {
      let name = this.town;
  
      if (this.mainRegion) {
              name = this.town + ' ' + this.mainRegion;
          }
  
      return name;
    }
  
    public id: string;
    public town: string;
    public region: string;
    public mainRegion: string;
    
  }