<form #form="ngForm" [formGroup]="loginForm" (ngSubmit)="login()" novalidate class="app-login-form">
  <mat-form-field class="app-validate">
    <input matInput formControlName="userName" placeholder="E-mail" autocomplete="username" type="text" (keydown.enter)="enterKeyDown()">
    <mat-error *ngIf="userName.hasError('required')">
      E-mail <strong>обов'язковий</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field class="app-validate">
    <input matInput formControlName="password" placeholder="Пароль" autocomplete="current-password" (keydown.enter)="enterKeyDown()"
		[type]="hidePassword ? 'password' : 'text'" >
    <mat-icon matSuffix style="cursor: pointer;" (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
    <mat-error *ngIf="password.hasError('required')">
      Пароль <strong>обов'язковий</strong>
    </mat-error>
  </mat-form-field>

  <mat-checkbox formControlName="rememberMe" (keydown.enter)="enterKeyDown()">Запам'ятати мене!</mat-checkbox>
  <!-- https://github.com/angular/material2/issues/8798 -->
  <div>&nbsp;</div>

  <p class="error">{{backendErrorMessage}}</p>
</form>
