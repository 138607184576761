import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { EndpointBase } from './endpoint-base.service';
import { ConfigurationService } from './configuration.service';
import { CarType } from '../models/CarType';
import { CarTypeProperty } from '../models/CarTypeProperty';
import { CommonService } from './common.service';

@Injectable()
export class CatalogEndpoint extends EndpointBase {

  get catalogGetTownsUrl() { return this.configurations.baseUrl + '/api/v1/Catalog/GetTowns'; }

  get catalogGetTownUrl() { return this.configurations.baseUrl + '/api/v1/Catalog/GetTown'; }

  get catalogGetCarBrandsUrl() { return this.configurations.baseUrl + '/api/v1/Catalog/GetCarMarks'; }

  constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService, commonService: CommonService) {
    super(http, authService, commonService);
  }

  getCitiesEndpoint<T>(city?: string): Observable<T> {

    var encodedCity = encodeURIComponent(city);
    const endpointUrl = `${this.catalogGetTownsUrl}?city=${encodedCity}`;

    let headers = this.requestHeadersClientToken;
    return this.http.get<T>(endpointUrl, headers).pipe<T>(
      catchError(error => {
        return this.handleErrorFromApi(error, () => this.getCitiesEndpoint(city));
      }));
  }

  getCityEndpoint<T>(cityCode?: string): Observable<T> {

    var encodedCityCode = encodeURIComponent(cityCode);
    const endpointUrl = `${this.catalogGetTownUrl}?cityCode=${encodedCityCode}`;

    let headers = this.requestHeadersClientToken;
    return this.http.get<T>(endpointUrl, headers).pipe<T>(
      catchError(error => {
        return this.handleErrorFromApi(error, () => this.getCityEndpoint(cityCode));
      }));
  }

  getCarBrandsEndpoint<T>(carType?: CarType, carTypeProperty?:CarTypeProperty): Observable<T> {

    var encodedCarType = encodeURIComponent(carType);
    var encodedCarTypeProperty = encodeURIComponent(carTypeProperty);
    const endpointUrl = `${this.catalogGetCarBrandsUrl}?carType=${encodedCarType}&carTypeProperty=${encodedCarTypeProperty}`;

    let headers = this.requestHeadersClientToken;
    return this.http.get<T>(endpointUrl, headers).pipe<T>(
      catchError(error => {
        return this.handleErrorFromApi(error, () => this.getCarBrandsEndpoint(carType, carTypeProperty));
      }));
  }
}
