


    <!-- ===============================================-->
    <!--    Main Content-->
    <!-- ===============================================-->
    <main class="main" id="top">


      <!-- ============================================-->
      <!-- <section> begin ============================-->
      <section class="py-0">

        <div class="bg-holder overlay overlay-1" style="background-image:url(../assets/img/background-1.jpg);">
        </div>
        <!--/.bg-holder-->

        <div class="container">
          <div class="row min-vh-100 align-items-center">
            <div class="col-md-9 col-lg-6 mx-auto" data-zanim-timeline="{}" data-zanim-trigger="scroll">
              <div class="mb-5"></div>
              <div class="mb-5" data-zanim-xs='{"delay":0,"duration":1}'><a href="index.html"><img src="assets/img/logo-4.png" alt="SYDI Finance" /></a></div>

              <div class="card" data-zanim-xs='{"delay":0.1,"duration":1}'>

                <mat-card class="password-recovery-card mat-elevation-z6">
                  <mat-card-header *ngIf="!isSuccess">
                    <mat-card-title><h4>Відновити Пароль</h4></mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    <div *ngIf="isSuccess" class="overflow-hidden">
                      <p class="text-primary mt-4 mb-5 fs-1 fs-md-2 lh-xs" data-zanim-xs='{"delay":0.6}'>Будь ласка, перевірте свою електронну пошту, щоб відновити пароль.</p>
                    </div>

                    <form *ngIf="!isSuccess" #form="ngForm" [formGroup]="recoverPasswordForm" (ngSubmit)="recover()" novalidate class="app-recover-form">
                      <mat-form-field class="app-validate">
                        <input matInput formControlName="usernameOrEmail" placeholder="Email адреса" autocomplete="username" (keydown.enter)="recover()">
                        <mat-error *ngIf="usernameOrEmail.hasError('required')">
                          Email адреса є <strong>обов'язковою</strong>
                        </mat-error>
                      </mat-form-field>
                    </form>
                  </mat-card-content>
                  <mat-card-actions>
                    <button *ngIf="!isSuccess" mat-raised-button color="primary" [disabled]="isLoading" (click)="recover()">
                      <mat-spinner *ngIf="isLoading" [diameter]="20"></mat-spinner>
                      Відновити
                    </button>
                    <a *ngIf="!isSuccess" mat-button routerLink="/login">Відмінити</a>
                    <a *ngIf="isSuccess" mat-raised-button color="accent" routerLink="/login">Завершити</a>
                  </mat-card-actions>
                </mat-card>


              </div>
            </div>
          </div>
        </div>
        <!-- end of .container-->

      </section>
      <!-- <section> close ============================-->
      <!-- ============================================-->


    </main>
    <!-- ===============================================-->
    <!--    End of Main Content-->
    <!-- ===============================================-->

