
export class ReleaseRequestModel {

  constructor(id?: string,
              applicationUserId?: string)
              {

                this.id = id;
                this.applicationUserId = applicationUserId;
              }

  public id: string;
  public applicationUserId: string;
}
